import { FC, ReactNode } from "react";
import { Droppable, DroppableProps } from "react-beautiful-dnd";
import styled from "../../../plugins/styled";

const Content = styled.div<{ $isDraggingOver: boolean }>`
  border: 2px dashed
    ${({ $isDraggingOver, theme }) =>
      $isDraggingOver ? theme.colors.purple_1 : theme.colors.transparent};
`;

interface DropProps extends Omit<DroppableProps, "children"> {
  children: ReactNode;
  className?: string;
}

const Drop: FC<DropProps> = ({ children, className, ...props }) => {
  return (
    <Droppable {...props}>
      {(provided, snapshot) => (
        <Content
          ref={provided.innerRef}
          className={className}
          $isDraggingOver={snapshot.isDraggingOver}
        >
          {children}
          {provided.placeholder}
        </Content>
      )}
    </Droppable>
  );
};

export default Drop;

