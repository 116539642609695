import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Heading from "@hellodarwin/core/lib/components/common/typography/heading";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import FormLabel from "@hellodarwin/core/lib/components/forms/form-layouts/form-label";
import validatePhoneNumber from "@hellodarwin/core/lib/components/forms/utils/validate-phone-number";
import CompanyType from "@hellodarwin/core/lib/features/enums/company-type";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Input from "antd/es/input";
import Select from "antd/es/select";

type OnboardingContactFormFieldsProps = {
  isLoading: boolean;
};
const OnboardingContactFormFields = ({
  isLoading,
}: OnboardingContactFormFieldsProps) => {
  const { t } = useTranslation();
  const { getFullLanguage } = useTranslations();
  return (
    <Container>
      <Heading noMargin level={5} elementTheme="h6">
        {t("profile|profileForm.contact.title")}
      </Heading>
      <Div flex="row" gap={16} tablet={{ flex: "column" }}>
        <FormItem
          name="contact_name"
          label={
            <FormLabel label={t("profile|profileForm.contact.labels.name")} />
          }
          rules={[
            {
              required: true,
              message: t("profile|profileForm.contact.validation.name"),
            },
          ]}
          style={{ flex: 1 }}
        >
          <Input
            size={"large"}
            placeholder={t("profile|profileForm.contact.placeholder.name")}
            disabled={isLoading}
          />
        </FormItem>
        <FormItem
          name="contact_phone"
          label={
            <FormLabel label={t("profile|profileForm.contact.labels.phone")} />
          }
          rules={[
            {
              required: true,
              validator: validatePhoneNumber(
                t("profile|profileForm.contact.validation.phone")
              ),
              message: t("profile|profileForm.contact.validation.phone"),
            },
          ]}
          style={{ flex: 1 }}
        >
          <Input
            size={"large"}
            placeholder={t("profile|profileForm.contact.placeholder.phone")}
            disabled={isLoading}
          />
        </FormItem>
      </Div>
      <Div
        flex="row"
        gap={16}
        align="flex-end"
        tablet={{ flex: "column", align: "center" }}
      >
        <FormItem
          name="contact_preferred_language"
          label={
            <FormLabel
              label={t("profile|profileForm.contact.labels.preferredLanguage")}
              extra={t("profile|profileForm.contact.extra.preferredLanguage")}
            />
          }
          rules={[
            {
              required: true,
              message: t(
                "profile|profileForm.contact.validation.preferredLanguage"
              ),
            },
          ]}
          style={{ flex: 1 }}
        >
          <Select
            size={"large"}
            placeholder={t(
              "profile|profileForm.contact.placeholder.preferredLanguage"
            )}
            disabled={isLoading}
          >
            <Select.Option value={"en"}>{getFullLanguage("en")}</Select.Option>
            <Select.Option value={"fr"}>{getFullLanguage("fr")}</Select.Option>
          </Select>
        </FormItem>
        <FormItem
          name="company_type"
          label={
            <FormLabel label={t("profile|profileForm.company.labels.type")} />
          }
          rules={[
            {
              required: true,
              message: t(`profile|profileForm.validation.type`),
            },
          ]}
          style={{ flex: 1, width: "100%" }}
        >
          <Select
            placeholder={t("profile|profileForm.company.placeholder.type")}
            size="large"
          >
            {(Object.keys(CompanyType) as (keyof typeof CompanyType)[]).map(
              (key) => (
                <Select.Option value={CompanyType[key]} key={key}>
                  {t(`profile|companyType.${CompanyType[key]}`)}
                </Select.Option>
              )
            )}
          </Select>
        </FormItem>
      </Div>
    </Container>
  );
};
export default OnboardingContactFormFields;

