import { LogoProps } from ".";
import theme from "../../theme";

const PrimaryLogo = ({
  primaryColor = theme.colors.grey_1,
  secondaryColor = theme.colors.grey_1,
  width = 358,
  height = 45,
  onClick,
  style,
}: LogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      onClick={onClick}
      viewBox="0 0 358 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M145.066 12.2056H159.194C171.357 12.2056 176.503 19.5219 176.503 28.3898C176.503 38.1409 170.234 44.9961 159.615 44.9961H145.066V12.2056ZM158.773 39.2392C164.059 39.2392 169.205 36.8434 169.205 28.7298C169.205 19.3108 162.281 17.9664 157.416 17.9664H152.036V39.2392H158.773Z"
        fill={primaryColor}
      />
      <path
        d="M192.978 12.2056H201.118L214.965 44.9961H207.527L203.878 36.4213H189.937C188.721 39.2783 187.551 42.1391 186.335 44.9961H179.13L192.978 12.2056ZM201.398 30.3674C200.276 27.7176 198.872 24.3134 197.001 19.7329H196.907C195.691 22.7189 194.054 26.5841 192.416 30.3674H201.398Z"
        fill={primaryColor}
      />
      <path
        d="M220.774 12.2056H234.855C237.335 12.2056 247.533 13.1318 247.533 22.7579C247.533 28.9369 243.463 31.7978 239.299 32.7631V32.8061C242.34 36.8434 245.381 40.9197 248.422 45H240.562L231.955 33.2712H227.744V45H220.774V12.2056ZM233.592 27.7176C236.212 27.7176 240.235 26.8343 240.235 22.883C240.235 18.9317 236.259 17.8804 233.686 17.8804H227.744V27.7176H233.592Z"
        fill={primaryColor}
      />
      <path
        d="M251.513 12.2056H259.466C262.039 20.6553 264.144 28.1396 266.343 36.1673H266.437C269.01 28.1787 271.676 20.1902 274.249 12.2056H282.202C284.775 20.1941 287.442 28.1826 290.061 36.1673H290.155C292.307 28.3898 294.552 20.1511 296.985 12.2056H304.938L294.552 44.9961H285.898C282.997 36.7574 280.752 29.9453 278.272 22.1248H278.179C275.653 30.1993 273.267 37.4687 270.647 44.9961H261.899L251.513 12.2056Z"
        fill={primaryColor}
      />
      <path
        d="M311.031 12.2056H318.002V44.9961H311.031V12.2056Z"
        fill={primaryColor}
      />
      <path
        d="M327.545 12.2056H334.796C340.176 19.3929 345.556 26.4161 350.983 34.0255H351.076V12.2056H358V44.9961H350.796C345.416 37.8908 340.036 31.0826 334.656 23.6412H334.516V44.9961H327.545V12.2056Z"
        fill={primaryColor}
      />
      <path
        d="M81.1894 42.3072C80.129 42.7215 79.0063 42.9325 77.8484 42.9325C76.0668 42.9325 74.679 42.3424 73.7316 41.1816C72.8155 40.0638 72.3711 38.2856 72.3711 35.7491V4.11932H64.8704V6.01093H69.8059V35.753C69.8059 37.5664 69.9813 39.0985 70.3322 40.3022C70.6635 41.4474 71.1509 42.3736 71.7785 43.0458C72.4062 43.722 73.1703 44.1988 74.1137 44.5115C75.1039 44.8358 76.2578 45 77.5404 45C78.1096 45 78.749 44.9296 79.4429 44.7929C80.1446 44.6522 80.8502 44.4489 81.5481 44.1871C82.2498 43.9252 82.9437 43.5969 83.6065 43.2139C84.0236 42.9716 84.4212 42.7097 84.7994 42.4244L83.7819 40.8689C82.8618 41.5138 81.9925 41.9984 81.1933 42.3111L81.1894 42.3072Z"
        fill={secondaryColor}
      />
      <path
        d="M99.446 42.3072C98.3856 42.7215 97.2629 42.9325 96.105 42.9325C94.3234 42.9325 92.9356 42.3424 91.9882 41.1816C91.0721 40.0638 90.6277 38.2856 90.6277 35.7491V4.11932H83.127V6.01093H88.0625V35.753C88.0625 37.5664 88.2379 39.0985 88.5888 40.3022C88.9201 41.4474 89.4074 42.3736 90.0351 43.0458C90.6627 43.722 91.4269 44.1988 92.3703 44.5115C93.3605 44.8358 94.5144 45 95.797 45C96.3662 45 97.0056 44.9296 97.6995 44.7929C98.4012 44.6522 99.1068 44.4489 99.8047 44.1871C100.506 43.9252 101.2 43.5969 101.863 43.2139C102.28 42.9716 102.678 42.7097 103.056 42.4244L102.038 40.8689C101.118 41.5138 100.249 41.9984 99.4499 42.3111L99.446 42.3072Z"
        fill={secondaryColor}
      />
      <path
        d="M0 0H2.58078V20.4169H2.69384C5.16157 15.2423 9.20038 13.1592 13.8006 13.1592C19.1883 13.1592 25.2465 15.9732 25.2465 25.6462V44.9961H22.6657V26.7718C22.6657 17.9429 17.8394 15.4651 13.4068 15.4651C6.22585 15.4651 2.58078 22.0466 2.58078 27.3345V44.9961H0V0Z"
        fill={secondaryColor}
      />
      <path
        d="M32.5288 28.6282C32.5288 18.9552 38.8131 12.2056 47.5652 12.2056C52.8398 12.2056 61.4242 14.6795 61.4242 27.3932V28.9096H35.3357C35.3357 37.1795 40.4427 42.6902 47.6782 42.6902C53.7949 42.6902 57.7792 38.6412 59.627 33.9161L61.9271 34.6469C59.514 41.3966 53.9587 44.9961 47.5067 44.9961C38.8677 44.9961 32.5249 38.3598 32.5249 28.6282H32.5288ZM58.5628 26.3223C58.5628 17.4896 52.5591 14.5115 47.4521 14.5115C40.9456 14.5115 36.3454 19.3499 35.4449 26.3223H58.5589H58.5628Z"
        fill={secondaryColor}
      />
      <path
        d="M104.198 28.6282C104.198 18.9552 110.483 12.2056 119.515 12.2056C128.548 12.2056 134.778 18.9552 134.778 28.6282C134.778 38.3012 128.493 44.9961 119.515 44.9961C110.537 44.9961 104.198 38.2465 104.198 28.6282ZM119.515 42.6902C126.922 42.6902 131.971 36.8981 131.971 28.6282C131.971 20.3583 126.922 14.5115 119.515 14.5115C112.108 14.5115 107.005 20.3622 107.005 28.6282C107.005 36.8942 112.112 42.6902 119.515 42.6902Z"
        fill={secondaryColor}
      />
    </svg>
  );
};

export default PrimaryLogo;
