import Carousel from "antd/es/carousel";
import { CarouselButtonSize } from ".";
import styled from "../../../plugins/styled";
import Button from "../button";
import Div from "../div";

export const CarouselContent = styled(Carousel)`
  .slick-list {
    overflow: visible;
  }
`;

export const CarouselButton = styled(Button)<{
  $buttonSize: CarouselButtonSize;
}>`
  width: ${({ $buttonSize }) =>
    $buttonSize === "large" ? 48 : $buttonSize === "small" ? 24 : 36}px;
  height: ${({ $buttonSize }) =>
    $buttonSize === "large" ? 48 : $buttonSize === "small" ? 24 : 36}px;
  &:hover {
    width: ${({ $buttonSize }) =>
      $buttonSize === "large" ? 48 : $buttonSize === "small" ? 24 : 36}px;

    height: ${({ $buttonSize }) =>
      $buttonSize === "large" ? 48 : $buttonSize === "small" ? 24 : 36}px;
  }
`;

export const CarouselContainer = styled(Div)`
  width: 100%;
`;
