import Button from "@hellodarwin/core/lib/components/common/button";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import { useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchAvailableMatches,
  selectNewMatches,
} from "../../features/api/slices/matches-slice";
import { usePartnerApi } from "../../features/api/use-partner-api";
import ProjectCard from "./project-banner/project-card";

const RecentProjects = () => {
  const { t } = useTranslation();
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const recentMatches = useAppSelector(selectNewMatches);

  useEffect(() => {
    dispatch(fetchAvailableMatches(api));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectProjects = () => {
    navigate("/projects");
  };

  if (!recentMatches.length) return <></>;
  return (
    <>
      <Container>
        <Div flex="column" gap={38}>
          <Typography.Title level={2} elementTheme="h6">
            {t(`dashboard|recentProject.title`)}
          </Typography.Title>
          <Div flex="column" gap={26}>
            {recentMatches.slice(0, 2).map((match) => (
              <ProjectCard
                match={match}
                price={match?.project?.lead_price || 0}
                discount={match.discounted_amount || 0}
              />
            ))}

            <Div flex="column" align="flex-end">
              <Link to={"/projects"}>
                <Button
                  defaultStyle={theme.colors.grey_1}
                  size="small"
                  withArrowRight
                  onClick={handleRedirectProjects}
                >
                  {t("project|seeAll")}
                </Button>
              </Link>
            </Div>
          </Div>
        </Div>
      </Container>
    </>
  );
};

export default RecentProjects;
