import Geocode from "react-geocode";
import { GMAPS_API_KEY } from "../../api";

Geocode.setApiKey(GMAPS_API_KEY);
Geocode.setLanguage("en");
Geocode.setLanguage("ca");
Geocode.setLocationType("ROOFTOP");

export const getLocationFromAddress = async (address: string) => {
  return await Geocode.fromAddress(address);
};
