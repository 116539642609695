import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import OnboardingFooter from "@hellodarwin/core/lib/components/onboarding/footer";
import OnboardingHeader from "@hellodarwin/core/lib/components/onboarding/header";
import {
  OnBoardingSteps,
  useOnBoarding,
} from "@hellodarwin/core/lib/context/onboarding-provider";
import { ServiceTag } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Select, { SelectProps } from "antd/es/select";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import {
  selectTags,
  toggleSpecialtyTag,
} from "../../../features/api/slices/profile-slice";
type PartnerSpecialtyPageProps = {
  categories: string[];
  onSave: (
    categories: string[],
    services: ServiceTag[],
    specialties: string[]
  ) => void;
};

const PartnerSpecialtyPage = ({
  categories,
  onSave,
}: PartnerSpecialtyPageProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedTags = useAppSelector(selectTags);
  const loading = useAppSelector((state) => state.global.status === "pending");
  const { setCurrentStep } = useOnBoarding();

  const handleToggle = (tag: string) => {
    dispatch(toggleSpecialtyTag({ specialty: tag }));
  };
  const handleNextStep = () => {
    onSave(
      categories,
      selectedTags.selectedServices,
      selectedTags.selectedSpecialties.map((specialty) => specialty.tag)
    );
  };

  const selectMenuPropsValue: SelectProps<string[]> = {
    mode: "multiple",
    allowClear: true,
    placeholder: "Select the services you offer",
    value: selectedTags.selectedSpecialties.map((tag) => tag.tag),
    onSelect: (e: string) => handleToggle(e),
    onDeselect: (e: string) => handleToggle(e),
    defaultValue: selectedTags.selectedSpecialties.map((tag) => tag.tag),
  };
  const selectMenuOptions = selectedTags.selectedCategories.map((category) => (
    <Select.OptGroup label={t(`tags|${category.label}`)} key={category.tag}>
      {category.specialties.map((specialty) => {
        return (
          <Select.Option key={specialty.tag} value={specialty.tag}>
            {t(`tags|${specialty.label}`)}
          </Select.Option>
        );
      })}
    </Select.OptGroup>
  ));

  return (
    <PageLayout
      app="provider"
      className={"partner-category-page"}
      title={t("provider-profile|expertise.specialty.title")}
      subtitle={t("expertise|specialty.subtitle")}
      breadcrumbs={[]}
    >
      <OnboardingHeader />

      <Container>
        <Select {...selectMenuPropsValue} style={{ width: "100%" }}>
          {selectMenuOptions}
        </Select>
      </Container>
      <OnboardingFooter
        handleNext={handleNextStep}
        handlePrev={() => setCurrentStep(OnBoardingSteps.Services)}
        isLoading={loading}
        nextDisabled={selectedTags.selectedSpecialties.length <= 0}
      />
    </PageLayout>
  );
};

export default PartnerSpecialtyPage;

