import React, { CSSProperties, ReactNode } from "react";
import { AppType } from "../../../../features/entities";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../button";
import Div from "../../div";
import { TagProps } from "../../hd-tag";
import Typography from "../../typography";
import PageLayoutTitle from "./page-layout-title";

export interface PageHeadingProps {
  handleBack?: () => void;
  title?: ReactNode;
  subtitle?: ReactNode;
  footnote?: string;
  status?: TagProps;
  actions?: ReactNode;
  app: AppType;
  styles?: {
    actions?: CSSProperties;
    titleContainer?: CSSProperties;
    title?: CSSProperties;
    subtitle?: CSSProperties;
    footnote?: CSSProperties;
    container?: CSSProperties;
  };
}

const PageHeading = ({
  handleBack,
  title,
  subtitle,
  footnote,
  status,
  actions,
  app,
  styles,
}: PageHeadingProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <Div
      flex="column"
      gap={16}
      tablet={{ style: { padding: "25px 21px" } }}
      style={{ position: "relative", ...styles?.container }}
    >
      <Div
        flex="row"
        justify="space-between"
        align="center"
        tablet={{
          flex: "column",
          align: "flex-start",
        }}
        style={{ ...styles?.titleContainer }}
        gap={24}
      >
        {handleBack ? (
          <Button
            isLink
            onClick={handleBack}
            withArrowLeft
            size="small"
            defaultStyle={theme.colors.purple_1}
          >
            {t("button|back")}
          </Button>
        ) : (
          <PageLayoutTitle app={app} title={title} status={status} />
        )}

        {!!actions && (
          <Div fitContent flex="row" gap={16} style={{ ...styles?.actions }}>
            {React.Children.toArray(actions)}
          </Div>
        )}
      </Div>
      {subtitle && (
        <Typography
          elementTheme="body2"
          style={{ maxWidth: 600, ...styles?.subtitle }}
        >
          {subtitle}
        </Typography>
      )}
      {footnote && (
        <Typography
          elementTheme="caption"
          color={theme.colors.grey_3}
          style={{ ...styles?.footnote }}
        >
          {footnote}
        </Typography>
      )}
    </Div>
  );
};

export default PageHeading;

