import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Industry = (props: IconProps) => {
  const { size } = props;
  const strokeWidth = useStrokeWidth(size);
  const iconProps = useIconProps(props);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1523 30V18.3333C18.1523 18.0239 18.2658 17.7272 18.4677 17.5084C18.6697 17.2896 18.9437 17.1667 19.2293 17.1667H23.5375C23.8231 17.1667 24.0971 17.2896 24.2991 17.5084C24.5011 17.7272 24.6145 18.0239 24.6145 18.3333V30M18.1523 30H2.15462M18.1523 30H24.6145M24.6145 30H29.8418M27.8457 30V11.8762C28.4181 11.5183 28.9068 11.0225 29.274 10.4272C29.6412 9.83197 29.877 9.15316 29.9632 8.44335C30.0494 7.73353 29.9837 7.01175 29.7711 6.33385C29.5586 5.65596 29.2049 5.04012 28.7375 4.534L27.0285 2.68444C26.625 2.24673 26.0774 2.00054 25.5063 2H6.48864C5.91753 2.00054 5.37 2.24673 4.96642 2.68444L3.25895 4.53244C2.79257 5.03915 2.43984 5.6551 2.22796 6.3328C2.01608 7.01051 1.9507 7.73187 2.03686 8.44127C2.12302 9.15067 2.35843 9.82917 2.72493 10.4244C3.09143 11.0197 3.57924 11.5159 4.15074 11.8747M4.15074 29.9984V11.8778C5.0124 12.416 6.01941 12.6147 7.00094 12.4404C7.98248 12.266 8.87812 11.7292 9.53595 10.9211C9.93991 11.418 10.4371 11.8159 10.9943 12.0882C11.5514 12.3604 12.1558 12.5008 12.7671 12.5C14.0538 12.5 15.2084 11.8887 15.9982 10.9196C16.402 11.4168 16.8991 11.8149 17.4563 12.0875C18.0135 12.36 18.618 12.5006 19.2293 12.5C20.516 12.5 21.6706 11.8887 22.4605 10.9196C23.1185 11.7274 24.0142 12.2639 24.9957 12.438C25.9772 12.6121 26.9842 12.413 27.8457 11.8747M8.45891 25.3318H13.8441C14.1298 25.3318 14.4037 25.2089 14.6057 24.9901C14.8077 24.7713 14.9212 24.4745 14.9212 24.1651V18.3333C14.9212 18.0239 14.8077 17.7272 14.6057 17.5084C14.4037 17.2896 14.1298 17.1667 13.8441 17.1667H8.45891C8.17326 17.1667 7.89931 17.2896 7.69732 17.5084C7.49534 17.7272 7.38187 18.0239 7.38187 18.3333V24.1667C7.38187 24.8122 7.86438 25.3318 8.45891 25.3318Z"
        strokeWidth={strokeWidth}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Industry;

