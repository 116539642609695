import styled from "../../../../plugins/styled";
import Avatar from "../../../common/avatar";
import CollapsePanel from "../../../common/Collapse/CollapsePanel";
export const RatingsGridContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.colors.grey_5};
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.l}px) {
    flex-direction: column;
  }
`;

export const RatingsGridBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: fit-content;
  padding: 24px 0;
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.colors.grey_5};
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.l}px) {
    padding: 12px 0;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey_5};
  }
`;

export const UserAvatar = styled(Avatar)<{ $isLarge?: boolean }>``;

export const ReviewCollapse = styled(CollapsePanel)`
  &:nth-child(3n) {
    ${UserAvatar} {
      background-color: ${(props) => props.theme.colors.green_1};
    }
  }
  &:nth-child(3n + 2) {
    ${UserAvatar} {
      background-color: ${(props) => props.theme.colors.yellow_1};
    }
  }
  &:nth-child(3n + 1) {
    ${UserAvatar} {
      background-color: ${(props) => props.theme.colors.purple_2};
    }
  }
`;

