import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Delete = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.431 11.5698L18.92 24.8618M11.8486 24.8618L11.3376 11.5698M26.0592 6.82904C26.5643 6.90583 27.0665 6.98706 27.5686 7.0742M26.0592 6.82904L24.4819 27.3326C24.4175 28.1674 24.0404 28.9471 23.426 29.5158C22.8115 30.0845 22.005 30.4003 21.1678 30.4001H9.60083C8.76357 30.4003 7.95709 30.0845 7.34264 29.5158C6.72819 28.9471 6.35107 28.1674 6.2867 27.3326L4.70939 6.82904M26.0592 6.82904C24.3547 6.57134 22.6414 6.37577 20.9226 6.24271M4.70939 6.82904C4.20429 6.90436 3.70215 6.98559 3.20001 7.07272M4.70939 6.82904C6.41391 6.57135 8.12724 6.37578 9.84599 6.24271M20.9226 6.24271V4.88989C20.9226 3.14716 19.5787 1.69391 17.8359 1.63927C16.2019 1.58704 14.5667 1.58704 12.9327 1.63927C11.19 1.69391 9.84599 3.14864 9.84599 4.88989V6.24271M20.9226 6.24271C17.2359 5.95779 13.5327 5.95779 9.84599 6.24271"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Delete;

