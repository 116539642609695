import Div from "@hellodarwin/core/lib/components/common/div";
import Grid from "@hellodarwin/core/lib/components/common/hd-grid";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProjectSingleTile from "@hellodarwin/core/lib/components/project/single/project-content/project-single-tile";
import { Contact } from "@hellodarwin/core/lib/features/entities/core-entities";
import { Match } from "@hellodarwin/core/lib/features/entities/matches-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";

interface ClientDetailsProps {
  match: Match;
  projectContact: Contact;
}
const ClientDetails = ({ match, projectContact }: ClientDetailsProps) => {
  const { t } = useTranslation();

  const Detail = ({ label, value }: { label: string; value?: string }) => {
    if (!value) return <></>;
    return (
      <Div flex={"column"} gap={4} fitContent>
        <Typography elementTheme="overline" bold>{`${label} :`}</Typography>
        <Typography elementTheme="body2">{value}</Typography>
      </Div>
    );
  };
  return (
    <ProjectSingleTile>
      <Typography.Title level={2} elementTheme="h6">
        {t(`project|projectPage.clientDetails`)}
      </Typography.Title>
      <Grid md={1} xl={2} gutter={20}>
        <Detail
          label={t(`project|clientDetails.labels.company`)}
          value={match.project.client?.name}
        />
        <Detail
          label={t(`project|clientDetails.labels.contact`)}
          value={projectContact?.name}
        />
        <Detail
          label={t(`project|clientDetails.labels.email`)}
          value={projectContact?.email}
        />
        <Detail
          label={t(`project|clientDetails.labels.phone`)}
          value={projectContact?.phone}
        />
        <Detail
          label={t(`project|clientDetails.labels.clientTitle`)}
          value={projectContact?.title}
        />
        <Detail
          label={t(`project|clientDetails.labels.preferredLanguage`)}
          value={t(`language.${projectContact?.preferred_language}`)}
        />
      </Grid>
    </ProjectSingleTile>
  );
};

export default ClientDetails;

