import { getWebsiteURL } from ".";
import { Provider } from "../entities/providers-entities";
import SingleLanguage from "../enums/single-language";

const getAgenciesPage = async ({
  provider,
  withReviews,
  locale,
}: {
  provider: Provider;
  withReviews?: boolean;
  validateUrl?: (url: string) => Promise<boolean>;
  locale: SingleLanguage;
}) => {
  if (!provider.slug) return "";
  return `${getWebsiteURL()}/${
    locale === SingleLanguage.French ? "fr/agences" : "agencies"
  }/${provider.slug}${withReviews ? "?reviewOpen=true" : ""}`;
};

export default getAgenciesPage;
