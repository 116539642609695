import { useMemo } from "react";

const useStrokeWidth = (size: number) => {
  return useMemo(() => {
    if (size <= 16) {
      return 1.3;
    } else if (size <= 24) {
      return 1.5;
    } else {
      return 1.8;
    }
  }, [size]);
};

export default useStrokeWidth;

