import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import HdProgression from "@hellodarwin/core/lib/components/common/hd-progression";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import Loading from "@hellodarwin/core/lib/components/loading";
import CompanyType from "@hellodarwin/core/lib/features/enums/company-type";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme/index";
import Hello from "@hellodarwin/icons/dist/icons/Hello";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Checkbox from "antd/es/checkbox/Checkbox";
import Collapse from "antd/es/collapse";
import List from "antd/es/list";
import Progress from "antd/es/progress";
import React, { ReactNode, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app";
import RecentProjects from "../components/project/recent-project";
import {
  fetchScore,
  selectScore,
  selectScoreLoading,
} from "../features/api/slices/dashboard-slice";
import {
  fetchContact,
  selectContact,
  selectProfile,
} from "../features/api/slices/profile-slice";
import { usePartnerApi } from "../features/api/use-partner-api";

import "./dashboard-page.scss";

interface DashboardItem {
  key: string;
  isFullfiled: number;
  link: string;
}

const DashboardPage = () => {
  const { t } = useTranslation();
  const api = usePartnerApi();
  const theme = useTheme() as DefaultTheme;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useAppSelector(selectProfile);
  const providerId = profile.provider_id;
  const score = useAppSelector(selectScore);
  const isLoading = useAppSelector(selectScoreLoading);
  const contact = useAppSelector(selectContact);

  useEffect(() => {
    if (!profile.provider_id) return;
    dispatch(fetchScore({ api, providerId }));
    dispatch(fetchContact({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const teamMembersMax = profile.type === CompanyType.Agency ? 2 : 1;

  const getDataByIndex = (index: number): DashboardItem[] => {
    if (index === 0) {
      return [
        {
          key: "analytics_profile_founded_at_score",
          isFullfiled: score.analytics_profile_founded_at_score,
          link: "/settings/company",
        },
        {
          key: "analytics_profile_description_score",
          isFullfiled: score.analytics_profile_description_score,
          link: "/profile#about",
        },
        {
          key: "analytics_profile_banner_score",
          isFullfiled: score.analytics_profile_banner_score,
          link: "/profile",
        },
        {
          key: "analytics_profile_logo_score",
          isFullfiled: score.analytics_profile_logo_score,
          link: "/profile",
        },
        {
          key: "analytics_profile_tagline_score",
          isFullfiled: score.analytics_profile_tagline_score,
          link: "/profile",
        },
        {
          key: "analytics_profile_website_score",
          isFullfiled: score.analytics_profile_website_score,
          link: "/settings/company",
        },
        {
          key: "analytics_profile_asset_type_score",
          isFullfiled: score.analytics_profile_asset_type_score,
          link: "/profile#video",
        },
        {
          key: "analytics_profile_address_score",
          isFullfiled: score.analytics_profile_address_score,
          link: "/settings/company",
        },
        {
          key: "analytics_profile_language_score",
          isFullfiled: score.analytics_profile_language_score,
          link: "/profile/settings",
        },
        {
          key: "analytics_profile_company_size_score",
          isFullfiled: score.analytics_profile_company_size_score,
          link: "/settings/company",
        },
      ];
    } else if (index === 1) {
      return [
        {
          key: "analytics_profile_team_member_score",
          isFullfiled: score.analytics_profile_team_member_count,
          link: "/profile#team",
        },
      ];
    } else if (index === 2) {
      return [
        {
          key: "analytics_profile_portfolio_score",
          isFullfiled: score.analytics_profile_portfolio_count,
          link: "/profile#portfolios",
        },
      ];
    } else if (index === 3) {
      return [
        {
          key: "analytics_profile_review_score",
          isFullfiled: score.analytics_profile_review_count,
          link: "/profile#reviews",
        },
      ];
    } else {
      return [];
    }
  };

  const getItemTitle = (item: DashboardItem, index: number) => {
    switch (index) {
      case 0:
        return t(`dashboard|transparency.${item.key}.title`);
      case 1:
        return (
          t(`dashboard|team.${item.key}.title`) +
          " " +
          (item.isFullfiled > teamMembersMax
            ? `(${score.analytics_profile_section_team_score}/${teamMembersMax})`
            : `(${score.analytics_profile_team_member_count}/${teamMembersMax})`)
        );
      case 2:
        return (
          t(`dashboard|portfolio.${item.key}.title`) +
          " " +
          (item.isFullfiled > 6
            ? `(${score.analytics_profile_section_portfolio_score}/6)`
            : `(${score.analytics_profile_portfolio_count}/6)`)
        );

      case 3:
        return (
          t(`dashboard|reviews.${item.key}.title`) +
          " " +
          (item.isFullfiled > 15
            ? `(${score.analytics_profile_section_review_score}/15)`
            : `(${score.analytics_profile_review_count}/15)`)
        );
      default:
        return "";
    }
  };

  const getItemSubtitle = (item: DashboardItem, index: number) => {
    switch (index) {
      case 0:
        return t(`dashboard|transparency.${item.key}.subtitle`);
      case 1:
        return t(`dashboard|team.${item.key}.subtitle`);
      case 2:
        return t(`dashboard|portfolio.${item.key}.subtitle`);
      case 3:
        return t(`dashboard|reviews.${item.key}.subtitle`);
      default:
        return "";
    }
  };

  const generateList = (index: number) => (
    <List
      itemLayout="horizontal"
      dataSource={getDataByIndex(index)}
      renderItem={(item, listIndex) => (
        <List.Item>
          <List.Item.Meta
            style={{ alignItems: "center" }}
            avatar={<Checkbox checked={item.isFullfiled >= 1} />}
            title={<Link to={item.link}>{getItemTitle(item, index)}</Link>}
            description={getItemSubtitle(item, index)}
          />
        </List.Item>
      )}
    />
  );

  const getPercent = (index: number) => {
    switch (index) {
      case 0:
        return (score.analytics_profile_section_transparency_score / 10) * 100;
      case 1:
        return (
          (score.analytics_profile_section_team_score / teamMembersMax) * 100
        );
      case 2:
        return (score.analytics_profile_section_portfolio_score / 6) * 100;
      case 3:
        return (score.analytics_profile_section_review_score / 15) * 100;
      default:
        return 0;
    }
  };

  const DashboardHeader = ({
    index,
    title,
    subtitle,
    link,
  }: {
    index: number;
    title: string;
    subtitle?: string | ReactNode;
    link?: string;
  }) => {
    const progressPercent = getPercent(index);

    const handleNavigate = () => {
      if (!!link) {
        navigate(link);
      }
    };
    return (
      <Container
        noPadding
        style={{ padding: "18px 30px", cursor: "pointer" }}
        onClick={handleNavigate}
      >
        <Div flex="row" gap={25} align="center" tablet={{ flex: "column" }}>
          <Progress
            size={25}
            type="circle"
            percent={progressPercent}
            strokeColor={theme.colors.purple_2}
            trailColor={theme.colors.purple_4}
            strokeWidth={15}
            showInfo={false}
          />
          <Div>
            <Typography className="title">{title}</Typography>
            <Typography
              elementTheme="body2"
              color={theme.colors.grey_2}
              className="subtitle"
            >
              {subtitle}
            </Typography>
          </Div>
        </Div>
      </Container>
    );
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <PageLayout
        breadcrumbs={[
          {
            breadcrumbName: t("navigation.home"),
            path: "/",
          },
          {
            breadcrumbName: t("navigation.dashboard"),
          },
        ]}
        app="provider"
        title={
          <Div flex="row" align="center" gap={8} wrap="wrap">
            <Hello style={{ marginBottom: 6 }} />

            <Typography.Title
              level={1}
              elementTheme="h4"
              {...(contact.name.length > 10 ? {} : { nowrap: true })}
              nowrap
            >
              {`${contact.name}!`}
            </Typography.Title>
          </Div>
        }
      >
        <Div flex="row" gap={43} tablet={{ flex: "column" }}>
          <Container fitContent>
            <Typography.Title level={3} elementTheme="h6" textAlign="center">
              {t("navigation.profileQuality")}
            </Typography.Title>
            <Div flex="column" align="center" gap={24}>
              <HdProgression
                size={166}
                barSize={12}
                outerBarSize={14}
                progressBarColor={theme.colors.white_1}
                progressColor={theme.colors.green_2}
                outerColor={theme.colors.green_4}
                value={score.analytics_profile_completed_percentage}
              />
              <Typography
                elementTheme="body2"
                color={theme.colors.grey_2}
                style={{ fontStyle: "italic" }}
                textAlign="center"
              >
                {t(`dashboard|description.text`)}
              </Typography>
            </Div>
          </Container>
          <Div flex="column" gap={21}>
            <Collapse
              expandIconPosition="end"
              className="dashboard-collapse"
              expandIcon={({ isActive }) => <TabArrow down={isActive} />}
              items={[
                {
                  key: "1",
                  label: (
                    <DashboardHeader
                      index={0}
                      title={t(
                        `dashboard|headerCollapse.profileOptimizationHeader`,
                        {
                          score:
                            score.analytics_profile_section_transparency_score,
                        }
                      )}
                    />
                  ),
                  children: generateList(0),
                },
              ]}
              bordered={false}
              style={{
                width: "100%",
                padding: 0,
                background: theme.colors.white_2,
              }}
            />
            {React.Children.toArray(
              [1, 2, 3].map((index) =>
                getDataByIndex(index).map((item) => (
                  <DashboardHeader
                    index={index}
                    title={getItemTitle(item, index)}
                    subtitle={getItemSubtitle(item, index)}
                    link={item.link}
                  />
                ))
              )
            )}
          </Div>
        </Div>
        <RecentProjects />
      </PageLayout>
    </>
  );
};

export default DashboardPage;

