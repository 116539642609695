import { AppType } from "../entities";

type MeetintType =
  | "propulsion"
  | "forms"
  | "projects"
  | "grants-qualification"
  | "grants-qualification-icp";

const getMeetingUrl = (
  lang: string,
  app: AppType,
  type?: MeetintType
): string => {
  let meetingUrl = "";

  if (!type) type = "projects";

  if (typeof window !== "undefined") {
    switch (type) {
      case "propulsion":
        meetingUrl =
          "https://meetings.hubspot.com/hellodarwin/rencontre-informative-in";
        break;
      case "grants-qualification":
        meetingUrl =
          lang === "en"
            ? "https://meetings.hubspot.com/hellodarwin-website/discovery-call-en?utm_source=routing-form-redirect&utm_medium=website&utm_campaign=grants-qualification"
            : "https://meetings.hubspot.com/hellodarwin-website/discovery-call-fr?utm_source=routing-form-redirect&utm_medium=website&utm_campaign=grants-qualification";
        break;
      case "grants-qualification-icp":
        meetingUrl =
          lang === "en"
            ? "https://meetings.hubspot.com/hellodarwin/informative-meeting?utm_source=routing-form-redirect&utm_medium=website&utm_campaign=grants-qualification"
            : "https://meetings.hubspot.com/hellodarwin/rencontre-informative-ext?utm_source=routing-form-redirect&utm_medium=website&utm_campaign=grants-qualification";
        break;

      default:
        meetingUrl =
          lang === "en"
            ? `https://meetings.hubspot.com/hellodarwin-website/hd-website-discovery-call-link-en?utm_source=helloDarwin&utm_medium=${app}-app&utm_campaign=${type}`
            : `https://meetings.hubspot.com/hellodarwin-website/hd-website-discovery-call-link-fr?utm_source=helloDarwin&utm_medium=${app}-app&utm_campaign=${type}`;
        break;
    }
  }

  return meetingUrl;
};

export default getMeetingUrl;

