import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";

const FacebookLogo = (props: IconProps) => {
  const iconProps = useIconProps(props);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5108 29.4401H17.3719V17.7033H22.6527L23.2329 11.8715H17.3719V8.92632C17.3719 8.53771 17.5262 8.16501 17.801 7.89022C18.0758 7.61543 18.4485 7.46105 18.8371 7.46105H23.2329V1.59998H18.8371C16.8941 1.59998 15.0306 2.37186 13.6566 3.74581C12.2827 5.11977 11.5108 6.98325 11.5108 8.92632V11.8715H8.58025L8 17.7033H11.5108V29.4401Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FacebookLogo;

