import { AppType } from "../../../features/entities";
import useMediaQuery from "../../../features/helpers/use-media-query";
import theme from "../../../theme";
import MobileOnboardingSider from "./mobile-onboarding-sider";

type OnboardingSiderProps = {
  appType: AppType;
};

const OnboardingSider = ({ appType }: OnboardingSiderProps) => {
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  if (!isTablet) return <></>;
  return <MobileOnboardingSider appType={appType} />;
};

export default OnboardingSider;

