import { CSSProperties, useMemo } from "react";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTheme } from "../../../../plugins/styled";
import {
  ParagraphProps,
  TextStylingProps,
  ViewParagraphProps,
} from "../entities";

const useTypographyProps = (props: ParagraphProps) => {
  const theme = useTheme();
  const isXL = useMediaQuery({ maxWidth: theme.breakpoints.l });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  return useMemo(() => {
    const currentProps: TextStylingProps = {};
    const currentStyle: CSSProperties = {};

    const addProp = (property, value) => {
      if (value !== undefined) {
        currentProps[property] = value;
      }
    };

    const addStyling = (property, value) => {
      if (value !== undefined) {
        currentStyle[property] = value;
      }
    };

    const prepareStyling = (data: ViewParagraphProps) => {
      const { style, ...rest } = data;
      addStyling("textTransform", data?.textTransform);
      addStyling("textAlign", data?.textAlign);
      addStyling("letterSpacing", data?.letterSpacing);

      Object.entries(rest).forEach(([key, value]) => {
        addStyling(key, value);
      });

      if (data.style) {
        Object.entries(data.style).forEach(([key, value]) => {
          addStyling(key, value);
        });
      }
    };

    const prepareProps = (data: ViewParagraphProps) => {
      if (data) {
        const {
          bold,
          medium,
          strike,
          color,
          colorHover,
          underline,
          elementTheme,
          center,
          ellipsis,
          fitContent,
          nowrap,
          inline,
          font,
          lineClamp,
          overflow,
          normal,
          ...rest
        } = data;

        const propMappings = {
          $bold: bold,
          $medium: medium,
          $strike: strike,
          $color: color,
          $colorHover: colorHover,
          $underline: underline,
          $size: elementTheme,
          $center: center,
          $ellipsis: ellipsis,
          $fitContent: fitContent,
          $nowrap: nowrap,
          $inline: inline,
          $font: font,
          $lineClamp: lineClamp,
          $overflow: overflow,
          $normal: normal,
        };

        Object.entries(propMappings).forEach(([key, value]) => {
          addProp(key, value);
        });

        prepareStyling(rest);
      }
    };

    const {
      children,
      onClick,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      onPointerEnter,
      onPointerLeave,
      id,
      className,
      elementTheme,
      xl,
      tablet,
      ...viewProps
    } = props;

    const defaultProps = {
      children,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onPointerEnter,
      onPointerLeave,
      onBlur,
      id,
      className,
      elementTheme,
    };
    prepareProps(viewProps);
    if (isXL) prepareProps(xl);
    if (isTablet) prepareProps(tablet);

    return { currentProps, currentStyle, defaultProps };
  }, [isTablet, isXL, props]);
};
export default useTypographyProps;

