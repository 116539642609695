import { useMemo } from 'react';
import { ApolloClient, InMemoryCache } from '../../plugins/apollo';

export const useApolloClient = (spaceId: string, accessToken: string) => {
  return useMemo(() => new ApolloClient({
    uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    cache: new InMemoryCache()
  }), [spaceId, accessToken]);
};


export default useApolloClient;