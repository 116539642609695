import { CSSProperties } from "react";
import styled, { css } from "../../../plugins/styled";
import Button from "../button";
import { ArrowContainer } from "../button/arrow/styles";

interface CardStyledProps {
  $hoverable?: boolean;
  $backgroundColor?: CSSProperties["backgroundColor"];
}

export const CardButton = styled(Button)`
  width: 32px;
  height: 32px;
`;

const CardHoverStyled = css`
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  ${CardButton} {
    width: 45px;
  }
  ${ArrowContainer} {
    width: 30px;
  }
`;

export const CardStyled = styled.div<CardStyledProps>`
  border-radius: 0px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ $backgroundColor, theme }) =>
    !!$backgroundColor ? $backgroundColor : theme.colors.white_1};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};

  .card-body {
    width: 100%;
    height: 100%;
  }
  &:hover {
    ${({ $hoverable }) => ($hoverable ? CardHoverStyled : undefined)}
  }
`;

