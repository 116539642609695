import { QA } from "../../features/entities/core-entities";
import Collapse from "../common/Collapse";
import Typography from "../common/typography";
import FaqBlock from "./faq-block";
import { FaqContentContainer } from "./styles";

const FaqContent = ({
  QAs,
  defaultActiveKey,
}: {
  QAs: QA[];
  defaultActiveKey?: string;
}) => {
  return (
    <FaqContentContainer>
      <Collapse
        gap={10}
        accordion
        items={QAs.map((value, i) => ({
          id: value.question,
          title: (
            <Typography.Title
              key={i}
              level={3}
              elementTheme="subtitle2"
              noMargin
            >
              {value.question}
            </Typography.Title>
          ),
          children: <FaqBlock qa={value} />,
          forceRender: true,
        }))}
      />
    </FaqContentContainer>
  );
};

export default FaqContent;

