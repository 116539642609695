import { UserMenuProps } from ".";
import Div from "../../../common/div";
import SubNavigationMenuItemsList from "../../navigation/menu-item/subitems-list";
import useHandleLinkClick from "../../navigation/utils/use-handle-link-click";
import UserMenuButton from "./user-menu-button";

const MobileUserMenu = ({ user, links, app, notifs }: UserMenuProps) => {
  const handleLinkClick = useHandleLinkClick({ selectedKeys: [] });

  return (
    <Div flex="column" gap={26} className="user-menu">
      <UserMenuButton user={user} app={app} isOnNav notifs={notifs} />
      <SubNavigationMenuItemsList
        items={links}
        app={app}
        handleLinkClick={handleLinkClick}
        size="large"
        removeBorder
      />
    </Div>
  );
};

export default MobileUserMenu;

