import React, { useRef } from "react";
import Typography from "../typography";
import { MenuContainer, MenuContent } from "./styles";

export interface SingleHeaderMenuItem {
  label: string;
  id: string;
}

export interface SingleHeaderMenuProps {
  items: SingleHeaderMenuItem[];
}

const SingleHeaderMenu = ({ items }: SingleHeaderMenuProps) => {
  const ref = useRef<HTMLDivElement>();

  const handleScroll: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.persist();
    const menuItem = e.target as HTMLAnchorElement;

    // Get the container element
    const container = ref.current;

    if (container) {
      const containerWidth = container.offsetWidth;
      const scrollLeft = container.scrollLeft;
      const menuItemLeft = menuItem.offsetLeft;
      const menuItemWidth = menuItem.offsetWidth;
      const menuItemRight = menuItemLeft + menuItemWidth;

      // Check if the item is overflowing on the right side
      if (menuItemRight > scrollLeft + containerWidth) {
        container.scrollTo({
          left: menuItemRight - containerWidth + 40,
          behavior: "smooth",
        });
      }

      // Check if the item is overflowing on the left side
      if (menuItemLeft < scrollLeft) {
        container.scrollTo({
          left: menuItemLeft - 40,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <MenuContainer>
      <MenuContent ref={ref}>
        {React.Children.toArray(
          items.map(
            (s) =>
              !!s.label.length && (
                <a
                  href={`#${s.id}`}
                  onMouseOver={handleScroll}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <Typography
                    elementTheme="body2"
                    style={{
                      padding: "8px 24px",
                      cursor: "pointer",
                    }}
                    nowrap
                  >
                    {s.label}
                  </Typography>
                </a>
              )
          )
        )}
      </MenuContent>
    </MenuContainer>
  );
};

export default SingleHeaderMenu;

