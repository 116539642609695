import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Email = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2222 8.53334V23.4667C30.2222 25.2302 28.7489 26.6667 26.9402 26.6667H5.05983C3.25106 26.6667 1.77778 25.2302 1.77778 23.4667V8.53334M30.2222 8.53334C30.2222 6.76979 28.7489 5.33334 26.9402 5.33334H5.05983C3.25106 5.33334 1.77778 6.76979 1.77778 8.53334M30.2222 8.53334V8.87468C30.2222 9.98401 29.6387 11.0222 28.6614 11.6053L17.7213 18.1618C16.671 18.8018 15.329 18.8018 14.2787 18.1618L3.33858 11.6053C2.36125 11.0222 1.77778 9.99823 1.77778 8.87468V8.53334"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Email;

