import Copy from "@hellodarwin/icons/dist/icons/Copy";
import TextArea, { TextAreaRef } from "antd/es/input/TextArea";
import Segmented from "antd/es/segmented";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { newClientAnalyticsUser } from "../../../../features/entities";
import { copyToClipboard } from "../../../../features/helpers";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";

type BadgesFormProps = {
  numberReviews: string;
  providerId: string;
  average: string;
  handleClipboardAnalytics?: () => void;
};

const BadgesForm = ({
  numberReviews,
  providerId,
  average,
  handleClipboardAnalytics,
}: BadgesFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const lightness = [
    t(`expertise|badges.LightnessType.type1`),
    t(`expertise|badges.LightnessType.type2`),
  ];

  const size = [
    t(`expertise|badges.SizeType.type1`),
    t(`expertise|badges.SizeType.type2`),
    t(`expertise|badges.SizeType.type3`),
  ];
  const language = [
    t(`expertise|badges.language.type2`),
    t(`expertise|badges.language.type1`),
  ];
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [containerHTML, setContainerHTML] = useState<HTMLElement>();

  const [sizeBadges, setSize] = useState("Large");
  const [languages, setLanguages] = useState("English");
  const [lightnesses, setlight] = useState(lightness[0]);

  useEffect(() => {
    if (scriptLoaded) {
      const badgesContainer = document.getElementById("badges-container");
      if (badgesContainer) {
        badgesContainer.dataset.color = "#ff0000";
        badgesContainer.dataset.size = sizeBadges;
        badgesContainer.dataset.lightness =
          lightnesses == "Blanc" || lightnesses == "Light" ? "Light" : "Dark";
        badgesContainer.dataset.language =
          languages === language[0] ? "en" : "fr";
        updateContainerHTML();
      }
      const badgeWidgetOptions = {
        color: "#ff0000",
        size: sizeBadges,
        lightness:
          lightnesses == "Blanc" || lightnesses == "Light" ? "Light" : "Dark",
        language: languages,
        rating: 5,
        numberReviews: numberReviews as any,
      };

      // Update or create badge widget for idBadgeVerified
      const idBadgeVerifiedElement = document.getElementById("idBadgeVerified");
      if (typeof createBadgeWidget === "function" && idBadgeVerifiedElement) {
        const badgeWidgetHtml = createBadgeWidget(badgeWidgetOptions);
        idBadgeVerifiedElement.innerHTML = badgeWidgetHtml;
      }

      const starRatingContainer = document.createElement("div");
      starRatingContainer.classList.add("star-rating");
      for (let i = 1; i <= 5; i++) {
        const star = document.createElement("span");
        star.classList.add("star");
        if (i <= +average) {
          star.classList.add("filled");
          star.innerHTML = "\u2605";
          star.style.color = lightnesses == lightness[0] ? "#2b2b2b" : "white";
        } else {
          star.classList.add("filled");
          star.innerHTML = "\u2605";
          star.style.color = "gray";
        }
        starRatingContainer.appendChild(star);
        starRatingContainer.style.marginTop = "12%";
      }
      let pElement = document.createElement("p");
      pElement.style.fontSize = "85%";

      pElement.style.color = "var(--Grey-2, #5C5C5C)";
      pElement.style.display = "flex";
      pElement.style.marginTop = "1%";
      pElement.style.marginLeft = "0.5rem";
      pElement.style.width = "100%";
      pElement.style.height = "100%";

      let borderSpan = document.createElement("span");
      borderSpan.style.marginRight = "0.5rem";

      borderSpan.style.borderLeft = `1px solid ${
        lightnesses == lightness[0] ? theme.colors.grey_1 : theme.colors.white_1
      }`;

      let textSpan = document.createElement("span");

      if (languages === language[0]) {
        textSpan.textContent = `${numberReviews} REVIEWS`;
      } else {
        textSpan.textContent = `${numberReviews} AVIS`;
      }

      textSpan.style.color = lightnesses == lightness[0] ? "#2b2b2b" : "white";

      pElement.appendChild(borderSpan);
      pElement.appendChild(textSpan);
      starRatingContainer.appendChild(pElement);
      starRatingContainer.style.display = "flex";
      if (languages === language[0]) {
        starRatingContainer.style.marginTop = "10%";
      }
      const divReviews = document.getElementById("divReviews");

      if (divReviews) {
        const starRatingContainer1 = starRatingContainer.cloneNode(true);
        divReviews.appendChild(starRatingContainer1);
      }
    } else {
      const badgesContainer = document.getElementById("badges-container");
      if (badgesContainer) {
        badgesContainer.dataset.color = "#ff0000";
        badgesContainer.dataset.size = sizeBadges;
        badgesContainer.dataset.lightness = lightnesses;
        badgesContainer.dataset.language =
          languages === language[0] ? "en" : "fr";
      }

      const existingBadgeWidget2 = document.getElementById("idBadgeVerified");
      existingBadgeWidget2.innerHTML = "";
      const script = document.createElement("script");

      script.src =
        "https://hellodarwin-core.s3.amazonaws.com/badgeComponent.js";
      script.async = true;

      script.onload = () => {
        const badgeWidgetOptions = {
          color: "#ff0000",
          size: sizeBadges,
          lightness: lightnesses,
          language: languages,
          rating: 5,
          numberReviews: numberReviews as any,
        };

        if (typeof createBadgeWidget === "function") {
          const badgeWidgetHtml = createBadgeWidget(badgeWidgetOptions);
          const isBadgetVerified = document.getElementById("idBadgeVerified");
          if (isBadgetVerified)
            isBadgetVerified.insertAdjacentHTML("afterbegin", badgeWidgetHtml);
        }
        setScriptLoaded(true);
      };
      document.head.appendChild(script);
    }
  }, [sizeBadges, lightnesses, languages, numberReviews, scriptLoaded]);

  if (document.getElementById("badges-container")) {
    const htmlDiv = document.getElementById("badges-container");
    htmlDiv.dataset.provider = providerId;
  }

  const updateContainerHTML = () => {
    if (scriptLoaded) {
      const container = document.getElementById(
        "badges-container"
      ) as HTMLElement;

      setContainerHTML(container.outerHTML as any);
    }
  };

  const generateScriptTagVerified = () => {
    if (!scriptLoaded) {
      return "";
    }
    const scriptContent = `${containerHTML} <script src="https://hellodarwin-core.s3.amazonaws.com/componentScriptVerified.js" async></script>
    `;

    return scriptContent;
  };

  const Header = () => {
    return (
      <Div
        gap={170}
        flex="row"
        tablet={{ flex: "column" }}
        style={{ display: "flex" }}
        justify="center"
      >
        <Option title={t(`expertise|badges.LayoutTitle.lightness`)}>
          <Segmented
            options={lightness}
            onChange={(val: string) => {
              setlight(val);
            }}
            value={lightnesses}
          />
        </Option>

        <Option title={t(`expertise|badges.LayoutTitle.language`)}>
          <Segmented
            options={language}
            onChange={(val: string) => {
              setLanguages(val);
            }}
            value={languages}
          />
        </Option>
      </Div>
    );
  };

  return (
    <Div flex="column" gap={20}>
      <div id="badges-container"></div>
      <Typography>{t(`expertise|badges.subtitle`)}</Typography>

      <Div
        bordersSide="horizontal"
        borderColor={theme.colors.grey_5}
        style={{
          padding: "1rem 0",
        }}
      >
        <Header />
      </Div>
      <Div flex="row" gap={20}>
        <BadgeData
          id="idBadgeVerified"
          title={t(`expertise|badges.verified`)}
          scriptData={generateScriptTagVerified()}
          handleClipboardAnalytics={handleClipboardAnalytics}
        />
      </Div>
    </Div>
  );
};

const Option = ({
  title,
  children,
  style,
}: { title: string; style?: React.CSSProperties } & PropsWithChildren) => {
  return (
    <Div style={style} flex="column" fitContent gap={6}>
      <Typography.Title level={6 as any} fitContent>
        {title}
      </Typography.Title>
      {children}
    </Div>
  );
};

const BadgeData = ({
  id,
  title,
  scriptData,
  handleClipboardAnalytics,
}: {
  id: string;
  title: string;
  scriptData: string;
  handleClipboardAnalytics?: () => void;
}) => {
  const textarea = useRef<TextAreaRef>(null);
  const theme = useTheme();

  const handleCopyTextarea = () => {
    handleClipboardAnalytics();
    if (!!textarea.current) {
      copyToClipboard(textarea.current.resizableTextArea.textArea.value);
    }
    newClientAnalyticsUser;
  };
  return (
    <Div flex="column" gap={20}>
      <Typography.Title level={6 as any} textAlign="center">
        {title}
      </Typography.Title>
      <Div flex="column" gap={20}>
        <Div id={id} flex="column" align="center">
          {""}
        </Div>

        <Div flex="row" gap={10}>
          <TextArea
            style={{
              flex: 5,
              resize: "none",
              textAlign: "center",
              paddingBottom: "5%",
            }}
            ref={textarea}
            value={scriptData}
            readOnly
          />
          <Button
            onClick={handleCopyTextarea}
            defaultStyle={theme.colors.white_1}
            style={{ borderRadius: 4 }}
            headingIcon={<Copy />}
          />
        </Div>
      </Div>
    </Div>
  );
};

export default BadgesForm;

