import reactParser, {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";
import Typography from "../../components/common/typography";

export { Element, HTMLReactParserOptions, attributesToProps, domToReact };

export const parseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs && domNode.name === "a") {
      const props = attributesToProps(domNode.attribs);

      return (
        <a {...props} rel="nofollow" target="_blank">
          {domToReact(domNode.children)}
        </a>
      );
    }

    if (domNode.attribs && domNode.name === "p") {
      return <Typography>{domToReact(domNode.children)}</Typography>;
    }
    if (domNode.attribs && domNode.name === "img") {
      return <></>;
    }
  },
};

const parse = (
  content: string,
  options: HTMLReactParserOptions = parseOptions
) => {
  return reactParser(content, options);
};

export default parse;

