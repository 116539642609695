import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const ArrowOutline = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...iconProps.style, borderRadius: 99 }}
    >
      <rect
        width="22"
        height="22"
        rx="11"
        fill="#F5F5F5"
        className="background"
      />
      <path
        d="M12.1185 15.3071L7.88086 11.0002L12.1185 6.69321"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        className="arrow"
      />
    </svg>
  );
};

export default ArrowOutline;

