import { useMemo } from "react";
import { useAppSelector } from "../../app/app-hooks";
import { selectProfile } from "../api/slices/profile-slice";
import PartnerAnalytics from "./partner-analytics";

export const usePartnerAnalytics = () => {
  const partner = useAppSelector(selectProfile);
  const analytics = window.analytics;
  return useMemo(() => new PartnerAnalytics(analytics, partner), [
    analytics,
    partner,
  ]);
};
