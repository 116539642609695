/* eslint-disable react-hooks/exhaustive-deps */
import FileOutlined from "@ant-design/icons/FileOutlined";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProjectDescription from "@hellodarwin/core/lib/components/project/single/project-content/project-description";
import { Match } from "@hellodarwin/core/lib/features/entities";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { TabsProps } from "antd/es/tabs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  fetchContactById,
  selectActiveTab,
  selectContactById,
  setActiveTab,
} from "../../features/api/slices/global-slice";
import { usePartnerApi } from "../../features/api/use-partner-api";
import ClientDetails from "./client-details";
import ProjectHeader from "./project-banner/project-header";
import ProjectDetails from "./project-details";
import ProjectShortlistMessage from "./project-shortlist-message";

interface SingleProjectProps {
  match: Match;
  defaultTab: "project" | "client" | "attachment";
}

const SingleProject = ({ match, defaultTab }: SingleProjectProps) => {
  const { t } = useTranslation();

  const activeTab = useAppSelector(selectActiveTab);
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const [available, setAvailable] = useState(true);
  const projectContact = useAppSelector((state) =>
    selectContactById(state, match?.project.contact_id || "")
  );

  const navigate = useNavigate();

  const handleTabClick = (tab: string) => dispatch(setActiveTab(tab));
  useEffect(() => {
    handleTabClick(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case "project":
        navigate(`/projects/${match.match_id}`, { replace: true });
        break;
      case "client":
        navigate(`/projects/${match.match_id}/client`, { replace: true });
        break;
      case "attachment":
        navigate(`/projects/${match.match_id}`, { replace: true });
        break;
      default:
        navigate(`/projects/${match.match_id}`, { replace: true });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (
      !match.purchased_at &&
      !match.raised_hand_at &&
      match.project.status !== ProjectStatus.Active
    ) {
      setAvailable(false);
    } else {
      setAvailable(true);
    }
    if (match?.project?.contact_id && match.purchased_at) {
      dispatch(
        fetchContactById({ api, contactId: match?.project?.contact_id })
      );
    }
  }, [match]);

  useEffect(() => {
    if (!match?.project?.assets?.length && activeTab === "attachment") {
      setActiveTab("project");
    }
    if (!match.project.client?.contacts?.length && activeTab === "client") {
      setActiveTab("project");
    }
  }, [activeTab, match]);

  const getProjectContent = (tab: string) => {
    switch (tab) {
      case "attachment":
        return (
          <>
            <Typography.Title level={4}>
              {t(`project|attachmentInfo.attachmentDetails`)}
            </Typography.Title>
            {match.project?.assets?.map((asset, i) => (
              <Div flex="row" align="center" justify="space-between" key={i}>
                <a href={asset.url} download={asset.url}>
                  <Div flex="row" align="center" justify="space-between">
                    <FileOutlined style={{ padding: 6 }} />
                    <Typography elementTheme="body2" bold>
                      {asset.filename}
                    </Typography>
                  </Div>
                </a>
              </Div>
            ))}
          </>
        );
      case "client":
        return (
          <Div flex="column" gap={32}>
            <ProjectShortlistMessage
              projectContact={projectContact}
              match={match}
            />
            <ClientDetails projectContact={projectContact} match={match} />
          </Div>
        );
      case "project":
      default:
        return (
          <>
            <ProjectShortlistMessage
              projectContact={projectContact}
              match={match}
            />
            <ProjectDescription isPartner project={match.project} />
          </>
        );
    }
  };

  const tabs = useMemo(() => {
    const projectTabData = projectContact ? ["client", "project"] : ["project"];

    const hasAssets =
      match.project.assets && Object.keys(match.project.assets).length > 0;

    if (hasAssets) {
      projectTabData.push("attachment");
    }

    const tabs: TabsProps["items"] = projectTabData.map((tab, i) => {
      return {
        key: tab,
        label: (
          <Typography key={tab} elementTheme="body2">
            {t(`project|projectPage.${tab}Details`)}
            {tab === "attachment" && hasAssets && (
              <span className="count">{match.project.assets?.length}</span>
            )}
          </Typography>
        ),
        children: (
          <ProjectDetails
            match={match}
            available={available}
            projectContent={getProjectContent(tab)}
          />
        ),
      };
    });
    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, available]);

  return (
    <Container>
      <ProjectHeader
        tabs={tabs}
        handleTabClick={handleTabClick}
        match={match}
        price={match.project.lead_price || 0}
        discount={match.discounted_amount || 0}
        available={available}
        activeTab={activeTab}
      />
    </Container>
  );
};

export default SingleProject;

