import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import { Match } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Tooltip from "antd/es/tooltip";

import getStatusColor from "../../../features/helpers/get-project-status-color";

type ProjectStatusProps = {
  status: Match["status"];
  statusMessage?: string;
  tooltip: string;
};

const ProjectStatus = ({
  status,
  statusMessage,
  tooltip,
}: ProjectStatusProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={tooltip}>
      {""}
      <HdTag
        text={t(`project|projectStatus|${status}`) + statusMessage}
        color={getStatusColor(status)}
      />
    </Tooltip>
  );
};

export default ProjectStatus;
