import styled, {
  DefaultTheme,
  ThemeContext,
  ThemeProvider,
  createGlobalStyle,
  css,
  keyframes,
  useTheme,
  withTheme,
} from "styled-components";

export default styled;
export {
  DefaultTheme,
  ThemeContext,
  ThemeProvider,
  createGlobalStyle,
  css,
  keyframes,
  useTheme,
  withTheme,
};

