import { AppType } from "../../../features/entities";
import { Burger, BurgerBar } from "./styles";

type BurgerProps = {
  className?: string;
  onClick: () => void;
  app?: AppType;
  isOpen?: boolean;
};

const NavigationBurger = ({ className, onClick, app, isOpen }: BurgerProps) => {
  return (
    <Burger
      className={`hd-navigation-burger ${className}`}
      onClick={onClick}
      $app={app}
      $isOpen={isOpen}
    >
      <BurgerBar />
      <BurgerBar />
      <BurgerBar />
    </Burger>
  );
};

export default NavigationBurger;

