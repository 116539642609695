import { useState } from "react";
import {
  Contact,
  MappedTags,
  Portfolio,
  ProfileModalProps,
  Provider,
  ServiceTag,
  TeamMember,
  TeamMemberItem,
} from "../../../../features/entities";
import ProfileBlocks from "../../../../features/enums/profile-blocks";
import { useTranslation } from "../../../../plugins/i18n";
import ModalWithHeaderFooter from "../../../common/hd-modal/layouts/modal-with-header-footer";
import AboutForm from "./about-form";
import ExpertiseEdition from "./expertise/expertise-edition";
import FastInfosForm from "./fast-infos-form";
import PortfolioForm from "./portfolio-form";
import ProfileAssetsForm from "./profile-assets-form";
import TeamEdit from "./team/team-edit";

export interface ProfileEditModalFunctions {
  isLoading: boolean;
  fetchProfile?: () => Promise<any>;
  updateProfile?: ({ data }: { data: FormData }) => Promise<any>;
  submitPortfolio?: ({ data }: { data: FormData }) => Promise<any>;
  updatePortfolio?: ({ data }: { data: FormData }) => Promise<any>;
  removePortfolio?: ({ portfolioID }: { portfolioID: string }) => Promise<any>;
  updateBanner?: ({
    data,
    companyId,
  }: {
    data: FormData;
    companyId: string;
  }) => Promise<any>;
  updateLogo?: ({
    data,
    companyId,
  }: {
    data: FormData;
    companyId: string;
  }) => Promise<any>;
  updateVideos?: ({
    data,
    companyId,
  }: {
    data: FormData;
    companyId: string;
  }) => Promise<any>;
  submitTeamMember?: ({ data }: { data: FormData }) => Promise<any>;
  editTeamMember?: ({
    data,
    teamMemberId,
  }: {
    data: FormData;
    teamMemberId: string;
  }) => Promise<any>;
  removeTeamMember?: ({
    teamMemberID,
  }: {
    teamMemberID: string;
  }) => Promise<any>;
  deleteBanner?: ({ companyId }: { companyId: string }) => Promise<any>;
  deleteLogo?: ({ companyId }: { companyId: string }) => Promise<any>;
  deleteVideos?: ({ companyId }: { companyId: string }) => Promise<any>;
  handleServiceChange?: ({
    service,
    minBudget,
    maxBudget,
    description,
  }: {
    service: string;
    minBudget: number;
    maxBudget: number;
    description: string;
  }) => void;
  createTags?: ({
    serviceTags,
    specialtyTags,
  }: {
    serviceTags: ServiceTag[];
    specialtyTags: string[];
  }) => Promise<any>;
  toggleCategoryTag?: (category: string) => void;
  toggleServiceTag?: ({ service }: { service: string }) => void;
  toggleSpecialtyTag?: ({ specialty }: { specialty: string }) => void;
  onboardingMatch?: () => Promise<any>;
  updateTeamMemberOrder?: ({
    teamMembers,
  }: {
    teamMembers: TeamMemberItem[];
  }) => void;
}

export interface ProfileEditModalProps {
  profile: Provider;
  teamMembers?: TeamMember[];
  modal: ProfileModalProps;
  handleCancel: () => void;
  functions: ProfileEditModalFunctions;
  tags?: MappedTags;
  activePortfolio?: Portfolio;
  contact?: Contact;
  setTeamMembers?: (value: TeamMember[]) => void;
}

const ProfileEditModal = ({
  profile,
  teamMembers,
  modal,
  handleCancel,
  tags,
  functions,
  activePortfolio,
  contact,
  setTeamMembers,
}: ProfileEditModalProps) => {
  const { t } = useTranslation();

  const { open, type, targetId } = modal;
  const [footer, setFooter] = useState<JSX.Element | undefined>(undefined);

  const renderFormBasedOnType = () => {
    switch (type) {
      case ProfileBlocks.About:
        return (
          <AboutForm
            provider={profile}
            setFooter={setFooter}
            handleCancel={handleCancel}
            functions={functions}
          />
        );
      case ProfileBlocks.FastInfos:
        return (
          <FastInfosForm
            provider={profile}
            setFooter={setFooter}
            handleCancel={handleCancel}
            functions={functions}
          />
        );

      case ProfileBlocks.Presentation:
        return (
          <ProfileAssetsForm
            handleCancel={handleCancel}
            company_id={profile?.company_id}
            type="video"
            asset_url={profile?.assets?.[0]?.asset_url || ""}
            asset_title={profile?.assets?.[0]?.asset_title || ""}
            setFooter={setFooter}
            functions={functions}
          />
        );
      case ProfileBlocks.Logo:
        return (
          <ProfileAssetsForm
            handleCancel={handleCancel}
            company_id={profile?.company_id}
            type="logo"
            asset_url={profile?.logo || ""}
            setFooter={setFooter}
            functions={functions}
          />
        );
      case ProfileBlocks.Banner:
        return (
          <ProfileAssetsForm
            handleCancel={handleCancel}
            company_id={profile?.company_id}
            type="banner"
            asset_url={profile?.banner || ""}
            setFooter={setFooter}
            functions={functions}
          />
        );
      case ProfileBlocks.Expertise:
        return (
          <ExpertiseEdition
            handleCancel={handleCancel}
            setFooter={setFooter}
            tags={tags}
            functions={functions}
          />
        );
      case ProfileBlocks.Portfolios:
        return (
          <PortfolioForm
            provider={profile}
            handleCancel={handleCancel}
            setFooter={setFooter}
            activePortfolio={activePortfolio}
            tags={tags}
            functions={functions}
          />
        );
      case ProfileBlocks.Team:
        return (
          <TeamEdit
            functions={functions}
            activeMemberId={targetId}
            setFooter={setFooter}
            provider={profile}
            teamMembers={teamMembers}
            handleCancel={handleCancel}
            setTeamMembers={setTeamMembers}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <ModalWithHeaderFooter
      open={open}
      handleCancel={handleCancel}
      size="full"
      header={
        <ModalWithHeaderFooter.Header
          title={t(`provider-profile|${type}.edit.title`)}
        />
      }
      footer={footer}
      noPadding={[
        ProfileBlocks.Team,
        ProfileBlocks.Logo,
        ProfileBlocks.Presentation,
        ProfileBlocks.Banner,
        undefined,
      ].includes(type)}
    >
      {renderFormBasedOnType()}
    </ModalWithHeaderFooter>
  );
};

export default ProfileEditModal;

