import { ReactNode, useMemo } from "react";
import { Project } from "../../../features/entities/project-entities";
import { useTranslations } from "../../../features/providers/translations-provider";
import Div from "../../common/div";
import SeeMoreList from "../../common/see-more/see-more-list";
import Loading from "../../loading";
import ProfileSection from "../profile/profile-section";
import ProjectCard from "./ProjectCard";

type ProfileProjectsProps = {
  isLoading?: boolean;
  projects: Project[];
  isEditable?: boolean;
  id: string;
};

const ProfileProjects = ({
  projects,
  isLoading,
  isEditable = false,
  id,
}: ProfileProjectsProps) => {
  const { t } = useTranslations();
  const content = useMemo<ReactNode[]>(() => {
    const content: ReactNode[] = [];
    let index = 0;
    projects.forEach((project) => {
      index++;
      content.push(<ProjectCard {...project} />);
    });
    return content;
  }, [projects]);

  return (
    <>
      <ProfileSection
        title={t("profile_projects|projectsTitle")}
        isEditable={isEditable}
        id={id}
        hide={!projects.length}
      >
        <Div style={{ display: "flex", flexDirection: "column" }} gap={32}>
          {isLoading ? (
            <Loading />
          ) : (
            <SeeMoreList
              limit={3}
              grid={{ sm: 1, md: 1, lg: 1, column: 1, gutter: 24 }}
              content={content}
            />
          )}
        </Div>
      </ProfileSection>
    </>
  );
};
export default ProfileProjects;

