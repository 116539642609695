export type DefaultTheme = typeof theme;
const theme = {
  colors: {
    primary: "#2b2b2b",
    secondary: "#ced000",
    tertiary: "#2b2b2b",
    quaternary: "#ffffff",
    background: "#f7f1eb",

    success: "",
    error: "#f82e47",

    black: "#2b2b2b",

    white_1: "#ffffff",
    white_2: "rgba(255, 255, 255, 0.60)",

    beige_1: "#f2e8dd",
    beige_2: "#f7f1eb",
    beige_3: "#fcfaf8",
    beige_4: "#fdfcfa",
    beige_1_pale: "rgba(#f2e8dd, 0.1)",

    green_1: "#00f0af",
    green_2: "#5affcc",
    green_3: "#a8ffde",
    green_4: "#e6fff7",
    green_1_pale: "rgba(#00f0af, 0.1)",

    grey_1: "#2b2b2b",
    grey_2: "#5c5c5c",
    grey_3: "#858585",
    grey_4: "#bbbbbb",
    grey_5: "#eeeeee",
    grey_1_pale: "rgba(#2b2b2b, 0.1)",

    purple_1: "#5d0b71",
    purple_2: "#89178f",
    purple_3: "#b861d8",
    purple_4: "#efcaff",
    purple_5: "#fcf4ff",
    purple_6: "#f3eef9",
    purple_1_pale: "rgba(#5d0b71, 0.1)",

    yellow_1: "#ced000",
    yellow_2: "#f0e74f",
    yellow_3: "#f9f299",
    yellow_4: "#fbfadf",
    yellow_1_pale: "rgba(#ced000, 0.1)",
    yellow_star: "#D0BB00",

    red_1: "#ff4e4e",
    red_2: "#ff5e5e",
    red_3: "#ff7070",
    red_1_pale: "rgba(#ff4e4e, 0.1)",

    orange_1: "#FF7A00",
    orange_4: "#FFEEDE",

    blue_1: "#0085FF",
    blue_4: "#DEF7FF",

    transparent: "transparent",
  },
};

export default theme;

