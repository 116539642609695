import HdLogo from "@hellodarwin/icons/dist/icons/HdLogo";
import Image from "antd/es/image";
import {
  Portfolio,
  PortfolioAssetsType,
} from "../../../../../features/entities/core-entities";
import getShortId from "../../../../../features/helpers/get-short-id";
import { useTranslation } from "../../../../../plugins/i18n";
import { useTheme } from "../../../../../plugins/styled";
import VideoPlayer from "../../../../common/VideoPlayer";
import Typography from "../../../../common/typography";
import {
  PortfolioCardContainer,
  PortfolioCover,
  PortfolioLogoContainer,
} from "./styles";

type PortfolioCardProps = {
  portfolio: Portfolio;
  onClick: (portfolio?: Portfolio) => void;
};

const PortfolioCard = ({ portfolio, onClick }: PortfolioCardProps) => {
  const { t: translateTag } = useTranslation(["tags"], { useSuspense: false });
  const theme = useTheme();

  const thumbnailIndex = portfolio?.assets?.findIndex(
    (a) => a.asset_type === PortfolioAssetsType.Image
  );
  const thumbnail = portfolio?.assets?.[thumbnailIndex];

  const handleClick = () => {
    onClick(portfolio);
  };

  const renderPortfolioContent = () => {
    if (!portfolio?.assets?.length || !!thumbnail) {
      return (
        <Image
          src={thumbnail?.asset_url}
          alt={
            !!thumbnail
              ? `${translateTag(`tags|${portfolio.service}`)} - ${
                  portfolio.portfolio_title
                }`
              : ""
          }
          fallback="https://images.ctfassets.net/uqnk3k6tiu47/yNnmq3QdK9QayVnVQ401X/a18c2a2766d8063e91fbd62640cdcd87/logo-compact-large.jpg"
          style={{ overflow: "hidden" }}
          placeholder={<HdLogo backgroundType="dark" type="tertiary" />}
          preview={false}
          loading="lazy"
        />
      );
    } else if (!!portfolio?.assets?.[0] && typeof window !== "undefined") {
      return (
        <VideoPlayer
          url={portfolio?.assets?.[0].asset_url}
          controls
          light={true}
        />
      );
    } else {
      return;
    }
  };

  return (
    <PortfolioCardContainer hoverable onClick={handleClick}>
      <PortfolioLogoContainer>
        {renderPortfolioContent()}
      </PortfolioLogoContainer>
      <PortfolioCover flex="column" align="center" justify="center">
        <Typography
          bold
          elementTheme="body1"
          color={theme.colors.white_1}
          className="portfolio-infos"
        >
          {portfolio.portfolio_title ||
            translateTag(`tags|${portfolio.service}`) ||
            `Portfolio ${getShortId(portfolio.provider_portfolio_id)}`}
        </Typography>
      </PortfolioCover>
    </PortfolioCardContainer>
  );
};

export default PortfolioCard;

