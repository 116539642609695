import { ReactNode } from "react";
import Modal, { ModalProps } from "..";
import Div from "../../div";
import Typography from "../../typography";

// *** MODAL HEADER ***
interface HeaderProps {
  title: string;
  action?: ReactNode;
}

const Header = ({ title, action }: HeaderProps) => {
  return (
    <Div flex="row" justify="space-between">
      <Typography elementTheme="h6" bold letterSpacing={-0.2}>
        {title}
      </Typography>
      {action}
    </Div>
  );
};

// *** MODAL FOOTER ***

export interface FooterProps {
  actions: ReactNode;
}

const Footer = ({ actions }: FooterProps) => {
  return (
    <Div
      flex="row"
      align="center"
      gap={10}
      justify="flex-end"
      tablet={{ flex: "column" }}
    >
      {actions}
    </Div>
  );
};

// *** MODAL CONTENT ***

interface ModalWithHeaderFooterProps extends ModalProps {}
const ModalWithHeaderFooter = ({
  ...modalProps
}: ModalWithHeaderFooterProps) => {
  return <Modal {...modalProps} />;
};
ModalWithHeaderFooter.Header = Header;
ModalWithHeaderFooter.Footer = Footer;

export default ModalWithHeaderFooter;

