import { PropsWithChildren, useEffect, useRef } from "react";
import { ContainerFullWidthHeaderStyling } from "./styles";

const ContainerFullWidthHeader = ({ children }: PropsWithChildren) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
      { threshold: [1] }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return (
    <ContainerFullWidthHeaderStyling ref={ref}>
      {children}
    </ContainerFullWidthHeaderStyling>
  );
};

export default ContainerFullWidthHeader;

