import Language from "../../../../features/enums/language";
import { useTranslations } from "../../../../features/providers/translations-provider";
import ContactModal from "../../../modals/contact-modal";

type ContactPreviewProps = {
  isVisible: boolean;
  onCancel: () => void;
  logo: string;
  name: string;
  title: string;
  email: string;
  phone: string;
  ext: string;
  preferred_language?: string | Language;
};

const ContactPreview = ({
  isVisible,
  onCancel,
  logo,
  name,
  title,
  email,
  phone,
  ext,
  preferred_language,
}: ContactPreviewProps) => {
  const { t, getFullLanguage } = useTranslations();

  if (!name?.length) return <></>;
  return (
    <ContactModal
      open={isVisible}
      handleClose={onCancel}
      name={name}
      picture={logo}
      description={title}
      infos={[
        {
          label: t(`profile_contact|email`),
          value: email,
          type: "email",
        },
        {
          label: t(`profile_contact|phone`),
          value: `${phone} ${ext}`,
          type: "phone",
        },
        {
          label: t(`profile_contact|preferredLanguage`),
          value: getFullLanguage(preferred_language),
        },
      ]}
    />
  );
};

export default ContactPreview;

