import { ProjectMenuProps } from "../project-menu";
import { ProjectMenuMobileTabs } from "./styles";

const ProjectMenuMobile = ({
  tabs,
  activeTab,
  handleTabClick,
}: ProjectMenuProps) => {
  if (!tabs.length) return <></>;

  return (
    <ProjectMenuMobileTabs
      defaultActiveKey="1"
      items={tabs}
      type="card"
      activeKey={activeTab}
      onTabClick={handleTabClick}
    />
  );
};

export default ProjectMenuMobile;
