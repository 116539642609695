import {
  Portfolio,
  PortfolioAsset,
} from "../entities/core-entities";

export default class PortfolioAdapter {
  getPortfolio = (
    portfolios: { [key: string]: Portfolio },
    portfolio_id: string
  ): Portfolio => {
    if (portfolios) {
      return portfolios[portfolio_id];
    }

    return {} as Portfolio;
  };

  selectAll = (portfolios: { [key: string]: Portfolio }): Portfolio[] => {
    if (portfolios) {
      const portfoliosList: Portfolio[] = Object.values(portfolios);

      portfoliosList.sort((portfolioA, portfolioB) =>
        new Date(portfolioA.created_at!).getTime() <
          new Date(portfolioB.created_at!).getTime()
          ? 1
          : -1
      );
      return portfoliosList;
    }

    return [] as Portfolio[];
  };

  setAll = (
    mappedPortfolios: { [key: string]: Portfolio },
    portfolios: Portfolio[]
  ): { [key: string]: Portfolio } => {
    mappedPortfolios = this.getInitialState();

    for (let portfolio of portfolios) {
      mappedPortfolios[portfolio.provider_portfolio_id] = portfolio;
    }
    return mappedPortfolios;
  };

  removeOne = (
    portfolios: { [key: string]: Portfolio },
    id: string
  ): { [key: string]: Portfolio } => {
    if (portfolios) {
      delete portfolios[id];
    }
    return portfolios;
  };

  insertOne = (
    portfolios: { [key: string]: Portfolio },
    portfolio: Portfolio
  ): { [key: string]: Portfolio } => {
    if (portfolios) {
      portfolios[portfolio.provider_portfolio_id] = portfolio;
    }
    return portfolios;
  };

  insertOneAtStart = (
    portfolios: { [key: string]: Portfolio },
    portfolio: Portfolio
  ): { [key: string]: Portfolio } => {
    if (portfolios) {
      portfolios[portfolio.provider_portfolio_id] = portfolio;
    }
    return portfolios;
  };

  updateOne = (
    portfolios: { [key: string]: Portfolio },
    { id, changes }: { id: string; changes: Portfolio }
  ): { [key: string]: Portfolio } => {
    if (portfolios) {
      portfolios[id] = changes;
    }
    return portfolios;
  };

  updateOnePortfolioAssets = (
    portfolios: { [key: string]: Portfolio },
    {
      portfolio_id,
      changes,
    }: { portfolio_id: string; changes: PortfolioAsset[] }
  ): { [key: string]: Portfolio } => {
    if (portfolios) {
      const portfolio = portfolios[portfolio_id];
      portfolios[portfolio_id] = { ...portfolio, assets: changes };
    }
    return portfolios;
  };

  insertOnePortfolioAsset = (
    portfolios: { [key: string]: Portfolio },
    { portfolio_id, asset }: { portfolio_id: string; asset: PortfolioAsset }
  ): { [key: string]: Portfolio } => {
    if (portfolios) {
      const portfolio = portfolios[portfolio_id];
      const assets = [...portfolio.assets, asset];
      portfolios[portfolio_id] = {
        ...portfolio,
        assets,
      };
    }
    return portfolios;
  };

  removeOnePortfolioAsset = (
    portfolios: { [key: string]: Portfolio },
    { id, changes }: { id: string; changes: PortfolioAsset }
  ): { [key: string]: Portfolio } => {
    if (portfolios) {
      const assetIndex = portfolios[id].assets.findIndex(
        (asset) =>
          asset.provider_portfolio_asset_id ===
          changes.provider_portfolio_asset_id
      );

      portfolios[id].assets.splice(assetIndex, 1);
    }
    return portfolios;
  };

  getInitialState = () => {
    return {} as { [key: string]: Portfolio };
  };
}

export const createPortfolioAdapter = () => {
  return new PortfolioAdapter();
};
