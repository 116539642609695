export { default as addLineBreak } from "./add-line-break";
export { default as copyToClipboard } from "./copy-to-clipboard";
export { default as dollarFormat } from "./dollar-format";
export { default as filterProjects } from "./filter-projects";
export { default as generateEmail } from "./generate-email";
export { default as getAverageAllReviews } from "./get-average-reviews";
export {
  default as getFormattedDate,
  getShortFormattedDate,
  getStringDate,
} from "./get-formatted-date";
export { default as getMeetingUrl } from "./get-meeting-url";
export { default as getPaginationTotal } from "./get-pagination-total";
export { default as getPriceInDollars } from "./get-prices-in-dollar";
export { default as getReviewURL } from "./get-review-url";
export { default as getShortId } from "./get-short-id";
export {
  getMilestoneStatusColor,
  getProgramStatusColor,
} from "./get-status-color";
export {
  default as getWebsiteURL,
  handleExternalLinkCLick,
  isDev,
} from "./get-website-url";
export { default as prefixHTTPS } from "./prefix-https";
export { default as selectInputEmail } from "./select-input-email";
export { default as validateURL } from "./validate-url";

