import { ReactNode } from "react";

export enum CancelProjectFlowSteps {
  cancelFormStep = "Cancel Form step",
  cancelConfirmation = "Cancel Confirmation step",
}

export enum NoPartnerSelectedFlowSteps {
  optionsStep = "Options step",
  bookConsultStep = "Book Consultation step",
  needMoreTimeStep = "Need More Time step",
}

export enum ChoosePartnerFlowSteps {
  chosenSpStep = "Chosen SP",
  progressStep = "Progress of Project",
  noReviewStep = "No review",
  QuickReviewStep = "Quick review",
}

export enum PossibleCancelAnswers {
  "Budget" = "budget",
  "Rescoping" = "rescoping",
  "PartnerSelection" = "partnerSelection",
  "OutsideHD" = "outside",
  "Other" = "other",
}

export enum ProjectProgress {
  NotStarted = 0,
  InProgress = 50,
  Completed = 100,
}

export enum ReviewFormSteps {
  Experience = "Experience",
  Contact = "Contact",
  Confirm = "Confirm",
  End = "End",
}

export interface ReviewStepsData {
  [name: string]: {
    children: ReactNode;
    type?: "boolean" | "checkbox" | "radio" | "rating" | "textarea";
    handleNext: () => void;
    handlePrev?: () => void;
    handleSubmit?: () => Promise<void>;
    isSubmit?: boolean;
    hide?: boolean;
    index: number;
    required?: boolean;
  };
}
