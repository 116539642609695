import { IconProps } from "../features/entities/general";

const Hello = ({
  width = 82,
  height = 28,
  style,
  className,
  onClick,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      className={className}
      onClick={onClick}
      viewBox="0 0 82 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.1939 25.8286C48.5642 26.0713 47.8969 26.1951 47.2097 26.1951C46.1502 26.1951 45.3281 25.8509 44.7658 25.1673C44.2211 24.511 43.9562 23.4683 43.9562 21.9798V3.41724H39.5056V4.52926H42.4368V21.9823C42.4368 23.0473 42.5418 23.9463 42.7492 24.6497C42.9466 25.3233 43.2365 25.8632 43.6063 26.2595C43.9787 26.6558 44.4335 26.9381 44.9932 27.1189C45.5805 27.3096 46.2652 27.4062 47.0273 27.4062C47.3647 27.4062 47.7445 27.3666 48.1568 27.2849C48.5717 27.2031 48.994 27.0818 49.4063 26.9282C49.8236 26.7747 50.2334 26.5815 50.6283 26.3561C50.8756 26.2149 51.113 26.0589 51.3354 25.893L50.7332 24.9791C50.1859 25.358 49.6712 25.6428 49.1964 25.8261L49.1939 25.8286Z"
        fill="#89178F"
        stroke="#89178F"
        strokeWidth="0.5"
      />
      <path
        d="M60.0291 25.8286C59.3994 26.0713 58.7322 26.1951 58.045 26.1951C56.9854 26.1951 56.1633 25.8509 55.601 25.1673C55.0563 24.511 54.7914 23.4683 54.7914 21.9798V3.41724H50.3408V4.52926H53.272V21.9823C53.272 23.0473 53.377 23.9463 53.5844 24.6497C53.7818 25.3233 54.0717 25.8632 54.4415 26.2595C54.8139 26.6558 55.2687 26.9381 55.8284 27.1189C56.4157 27.3096 57.1004 27.4062 57.8625 27.4062C58.1999 27.4062 58.5797 27.3666 58.992 27.2849C59.4069 27.2031 59.8292 27.0818 60.2415 26.9282C60.6588 26.7747 61.0686 26.5815 61.4635 26.3561C61.7109 26.2149 61.9482 26.0589 62.1706 25.893L61.5684 24.9791C61.0212 25.358 60.5064 25.6428 60.0316 25.8261L60.0291 25.8286Z"
        fill="#89178F"
        stroke="#89178F"
        strokeWidth="0.5"
      />
      <path
        d="M1 1H2.53183V12.9821H2.5993C4.06366 9.94571 6.46261 8.72472 9.19392 8.72472C12.39 8.72472 15.9884 10.3742 15.9884 16.0532V27.4087H14.4566V16.7144C14.4566 11.5333 11.5929 10.0795 8.96152 10.0795C4.69838 10.0795 2.53433 13.9406 2.53433 17.0438V27.4087H1V1Z"
        fill="#89178F"
        stroke="#89178F"
        strokeWidth="0.5"
      />
      <path
        d="M20.3091 17.8017C20.3091 12.1251 24.04 8.16248 29.2352 8.16248C32.3663 8.16248 37.4616 9.61628 37.4616 17.076V17.9676H21.9759C21.9759 22.8194 25.007 26.0539 29.3027 26.0539C32.9336 26.0539 35.2975 23.6788 36.397 20.9049L37.7615 21.3334C36.3296 25.2936 33.0335 27.4062 29.2027 27.4062C24.0724 27.4062 20.3116 23.5128 20.3116 17.8017H20.3091ZM35.7623 16.4494C35.7623 11.2682 32.1989 9.51721 29.1677 9.51721C25.3044 9.51721 22.5731 12.3555 22.0408 16.4494H35.7623Z"
        fill="#89178F"
        stroke="#89178F"
        strokeWidth="0.5"
      />
      <path
        d="M62.8503 17.8017C62.8503 12.1251 66.5812 8.16248 71.9414 8.16248C77.3015 8.16248 80.9999 12.1227 80.9999 17.8017C80.9999 23.4806 77.269 27.4062 71.9414 27.4062C66.6137 27.4062 62.8503 23.446 62.8503 17.8017ZM71.9414 26.0539C76.3369 26.0539 79.3356 22.6534 79.3356 17.8017C79.3356 12.9499 76.3394 9.51721 71.9414 9.51721C67.5433 9.51721 64.5146 12.9499 64.5146 17.8017C64.5146 22.6534 67.5458 26.0539 71.9414 26.0539Z"
        fill="#89178F"
        stroke="#89178F"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default Hello;

