import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Project } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import Confirm from "@hellodarwin/icons/dist/icons/Confirm";
import Grants from "@hellodarwin/icons/dist/icons/Grants";
import ProjectsList from "@hellodarwin/icons/dist/icons/ProjectsList";
import WaitingMoney from "@hellodarwin/icons/dist/icons/WaitingMoney";
import { ReactNode } from "react";
import { ProjectSummaryContainer, ProjectSummaryScroll } from "./styles";

type ProjectSummaryProps = {
  projects: Project[];
};

const ProjectSummary = ({ projects }: ProjectSummaryProps) => {
  const { t } = useTranslation();

  const projectCompletedTotal = () => {
    var completedTotalProject = 0;
    projects &&
      projects.forEach((project) => {
        if (project.status === "Completed") {
          completedTotalProject++;
        }
      });
    return completedTotalProject;
  };

  const projectTotalEarned = () => {
    var totalEarnedMoney = 0;
    projects &&
      projects.forEach((project) => {
        if (project.status === "Completed") {
          totalEarnedMoney += Number(project.lead_price);
        }
      });
    return totalEarnedMoney;
  };

  const projectPendingEarned = () => {
    var totalPendingMoney = 0;
    projects &&
      projects.forEach((project) => {
        if (project.status === "Active") {
          totalPendingMoney += Number(project.lead_price);
        }
      });
    return totalPendingMoney;
  };

  return (
    <ProjectSummaryScroll>
      <ProjectSummaryContainer>
        <SummaryBox
          Icon={ProjectsList}
          value={`${projects.length}`}
          title={t("referral|referralSummary.totalProjects")}
        />
        <SummaryBox
          Icon={Confirm}
          value={`${projectCompletedTotal()}`}
          title={t("referral|referralSummary.completedProjects")}
        />
        <SummaryBox
          Icon={Grants}
          value={`${projectTotalEarned()} $`}
          title={t("referral|referralSummary.totalEarned")}
        />
        <SummaryBox
          Icon={WaitingMoney}
          value={`${projectPendingEarned()} $`}
          title={t("referral|myReferral.pendingRevenue")}
        />
      </ProjectSummaryContainer>
    </ProjectSummaryScroll>
  );
};

const SummaryBox = ({
  Icon,
  value,
  title,
}: {
  Icon: (props: IconProps) => ReactNode;
  value: string;
  title: string;
}) => (
  <Div
    style={{ padding: 16, width: 250, height: 150, flexShrink: 0 }}
    justify="space-between"
    flex={"column"}
    gap={8}
    borderColor={theme.colors.primary}
    backgroundColor={theme.colors.white_1}
  >
    <Div flex={"column"} gap={16} align="center">
      <Icon width={32} height={32} style={{ color: theme.colors.primary }} />
      <Typography textAlign="center">{title}</Typography>
    </Div>

    <Typography elementTheme="h6" textAlign="center">
      {value}
    </Typography>
  </Div>
);

export default ProjectSummary;

