enum ProjectStatus {
  PendingApproval = "PendingApproval",
  Active = "Active",
  FilterCandidates = "FilterCandidates",
  EvaluateFinalists = "EvaluateFinalists",
  Completed = "Completed",
  Canceled = "Canceled",
  Rejected = "Rejected",
}

export default ProjectStatus;
