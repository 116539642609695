import styled from "../../../../../plugins/styled";
import Div from "../../../../common/div";

export const ProjectSidebarSection = styled(Div)`
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) { {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .project-sidebar-field:last-child:nth-last-child(odd) { 
      grid-column: auto / span 2;
    }
    .project-sidebar-field:first-child:nth-last-child(even),
    .project-sidebar-field:first-child:nth-last-child(even)~ .project-sidebar-field
    {
      grid-column: auto / span 1; 
    }
  }
`;
