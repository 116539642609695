import {
  MappedAssetsByPortfolio,
  MappedPortfolioAssets,
  PortfolioAsset,
} from "../entities/core-entities";

export default class PortfolioAssetsAdapter {
  getSingleAsset = (
    allAssets: { [key: string]: MappedPortfolioAssets },
    portfolio_id: string,
    asset_id: string
  ): PortfolioAsset => {
    if (allAssets) {
      return allAssets[portfolio_id][asset_id];
    }

    return {} as PortfolioAsset;
  };

  addSingleAsset = (
    allAssets: { [key: string]: MappedPortfolioAssets },
    portfolioAsset: PortfolioAsset
  ): { [key: string]: MappedPortfolioAssets } => {
    if (allAssets) {
      allAssets[portfolioAsset.provider_portfolio_id][
        portfolioAsset.provider_portfolio_asset_id
      ] = portfolioAsset;
    }

    return allAssets;
  };

  updateSingleAsset = (
    allAssets: { [key: string]: MappedPortfolioAssets },
    portfolioAsset: PortfolioAsset
  ): { [key: string]: MappedPortfolioAssets } => {
    if (portfolioAsset) {
      allAssets[portfolioAsset.provider_portfolio_id][
        portfolioAsset.provider_portfolio_asset_id
      ] = portfolioAsset;
    }
    return allAssets;
  };

  removeSingleAsset = (
    allAssets: { [key: string]: MappedPortfolioAssets },
    portfolioAsset: PortfolioAsset
  ): { [key: string]: MappedPortfolioAssets } => {
    if (allAssets) {
      delete allAssets[portfolioAsset.provider_portfolio_id][
        portfolioAsset.provider_portfolio_asset_id
      ];
    }

    return allAssets;
  };

  getPortfolioAssets = (
    allAssets: { [key: string]: MappedPortfolioAssets },
    portfolio_id: string
  ): MappedPortfolioAssets => {
    if (allAssets) {
      return allAssets[portfolio_id];
    }

    return {} as MappedPortfolioAssets;
  };

  selectAllPortfolioAssets = (
    portfolioAssets: MappedPortfolioAssets
  ): PortfolioAsset[] => {
    if (portfolioAssets) {
      const assets: PortfolioAsset[] = Object.values(portfolioAssets);
      return assets;
    }
    return [];
  };

  selectAll = (allAssets: {
    [key: string]: MappedPortfolioAssets;
  }): {
    [key: string]: PortfolioAsset[];
  } => {
    if (allAssets) {
      const mappedAssets: {
        [key: string]: PortfolioAsset[];
      } = {};

      Object.keys(allAssets).forEach((key) => {
        mappedAssets[key] = this.selectAllPortfolioAssets(allAssets[key]);
      });

      return mappedAssets;
    }

    return {};
  };

  setAll = (
    mappedPortfoliosAssets: { [key: string]: MappedPortfolioAssets },
    allAssets: MappedAssetsByPortfolio
  ): { [key: string]: MappedPortfolioAssets } => {
    mappedPortfoliosAssets = this.getInitialState();

    Object.keys(allAssets).forEach((key) => {
      const assets = allAssets[key];
      let mappedAssets: MappedPortfolioAssets = {};
      assets.forEach((a) => {
        mappedAssets[a.provider_portfolio_asset_id] = a;
      });
      mappedPortfoliosAssets[key] = mappedAssets;
    });

    return mappedPortfoliosAssets;
  };

  removeOne = (
    allAssets: { [key: string]: MappedPortfolioAssets },
    id: string
  ): { [key: string]: MappedPortfolioAssets } => {
    if (allAssets) {
      delete allAssets[id];
    }
    return allAssets;
  };

  insertOne = (
    allAssets: { [key: string]: MappedPortfolioAssets },
    portfolioAssets: MappedPortfolioAssets,
    portfolio_id: string
  ): { [key: string]: MappedPortfolioAssets } => {
    if (allAssets) {
      allAssets[portfolio_id] = portfolioAssets;
    }
    return allAssets;
  };

  updateOne = (
    allAssets: { [key: string]: MappedPortfolioAssets },
    { id, changes }: { id: string; changes: MappedPortfolioAssets }
  ): { [key: string]: MappedPortfolioAssets } => {
    if (allAssets) {
      allAssets[id] = changes;
    }
    return allAssets;
  };

  getInitialState = () => {
    return {} as { [key: string]: MappedPortfolioAssets };
  };
}

export const createPortfolioAssetsAdapter = () => {
  return new PortfolioAssetsAdapter();
};
