import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import FormLabel from "@hellodarwin/core/lib/components/forms/form-layouts/form-label";
import validatePhoneNumber from "@hellodarwin/core/lib/components/forms/utils/validate-phone-number";
import { PartnerProjectRequest } from "@hellodarwin/core/lib/features/entities/project-entities";
import SingleLanguage from "@hellodarwin/core/lib/features/enums/single-language";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import { usePartnerAnalytics } from "../../features/analytics/use-partner-analytics";
import { selectLoading } from "../../features/api/slices/global-slice";
import {
  fetchTags,
  selectTags,
  submitReferredProject,
} from "../../features/api/slices/profile-slice";
import { usePartnerApi } from "../../features/api/use-partner-api";
import ProjectReferralModal from "./project-referral-modal";

export type ProjectReferralFormValues = {
  original_description: string;
  client_name: string;
  client_email: string;
  client_phone: string;
  project_category: string;
  project_budget: string;
  preferredLanguage: string;
};

const ProjectReferralForm = () => {
  const analytics = usePartnerAnalytics();
  const { t } = useTranslation();
  const loading = useAppSelector(selectLoading);
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector(selectTags);

  const [form] = Form.useForm<ProjectReferralFormValues>();
  const [estimatedPayout, setEstimatedPayout] = useState(0);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchTags({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      if (values.project_budget === undefined) {
        values.project_budget = "0-0";
      }

      const [min_budget, max_budget] = values.project_budget.split("-");

      const project: PartnerProjectRequest = {
        full_name: values.client_name,
        email: values.client_email,
        phone: values.client_phone,
        description: values.original_description,
        budget_min: parseInt(min_budget),
        budget_max: parseInt(max_budget),
        preferredLanguage: values.preferredLanguage,
      };

      await dispatch(submitReferredProject({ api, project }));
      setIsSuccessModalVisible(true);
      form.resetFields();
      setEstimatedPayout(0);

      analytics.projectReferralSubmitted();
    });
  };
  const languageOptions: {
    value: string;
    label: string | ReactNode;
  }[] = [
    {
      value: SingleLanguage.French,
      label: t(`language.${SingleLanguage.French}`),
    },
    {
      value: SingleLanguage.English,
      label: t(`language.${SingleLanguage.English}`),
    },
  ];
  const handleBudgetChange = (newBudget: string) => {
    const [min_budget, max_budget] = newBudget.split("-");

    if (
      parseInt(min_budget) === 0 ||
      parseInt(min_budget) === 1000 ||
      parseInt(min_budget) === 2500
    ) {
      setEstimatedPayout(
        ((parseInt(min_budget) + parseInt(max_budget)) / 2) * 0.02 * 1.5
      );
    } else {
      setEstimatedPayout(
        ((parseInt(min_budget) + parseInt(max_budget)) / 2) * 0.02
      );
    }
  };

  return (
    <Div flex="column" align="center" gap={32}>
      <ProjectReferralModal
        isVisible={isSuccessModalVisible}
        handleCancel={() => setIsSuccessModalVisible(false)}
      />
      <Form
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        style={{ width: "100%" }}
      >
        <Div flex="row" gap={54} tablet={{ flex: "column", gap: 32 }}>
          <Div flex="column" gap={16}>
            <Typography.Title level={2} elementTheme="h6">
              {t("project|projectPage.clientDetails")}
            </Typography.Title>
            <FormItem
              name="client_name"
              label={<FormLabel label={t("referral|form.labels.name")} />}
              rules={[
                {
                  required: true,
                  message: "Please provide the client name",
                },
              ]}
            >
              <Input
                size={"large"}
                placeholder={t("referral|form.placeholders.name")}
              />
            </FormItem>

            <FormItem
              name="client_email"
              label={<FormLabel label={t("referral|form.labels.email")} />}
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please provide the client email",
                },
              ]}
            >
              <Input
                size={"large"}
                placeholder={t("referral|form.placeholders.email")}
              />
            </FormItem>
            <FormItem
              name="client_phone"
              label={<FormLabel label={t("referral|form.labels.phone")} />}
              rules={[
                {
                  validator: validatePhoneNumber(
                    t("profile|profileForm.contact.validation.phone")
                  ),
                },
              ]}
            >
              <Input
                size={"large"}
                placeholder={t("referral|form.placeholders.phone")}
              />
            </FormItem>
            <FormItem
              style={{ flex: 2 }}
              name="preferredLanguage"
              label={
                <FormLabel
                  label={t(
                    "profile|profileForm.users.form.labels.preferredLanguage"
                  )}
                />
              }
              rules={[
                {
                  required: true,
                  message: t(
                    "profile|profileForm.contact.validation.preferredLanguage"
                  ),
                },
              ]}
            >
              <Select
                options={languageOptions}
                size="large"
                placeholder={t(
                  "profile|profileForm.contact.labels.preferredLanguage"
                )}
              />
            </FormItem>
          </Div>
          <Div flex="column" gap={16}>
            <Typography.Title level={2} elementTheme="h6">
              {t("project|projectPage.projectDetails")}
            </Typography.Title>

            <FormItem
              name="project_category"
              label={t("referral|form.labels.category")}
              rules={[
                {
                  required: true,
                  message: "Please provide the project category",
                },
              ]}
            >
              <Select
                placeholder={t("referral|form.placeholders.category")}
                size={"large"}
              >
                {categories.map((category, index) => (
                  <Select.Option key={index} value={category.label}>
                    {t(`tags|${category.label}`)}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              name="project_budget"
              label={t("referral|form.labels.budget")}
              style={{ width: "100%" }}
            >
              <Select
                placeholder={t("referral|form.placeholders.budget")}
                onChange={handleBudgetChange}
                size={"large"}
              >
                <Select.Option value="0-0">
                  {t("referral|form.iDontKnow")}
                </Select.Option>
                <Select.Option value="0-1000">$0 - $1,000</Select.Option>
                <Select.Option value="1000-2500">$1,000 - $2,500</Select.Option>
                <Select.Option value="2500-5000">$2,500 - $5,000</Select.Option>
                <Select.Option value="5000-10000">
                  $5,000 - $10,000
                </Select.Option>
                <Select.Option value="10000-25000">
                  $10,000 - $25,000
                </Select.Option>
                <Select.Option value="25000-50000">
                  $25,000 - $50,000
                </Select.Option>
                <Select.Option value="50000-100000">
                  $50,000 - $100,000
                </Select.Option>
                <Select.Option value="100000-500000">
                  $100,000 - $500,000
                </Select.Option>
                <Select.Option value="500000-500000">$500,000 +</Select.Option>
              </Select>
            </FormItem>
            <FormItem
              name="original_description"
              label={t("portfolio|form.placeholder.description")}
              style={{ width: "100%" }}
            >
              <Input
                size={"large"}
                placeholder={t("referral|myReferral.descriptionPlaceHolder")}
                disabled={loading}
              />
            </FormItem>
            {estimatedPayout !== 0 && (
              <Typography
                elementTheme="body2"
                style={{ marginBottom: "1rem" }}
                textAlign={"center"}
              >
                {t("referral|form.estimatedPayout")}:{" "}
                <strong>
                  {t("referral|form.price", {
                    price: Math.trunc(estimatedPayout),
                  })}
                </strong>
              </Typography>
            )}
          </Div>
        </Div>
      </Form>
      <Typography elementTheme="caption" textAlign={"center"}>
        {t("referral|form.disclaimer")}
      </Typography>

      <Button
        defaultStyle={theme.colors.purple_1}
        htmlType="submit"
        loading={loading}
        onClick={onFinish}
      >
        {t("button.confirm")}
      </Button>
    </Div>
  );
};

export default ProjectReferralForm;

