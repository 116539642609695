import Div from "@hellodarwin/core/lib/components/common/div";
import ModalWarning from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-warning";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import EmailEnum from "@hellodarwin/core/lib/features/enums/email";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import { useEffect } from "react";
import { useAppDispatch } from "../../../app/index";
import { fetchAvailableMatches } from "../../../features/api/slices/matches-slice";
import { usePartnerApi } from "../../../features/api/use-partner-api";

type ErrorModalProps = {
  open: boolean;
  error: number;
  onCancel: () => void;
};

const ErrorModal = ({ open, error, onCancel }: ErrorModalProps) => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchAvailableMatches(api));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ModalWarning open={open} size="small" handleCancel={onCancel}>
      <Div flex="column" align="center" gap={8} justify="center">
        <Typography.Title level={2} elementTheme="h4" textAlign={"center"}>
          {t(`errors|${error}.message`)}
        </Typography.Title>
        <Div flex="column" align="center">
          <Typography
            elementTheme="body1"
            center={true}
            color={theme.colors.grey_2}
          >
            {t(`errors|${error}.description`)}
          </Typography>

          <a href={`mailto:${EmailEnum.Support}`}>{EmailEnum.Support}</a>
        </Div>
      </Div>
    </ModalWarning>
  );
};

export default ErrorModal;

