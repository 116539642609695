import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProjectMenu from "@hellodarwin/core/lib/components/project/single/project-header/project-menu";
import ProjectMenuMobile from "@hellodarwin/core/lib/components/project/single/project-header/project-menu-mobile";
import {
  Match,
  ProjectRowInformation,
} from "@hellodarwin/core/lib/features/entities";
import { dollarFormat } from "@hellodarwin/core/lib/features/helpers";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme/index";
import Empty from "antd/es/empty";
import { TabsProps } from "antd/es/tabs";
import { useNavigate } from "react-router-dom";
import ProjectBanner from ".";
import ProjectType from "@hellodarwin/core/lib/features/enums/project-type";

type ProjectHeaderProps = {
  match: Match;
  price: number;
  discount: number;
  available: boolean;
  activeTab: string;
  tabs: TabsProps["items"];
  handleTabClick: (tab: string) => void;
};

const ProjectHeader = ({
  match,
  price,
  discount,
  available,
  activeTab,
  tabs,
  handleTabClick,
}: ProjectHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;
  const { selectedLocale } = useLocale();

  const informations: ProjectRowInformation[] = [
    {
      title: t(`project|projectPage|budget`),
      value: `${dollarFormat(
        match.project.estimated_value || 0,
        selectedLocale
      )}$`,
      hide: !match.project.estimated_value,
    },
    {
      title: t(`project|projectPage|raiseHandPrice`),
      value:
        match.source === "Directory" ? (
          <Div fitContent flex="row" gap={10}>
            <Typography
              elementTheme="body2"
              bold
              strike
              color={theme.colors.red_1}
            >
              {`${dollarFormat(
                match?.project?.raise_hand_price || 0,
                selectedLocale
              )}$`}
            </Typography>
            <Typography elementTheme="body2" bold>{`${dollarFormat(
              match.source === "Directory"
                ? 0
                : match?.project?.raise_hand_price || 0,
              selectedLocale
            )}$`}</Typography>
          </Div>
        ) : (
          <Typography elementTheme="body2" bold>
            {`${dollarFormat(
              match.project.raise_hand_price || 0,
              selectedLocale
            )}$`}
          </Typography>
        ),
      hide: match.project.type !== ProjectType.RaiseHand,
    },
    {
      title: t(`project|projectPage|leadCost`),
      value:
        discount > 0 || match.source === "Directory" ? (
          <Div fitContent flex="row" gap={10}>
            <Typography
              elementTheme="body2"
              bold
              strike
              color={theme.colors.red_1}
            >
              {`${dollarFormat(price || 0, selectedLocale)}$`}
            </Typography>
            <Typography elementTheme="body2" bold>{`${dollarFormat(
              match.source === "Directory" ? 0 : price - discount,
              selectedLocale
            )}$`}</Typography>
          </Div>
        ) : (
          <Typography elementTheme="body2" bold>
            {`${dollarFormat(price || 0, selectedLocale)}$`}
          </Typography>
        ),
    },
  ];

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const navigate = useNavigate();
  if (!match.project) return <></>;

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Div>
      <ProjectBanner
        match={match}
        informationsLeft={informations}
        available={available}
      />
      {available ? (
        <>
          {isTablet ? (
            <ProjectMenuMobile
              tabs={tabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />
          ) : (
            <ProjectMenu
              tabs={tabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />
          )}
        </>
      ) : (
        <Empty
          description={
            <>
              <Typography
                elementTheme="subtitle2"
                textAlign="center"
                tablet={{ style: { padding: "0 10px" } }}
              >
                {t(`project|projectPage|maxRaiseHand.title`)}
              </Typography>
              <Typography
                color={theme.colors.grey_2}
                textAlign="center"
                tablet={{ style: { padding: "0 10px" } }}
              >
                {t(`project|projectPage|maxRaiseHand.subtitle`)}
              </Typography>
              <Button onClick={handleBack} transparent textWrap size="small">
                {t(`project|projectPage|maxRaiseHand.cta`)}
              </Button>
            </>
          }
        />
      )}
    </Div>
  );
};
export default ProjectHeader;

