import { Contact } from "./core-entities";
import { AdminGrantResult, GrantStatus } from "./grants-entities";

export interface Gin {
  gin_id: string;
  grant_id: string;
  admin_id: string;
  section: string;
  content?: string;
}

export interface GinSection {
  section: string;
  content?: string;
}

export interface AdminGinResult {
  grant: AdminGrantResult;
  ginSections: Gin[];
}

export interface GinContact {
  gin_contact_id: string;
  created_at?: Date;
  grant_id: string;
  admin_id?: string;
  crm_id?: string;
  grant_provider_id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  title?: string;
  phone_number?: string;
  extension?: string;
  language?: string;
  notes?: string;
}
export interface DocumentNeeded {
  document_id: string;
  name: string;
  type?: string;
  version?: string;
  date?: string;
  uploaded_by?: string;
}
export interface Step {
  step_id: string;
  title: string;
  assignment: string;
  medium: string;
  type: string;
  description?: string;
  conditions?: string;
  document_needed?: DocumentNeeded[];
  step_order: number;
}

export const InitialStepValue: Step = {
  step_id: "",
  title: "",
  assignment: "",
  medium: "",
  type: "",
  description: "",
  conditions: "",
  step_order: 0,
};

export const InitialDocumentValue: DocumentNeeded = {
  document_id: "",
  name: "",
  type: "",
  version: "",
  date: "",
  uploaded_by: "",
};

export interface StepFormFunction {
  updateSelectedStep: (step: Step) => Promise<Step>;
  createNewStep: (grantId: string, step: Step) => Promise<Step>;
  updateSelectedDocument: (document: DocumentNeeded) => Promise<DocumentNeeded>;
  createNewDocument: (document: DocumentNeeded) => Promise<DocumentNeeded>;
  deleteSelectedStep: (grant_id: string, step_id: string) => Promise<Step[]>;
  deleteSelectedDocument: (document_id: string) => Promise<string>;
  reorderSteps: (grant_id: string, steps: Step[]) => Promise<Step[]>;
}

export interface GrantProviderFormValues {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone_number: string;
  extension: string;
  language: string;
  notes: string;
  grant_id: string;
}

export interface AdminGinContactResponse extends GinContact {
  provider_title?: string;
}
export declare const GinInitialState: AdminGinResult;

export interface GinApplication {
  application_id: string;
  application_name: string;
  application_program_name: string;
  application_status: string;
  application_created_at: Date;
  application_company_id: string;
  application_company_name: string;
  application_contact_id: string;
  application_contact_name: string;
  application_account_manager: string;
  application_price: number;
  application_requested_at: Date;
  application_targeted_amount: number;
  application_submitted_at: Date;
  application_submitted_amount: number;
  application_result_at: Date;
  application_completed_at: Date;
  application_rejected_at: Date;
  application_accepted_amount: number;
  application_submission_result: string;
  application_refusal_reason: string;
  application_refusal_reason_specified: string;
  application_program_grant_id: string;
  application_program_status: GrantStatus;
  application_hubspot_url: string;
  application_notes: string;
  application_project_end: Date;
}

export const InitialStateGinApplication: GinApplication = {
  application_id: "",
  application_name: "",
  application_status: "",
  application_created_at: undefined,
  application_company_id: "",
  application_contact_id: "",
  application_contact_name: "",
  application_account_manager: "",
  application_price: 0,
  application_requested_at: undefined,
  application_targeted_amount: 0,
  application_submitted_at: undefined,
  application_submitted_amount: 0,
  application_result_at: undefined,
  application_accepted_amount: 0,
  application_submission_result: "",
  application_refusal_reason: "",
  application_refusal_reason_specified: "",
  application_program_grant_id: "",
  application_program_status: "closed",
  application_hubspot_url: "",
  application_program_name: "",
  application_company_name: "",
  application_completed_at: undefined,
  application_rejected_at: undefined,
  application_notes: "",
  application_project_end: undefined,
};

export interface GinShortApplication {
  application_id: string;
  application_name: string;
  application_status: string;
  application_company_id: string;
  application_contact_name: string;
  application_program_grant_id: string;
  application_program_name: string;
}

export const InitialStateGinShortApplication: GinShortApplication = {
  application_id: "",
  application_name: "",
  application_status: "",
  application_company_id: "",
  application_contact_name: "",
  application_program_grant_id: "",
  application_program_name: "",
};

export interface GinApplicationCompany {
  application_id: string;
  company_id: string;
  company_name: string;
  company_applications: GinShortApplication[];
}

export const InitialStateGinApplicationCompany: GinApplicationCompany = {
  application_id: "",
  company_id: "",
  company_name: "",
  company_applications: [],
};
export interface GinApplicationProgram {
  application_id: string;
  program_grant_id: string;
  program_name: string;
  program_applications: GinShortApplication[];
}

export const InitialStateGinApplicationProgram: GinApplicationProgram = {
  application_id: "",
  program_grant_id: "",
  program_name: "",
  program_applications: [],
};

export interface FullGinApplication {
  application: GinApplication;
  contacts: Contact[];
  company: GinApplicationCompany;
  program: GinApplicationProgram;
}

export const InitialStateFullGinApplication: FullGinApplication = {
  application: InitialStateGinApplication,
  contacts: [],
  company: InitialStateGinApplicationCompany,
  program: InitialStateGinApplicationProgram,
};

export declare const AdminGinInitialState: AdminGinContactResponse;

