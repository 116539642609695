import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";

const validatePhoneNumber =
  (message: string) => (_: RuleObject, value: StoreValue) => {
    if (value?.length === 0) return Promise.reject(new Error(message));
    // regex from https://www.regextester.com/103299
    const phoneRegex = new RegExp(
      /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g
    );

    return phoneRegex.test(value)
      ? Promise.resolve()
      : Promise.reject(new Error(message));
  };

export default validatePhoneNumber;

