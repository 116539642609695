import { CSSProperties, useMemo } from "react";
import { DivProps, ViewDivProps } from "..";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTheme } from "../../../../plugins/styled";
import { DivContainerProps } from "../styles";

const useDivProps = (props: DivProps) => {
  const theme = useTheme();
  const isXL = useMediaQuery({ minWidth: theme.breakpoints.xl });
  const isL = useMediaQuery({ maxWidth: theme.breakpoints.l });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  return useMemo(() => {
    const currentProps: DivContainerProps = {};
    const currentStyle: CSSProperties = {};

    const addProp = (property, value) => {
      if (value !== undefined) {
        currentProps[property] = value;
      }
    };

    const addStyling = (property, value) => {
      if (value !== undefined) {
        currentStyle[property] = value;
      }
    };

    const prepareStyling = (data: ViewDivProps) => {
      if (data) {
        const { gap, flex, style, justify, align, wrap, ...rest } = data;

        if (gap !== undefined) {
          addStyling("gap", `${gap}px`);
        }
        if (flex !== undefined) {
          addStyling("display", "flex");
          addStyling("flexDirection", flex);
        }
        if (justify !== undefined) {
          addStyling("justifyContent", justify);
        }
        if (align !== undefined) {
          addStyling("alignItems", align);
        }
        if (wrap !== undefined) {
          addStyling("flexWrap", wrap);
        }

        Object.entries(rest).forEach(([key, value]) => {
          addStyling(key, value);
        });

        if (style) {
          Object.entries(style).forEach(([key, value]) => {
            addStyling(key, value);
          });
        }
      }
    };

    const prepareProps = (data: ViewDivProps) => {
      if (data) {
        const {
          backgroundOpacity,
          backgroundOpacityHover,
          borderColor,
          borderColorHover,
          hidden,
          fitContent,
          fitMaxContent,
          fitMinContent,
          fullHeight,
          bordersSide,
          ...rest
        } = data;
        prepareStyling(rest);

        const propMappings = {
          $backgroundOpacity: backgroundOpacity,
          $backgroundOpacityHover: backgroundOpacityHover,
          $borderColor: borderColor,
          $borderColorHover: borderColorHover,
          $hidden: hidden,
          $fitContent: fitContent,
          $minContent: fitMinContent,
          $maxContent: fitMaxContent,
          $fullHeight: fullHeight,
          $borderSide: bordersSide,
        };

        Object.entries(propMappings).forEach(([key, value]) => {
          addProp(key, value);
        });
      }
    };

    const {
      xl,
      xxl,
      tablet,
      children,
      onClick,
      onDoubleClick,
      onMouseEnter,
      id,
      className,
      ...defaultViewProps
    } = props;

    const generalProps = {
      children,
      onClick,
      onDoubleClick,
      onMouseEnter,
      id,
      className,
    };

    prepareProps(defaultViewProps);

    if (isXL) prepareProps(props.xxl);
    if (isL) prepareProps(props.xl);
    if (isTablet) prepareProps(props.tablet);

    return { currentProps, currentStyle, generalProps };
  }, [isTablet, isXL, isL, props]);
};
export default useDivProps;

