import Budget from "@hellodarwin/icons/dist/icons/Budget";
import Industry from "@hellodarwin/icons/dist/icons/Industry";
import Service from "@hellodarwin/icons/dist/icons/Service";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import Divider from "antd/es/divider";
import React, { ReactNode } from "react";
import { Project } from "../../../features/entities";
import ProjectDescriptionType from "../../../features/enums/project-description-type";
import parse, {
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "../../../features/helpers/parse";
import { useTranslations } from "../../../features/providers/translations-provider";
import { useTheme } from "../../../plugins/styled";
import Collapse from "../../common/Collapse";
import { Tablet } from "../../common/Media";
import Div from "../../common/div";
import HdTag from "../../common/hd-tag";
import Typography from "../../common/typography";

const ProjectCard = ({
  description_container,
  service,
  category,
  specialties,
  industry,
  budget_min,
  budget_max,
  start_when,
  project_id,
  title,
}: Project) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (domNode.attribs) {
        const props = attributesToProps(domNode.attribs);
        if (domNode.name === "ul") {
          return (
            <Typography elementTheme="body2">
              <ul {...props}>{domToReact(domNode.children)}</ul>
            </Typography>
          );
        }
        if (domNode.name === "h4") {
          return (
            <Div flex="column" gap={6}>
              <Typography
                elementTheme="caption"
                color={theme.colors.grey_2}
                textTransform="uppercase"
                bold
              >
                {domToReact(domNode.children)}
              </Typography>
              <Divider style={{ margin: 0 }} />
            </Div>
          );
        }
      }
    },
  };

  const renderDescription = () => {
    if (
      !description_container ||
      !description_container[ProjectDescriptionType.Details]
    )
      return null;
    return (
      <Typography
        elementTheme="body3"
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "600px",
          gap: 16,
        }}
      >
        {parse(description_container[ProjectDescriptionType.Details], options)}
      </Typography>
    );
  };

  const InfoRow = ({
    Icon,
    label,
    value,
  }: {
    Icon: (props) => ReactNode;
    label: string;
    value: string;
  }) => (
    <Div flex="row" gap={10} align="center" justify="space-between">
      <Div flex="row" gap={10} align="center" fitContent>
        <Icon width={10} height={10} style={{ color: theme.colors.grey_2 }} />
        <Typography
          color={theme.colors.grey_2}
          elementTheme="overline"
          medium
          textTransform="uppercase"
          nowrap
        >
          {label}
        </Typography>
      </Div>

      <Typography elementTheme="caption" nowrap ellipsis>
        {value}
      </Typography>
    </Div>
  );
  return (
    <>
      <Collapse
        ExpandIcon={({ isActive }) => (
          <Div gap={16} flex="row" align="center" fitContent xl={{ gap: 0 }}>
            <Tablet direction="up">
              <Typography
                bold
                textTransform="uppercase"
                letterSpacing={0.36}
                nowrap
                elementTheme="overline"
              >
                {isActive
                  ? t("profile_expertise|seeLessDetails")
                  : t("profile_expertise|seeMoreDetails")}
              </Typography>
            </Tablet>
            <TabArrow down={isActive} width={8} height={4} />
          </Div>
        )}
        items={[
          {
            id: project_id,
            styles: {
              header: {
                backgroundColor: theme.colors.beige_2,
                padding: 24,
              },
              content: {
                padding: 24,
              },
            },
            title: (
              <>
                <Typography
                  bold
                  textTransform="capitalize"
                  style={{ height: 60, display: "flex", alignItems: "center" }}
                  xl={{ style: { height: "fit-content" } }}
                  textAlign="center"
                >
                  {title ? title : category}
                </Typography>
              </>
            ),
            headerIsColumnOnTablet: true,
            children: (
              <Div flex="column" gap={32}>
                <Div
                  flex="row"
                  xl={{ flex: "column" }}
                  gap={20}
                  justify="space-between"
                  align="stretch"
                >
                  <Div flex="column" gap={9} style={{ flex: "3 0 0%" }}>
                    <Typography
                      color={theme.colors.grey_2}
                      elementTheme="overline"
                      textTransform="uppercase"
                      letterSpacing={0.33}
                    >
                      {t("profile_projects|projectSummary")}
                    </Typography>
                    {renderDescription()}
                  </Div>
                  <Tablet direction="up">
                    <Divider
                      type="vertical"
                      style={{
                        height: "auto",
                        borderColor: theme.colors.grey_4,
                      }}
                    />
                  </Tablet>
                  <Div
                    flex="column"
                    gap={18}
                    style={{ flex: "2 0 0%" }}
                    overflow="hidden"
                  >
                    <InfoRow
                      Icon={Service}
                      label={t("profile_projects|serviceReceived")}
                      value={service}
                    />
                    {industry && (
                      <InfoRow
                        Icon={Industry}
                        label={t("profile_projects|industry")}
                        value={industry}
                      />
                    )}
                    {(budget_max > 0 || budget_min > 0) && (
                      <InfoRow
                        Icon={Budget}
                        label={t("profile_projects|budgetRange")}
                        value={`\$${budget_min || 0} - \$${budget_max || 0}`}
                      />
                    )}
                    <InfoRow
                      Icon={Timeline}
                      label={t("profile_projects|timeline")}
                      value={t(
                        `projects_start_when|${start_when?.toLowerCase()}`
                      )}
                    />
                  </Div>
                </Div>

                <Div
                  wrap="wrap"
                  flex="row"
                  gap={8}
                  xl={{ flex: "column", gap: 11 }}
                >
                  <HdTag
                    color={theme.colors.purple_5}
                    text={category}
                    translateTag
                  />
                  <HdTag
                    color={theme.colors.purple_5}
                    text={service}
                    translateTag
                  />
                  {React.Children.toArray(
                    specialties?.map((tag, tagIndex) => (
                      <HdTag
                        key={tagIndex}
                        color={theme.colors.purple_5}
                        text={tag}
                        translateTag
                      />
                    ))
                  )}
                </Div>
              </Div>
            ),
          },
        ]}
      />
    </>
  );
};

export default ProjectCard;

