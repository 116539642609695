import { CSSProperties, PropsWithChildren, useState } from "react";
import { AvatarContainer, StyledAvatar } from "./styles";

export type AvatarShape = "square" | "round" | "rounded-corner";
export interface AvatarProps extends PropsWithChildren {
  src?: string;
  alt?: string;
  loading?: HTMLImageElement["loading"];
  background?: string;
  size?: number;
  lineHeight?: number;
  shape?: AvatarShape;
  style?: CSSProperties;
}

const Avatar = ({
  children,
  src,
  alt,
  loading = "lazy",
  size = 128,
  lineHeight,
  shape = "square",
  background,
  style,
}: AvatarProps) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => setHasError(true);
  return (
    <AvatarContainer
      $shape={shape}
      $lineHeight={lineHeight}
      $size={size}
      $background={background}
      style={style}
    >
      {hasError || !src ? (
        children
      ) : (
        <StyledAvatar
          src={src}
          alt={alt}
          loading={loading}
          width={size}
          height={size}
          onError={handleError}
        />
      )}
    </AvatarContainer>
  );
};

export default Avatar;

