const urlRegexString =
  "^(https?:\\/\\/)?" + // validate protocol
  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
  "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
  "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
  "(\\#[-a-z\\d_]*)?$";

const validateURL = async (url: string) => {
  try {
    const checked_url = url.includes("youtube.com")
      ? `https://www.youtube.com/oembed?format=json&url=https://www.youtube.com/watch?v=${url}`
      : url.includes("vimeo.com")
        ? `https://vimeo.com/api/oembed.json?url=${url}`
        : url;

    const status = await fetch(url)
      .then((response) => response.status)
      .catch((reason) => console.error(reason));

    const urlPattern = url.includes("localhost")
      ? new RegExp("/(localhost).")
      : new RegExp(urlRegexString, "i"); // validate fragment locator

    if (status && status <= 400 && !!urlPattern.test(url)) return true;

    return false;
  } catch (e: any) {
    console.error(e);
    return false;
  }
};

export default validateURL;
