import { PropsWithChildren } from "react";
import { useTheme } from "../../../../plugins/styled";
import Typography from "../../../common/typography";
import { StepContainerStyled } from "./styles";

const StepContainer = ({
  label,
  description,
  children,
}: { label: string; description: string } & PropsWithChildren) => {
  const theme = useTheme();
  return (
    <StepContainerStyled>
      <Typography elementTheme="body3" bold color={theme.colors.grey_2}>
        {label}
      </Typography>
      <Typography elementTheme="body1">{description}</Typography>
      {children}
    </StepContainerStyled>
  );
};
export default StepContainer;

