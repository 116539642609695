import { ElementTheme } from "../components/common/typography/entities";
import { createGlobalStyle, css } from "../plugins/styled";

export const createFontSizeCSS = (
  size: ElementTheme,
  isHeading?: boolean,
  fontWeight?: "bold" | "medium" | "regular"
) => css`
  margin: 0;
  font-size: ${({ theme }) => theme?.fonts?.[size]};
  line-height: ${({ theme }) => theme?.fontsLineHeights?.[size]};
  letter-spacing: ${({ theme }) => theme?.letterSpacing?.[size]}px;
  font-family: ${isHeading ? "Figtree" : "Inter"};
  font-weight: ${({ theme }) =>
    isHeading || fontWeight === "bold"
      ? theme.fontWeights.semibold
      : fontWeight === "medium"
        ? theme.fontWeights.medium
        : theme.fontWeights.regular};

  @media (max-width: ${({ theme }) => theme?.breakpoints?.tablet}px) {
    font-size: ${({ theme }) => theme?.fonts?.[`${size}_md`]};
    line-height: ${({ theme }) => theme?.fontsLineHeights?.[`${size}_md`]};
    letter-spacing: ${({ theme }) => theme?.letterSpacing?.[`${size}_md`]}px;
  }
`;

export const GlobalTypographyStyling = createGlobalStyle`
.h1{
  ${createFontSizeCSS("h1", true)}
}
.h2{
  ${createFontSizeCSS("h2", true)}
}
.h3{
  ${createFontSizeCSS("h3", true)}
}
.h4{
  ${createFontSizeCSS("h4", true)}
}
.h5{
  ${createFontSizeCSS("h5", true)}
}
.h6 {
  ${createFontSizeCSS("h6", true)}
}

.subtitle1 {
  ${createFontSizeCSS("subtitle1", false, "medium")}
}

.subtitle2 {
  ${createFontSizeCSS("subtitle2", false, "medium")}
}

.body1 {
  ${createFontSizeCSS("body1")}
}
.body2 {
  ${createFontSizeCSS("body2")}
}
.body3 {
  ${createFontSizeCSS("body3")}
}

.caption {
  ${createFontSizeCSS("caption")}
}

.overline {
  text-transform: uppercase;
  ${createFontSizeCSS("overline", false, "medium")}
}

`;

