import styled from "../../../../../plugins/styled";
import Card from "../../../../common/Card";
import Div from "../../../../common/div";
import { ParagraphContainer } from "../../../../common/typography/styles";

export const PortfolioCover = styled(Div)`
  color: ${(props) => props.theme.colors.white_1};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 250ms linear;
  border-radius: 6px;

  ${ParagraphContainer} {
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.4) 81%
    );
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    margin-top: auto;
    padding: 30px 15px 18px;
    width: 100%;
  }
  &:hover {
    opacity: 1;
  }
`;

export const PortfolioLogoContainer = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.grey_1};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .portfolio-logo-avatar {
    object-fit: contain;
    object-position: center;
  }
`;

export const PortfolioCardContainer = styled(Card)`
  width: 314px;
  height: 235px;
  width: 314px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 6px;
  flex-grow: 0;
  flex-shrink: 0;
  .ant-card-body {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  border: 1px solid ${(props) => props.theme.colors.grey_1};

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.grey_1};

    .provider-portfolio-card-mask {
      opacity: 1;
    }

    .portfolio-cover {
      opacity: 1;
    }
  }
`;

