import { DetailedHTMLProps, HTMLAttributes } from "react";
import styled, { css } from "../../../plugins/styled";
import { TextStylingProps } from "./entities";

const LineClampStyling = css<TextStylingProps>`
  -webkit-line-clamp: ${({ $lineClamp }) => $lineClamp};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
`;

const FitContentStyling = css`
  width: max-content;
  height: fit-content;
  line-height: 1;
`;

const EllipsisStyling = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const StrikeStyling = css<TextStylingProps>`
position: relative;

&:before {
  position: absolute;
  content: "";
  left: 0;
  top: 45%;
  right: 0;
  border-top: ${({ $bold }) => ($bold ? "2px" : "1px")} solid;
  border-color: inherit;

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
  $
}
`;

const TextStyling = css<TextStylingProps>`
  &.hd-heading,
  &.hd-paragraph {
    margin: 0;
    color: ${(props) => (props.$color ? props.$color : "inherit")};
    ${({ $colorHover }) =>
      $colorHover ? `&:hover { color:${$colorHover}; }` : undefined};

    ${(props) => (!!props.$nowrap ? "white-space: nowrap;" : "")}
    ${(props) => (!!props.$inline ? "display: inline-block;" : "")}
  ${(props) => (!!props.$underline ? "text-decoration: underline;" : "")}
  ${(props) => (!!props.$fitContent ? FitContentStyling : "")}
  ${(props) => (!!props.$strike ? StrikeStyling : "")}
  ${(props) => (!!props.$ellipsis ? EllipsisStyling : "")}

    ${({ $bold, $medium, theme }) =>
      !!$bold || !!$medium
        ? `
  font-weight: ${
    $bold
      ? theme.fontWeights.semibold
      : !!$medium
        ? theme.fontWeights.medium
        : undefined
  };
    `
        : undefined}
    ${(props) => (!!props.$lineClamp ? LineClampStyling : "")}
  }
`;

export const Highlighting = styled.div<{ $color: string }>`
  position: absolute;
  width: 105%;
  height: 70%;
  position: absolute;
  bottom: 1%;
  left: -4%;
  background-color: ${({ $color }) => $color};
`;
export const HighlightContainer = styled.div`
  position: relative;
  width: fit-content;
  display: inline-block;
`;

export type TypographyContainerProps = TextStylingProps &
  DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

export const ParagraphContainer = styled.div<TextStylingProps>`
  ${TextStyling}
`;
export const H1 = styled.h1<TextStylingProps>`
  ${TextStyling}
`;
export const H2 = styled.h2<TextStylingProps>`
  ${TextStyling}
`;
export const H3 = styled.h3<TextStylingProps>`
  ${TextStyling}
`;
export const H4 = styled.h4<TextStylingProps>`
  ${TextStyling}
`;
export const H5 = styled.h5<TextStylingProps>`
  ${TextStyling}
`;
export const H6 = styled.h6<TextStylingProps>`
  ${TextStyling}
`;

