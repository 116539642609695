import { ThemeConfig } from "antd/es/config-provider";
import { DefaultTheme } from ".";

const getAntdTheme = (theme: DefaultTheme): ThemeConfig => {
  const token: ThemeConfig["token"] = {
    colorPrimary: theme.colors.purple_1,
    colorText: theme.colors.grey_1,
    borderRadius: 24,
    colorLink: theme.colors.purple_2,
    colorLinkActive: theme.colors.purple_3,
    colorLinkHover: theme.colors.purple_1,
    colorPrimaryHover: "nil",
    fontFamily: "Inter",
    fontSize: 16,
  };

  const components: ThemeConfig["components"] = {
    Button: {
      borderRadius: 24,
    },
    Steps: {
      lineWidth: 3,
      dotSize: 8,
      margin: 16,
      fontSizeLG: 12,
      colorPrimary: theme.colors.grey_1,
      colorSplit: theme.colors.grey_2,
      colorTextDisabled: theme.colors.purple_2,
      colorText: theme.colors.grey_2,
      colorTextDescription: theme.colors.grey_2,
      colorTextLabel: theme.colors.grey_1,
      colorPrimaryBorder: theme.colors.grey_1,
      colorFillContent: theme.colors.grey_5,
      iconSizeSM: 20,
    },
    Upload: {
      colorTextPlaceholder: "#757585",
      colorBgBase: "#fafaff",
      borderRadiusLG: 4,
      colorBorder: theme.colors.grey_1,
      lineHeight: 1,
      colorFillAlter: theme.colors.white_1,
    },
    Tag: {
      paddingXS: 7,
      lineHeight: 20,
      fontSizeSM: 12,
      fontSize: 16,
      marginXS: 0,
      colorSuccess: "#28CE8A",
      colorWarning: "#B4B500",
      defaultColor: theme.colors.grey_3,
      green: "#28CE8A",
      borderRadius: 18,
    },

    Card: {
      paddingLG: 0,
    },
    Divider: {
      colorSplit: theme.colors.grey_2,
      marginLG: 0,
    },
    Typography: {
      colorLink: theme.colors.purple_2,
      colorLinkActive: theme.colors.purple_3,
      colorLinkHover: theme.colors.purple_2,
    },
    Tooltip: {
      colorBgSpotlight: theme.colors.white_1,
      colorTextLightSolid: theme.colors.grey_2,
      borderRadiusLG: 0,
      borderRadiusXS: 0,
      borderRadiusOuter: 0,
    },
    Collapse: {
      borderRadius: 0,
      borderRadiusLG: 0,
      colorFillAlter: theme.colors.white_2,
      colorBgContainer: theme.colors.white_2,
      colorBorder: theme.colors.grey_5,
    },

    Form: {
      fontSizeLG: 14,
      fontSize: 16,
      paddingXS: 11,
      colorTextDescription: theme.colors.grey_2,
    },
    Select: {
      lineHeight: 1.4,
      borderRadius: 4,
      borderRadiusLG: 4,
      colorBgBase: theme.colors.white_1,
      colorText: theme.colors.grey_2,
      colorBorder: theme.colors.primary,
      colorPrimaryBorder: theme.colors.purple_3,
      controlPaddingHorizontal: 14,
      paddingXXS: 7,
      controlItemBgActive: theme.colors.grey_5,
      borderRadiusSM: 0,
      fontWeightStrong: 400,
      fontSize: 14,
      fontSizeLG: 16,
      colorTextDescription: theme.colors.grey_2,
    },
    Input: {
      lineHeight: 1,
      borderRadius: 4,
      borderRadiusLG: 4,
      fontSize: 14,
      fontSizeLG: 16,
      colorBgBase: theme.colors.white_1,
      colorText: theme.colors.grey_2,
      colorBorder: theme.colors.primary,
      colorTextDescription: theme.colors.grey_2,
    },
    DatePicker: {
      lineHeight: 1,
      borderRadius: 4,
      borderRadiusLG: 4,
      fontSize: 14,
      fontSizeLG: 16,
      colorBgBase: theme.colors.white_1,
      colorText: theme.colors.grey_2,
      colorBorder: theme.colors.primary,
      colorTextDescription: theme.colors.grey_2,
    },
    InputNumber: {
      lineHeight: 1,
      borderRadius: 4,
      borderRadiusLG: 4,
      fontSize: 14,
      fontSizeLG: 16,
      colorBgBase: theme.colors.white_1,
      colorText: theme.colors.grey_2,
      colorBorder: theme.colors.primary,
      colorTextDescription: theme.colors.grey_2,
    },

    Radio: {
      buttonBg: theme.colors.purple_1,
      buttonCheckedBg: theme.colors.purple_1,
      buttonSolidCheckedColor: theme.colors.purple_1,
      colorBorder: theme.colors.grey_1,
      wrapperMarginInlineEnd: 0,
    },
    Dropdown: {
      borderRadiusSM: 0,
      borderRadius: 0,
      borderRadiusLG: 0,
      lineHeight: 1.6,
      colorPrimaryBorder: theme.colors.primary,
      controlPaddingHorizontal: 0,
      controlItemBgHover: theme.colors.grey_5,
      colorText: theme.colors.grey_3,
      paddingXXS: 12,
    },
    Layout: {
      headerBg: theme.colors.background,
      bodyBg: theme.colors.background,
      footerBg: theme.colors.background,
      siderBg: theme.colors.grey_1,
      lightSiderBg: theme.colors.white_1,
      fontSize: 16,
    },
    Menu: {
      itemMarginInline: 0,
      margin: 0,
      controlHeightLG: 20,
      borderRadiusLG: 0,
      padding: 0,
      marginXXS: 0,
      itemColor: theme.colors.grey_2,
      itemBg: theme.colors.white_1,
      itemSelectedColor: theme.colors.purple_2,
      itemSelectedBg: theme.colors.purple_5,
      itemHoverColor: theme.colors.purple_1,
      itemHoverBg: theme.colors.purple_4,
      subMenuItemBg: theme.colors.white_1,

      darkItemColor: theme.colors.white_1,
      darkItemBg: theme.colors.grey_1,
      darkItemSelectedColor: theme.colors.purple_3,
      darkItemSelectedBg: theme.colors.grey_1,
      darkItemHoverColor: theme.colors.purple_4,
      darkItemHoverBg: theme.colors.grey_1,
      darkSubMenuItemBg: theme.colors.grey_1,
      colorSplit: "transparent",
    },

    Timeline: {
      tailColor: "#D8B9E3",
      colorPrimary: theme.colors.purple_3,
      colorSuccess: theme.colors.grey_3,
      dotBorderWidth: 5,
      tailWidth: 2,
      colorTextDisabled: theme.colors.purple_1,
      colorText: theme.colors.grey_3,
      colorTextDescription: theme.colors.purple_1,
    },
    Spin: {
      colorPrimary: theme.colors.purple_1,
    },
    Avatar: {
      borderRadius: 0,
      colorTextPlaceholder: theme.colors.purple_3,
    },
    Tabs: {
      horizontalItemPadding: "8px 0",
      colorPrimary: theme.colors.green_1,
      itemActiveColor: theme.colors.grey_1,
      itemHoverColor: theme.colors.purple_2,
    },
    Table: {
      headerBg: "transparent",
      headerSplitColor: "transparent",
      headerColor: theme.colors.grey_2,
      colorBgBase: "transparent",
      colorBgContainer: "transparent",
      borderColor: theme.colors.grey_5,
      headerBorderRadius: 0,
      fontWeightStrong: 500,
    },
  };

  return {
    token,
    components,
  };
};
export default getAntdTheme;

