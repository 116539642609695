import { MappedTranslations } from "../../entities/translations-entities";

export interface FlatTranslation {
  [key: string]: string;
}

export interface TranslationField {
  key: string;
  value: string;
}

export interface TranslationFile {
  key: string;
  translationFileCollection?: { items: TranslationFile[] };
  translationsFieldsCollection?: { items: TranslationField[] };
}

export const flattenTranslations = (
  arr: MappedTranslations[]
): FlatTranslation => {
  let flatObject = {};

  for (const key in arr) {
    if (Object.prototype.hasOwnProperty.call(arr, key)) {
      const obj = arr[key];

      for (const sectionName in obj) {
        const section = obj[sectionName];

        for (const translationKey in section) {
          const translation = section[translationKey];

          if (Array.isArray(translation)) {
            flatObject = { ...flatObject, ...flattenTranslations(translation) };
          } else {
            flatObject = {
              ...flatObject,
              [`${sectionName}|${translationKey}`]: translation,
            };
          }
        }
      }
    }
  }

  return flatObject;
};

export const addParamToTranslation = (
  str: string,
  params: { [key: string]: string | number }
) => {
  if (!params || Object.keys(params).length === 0) return str;

  const regex = new RegExp(`{{(${Object.keys(params).join("|")})}}`, "g");
  return str.replace(regex, (_, key) => `${params[key]}` || "");
};

export const formatKey = (key: string) => key.replaceAll("-", "_");

export const processTranslationFiles = (
  files: TranslationFile[]
): MappedTranslations =>
  files.reduce((acc, file) => {
    const formattedKey = formatKey(file.key);
    const subFiles = file.translationFileCollection?.items ?? [];
    const fields = file.translationsFieldsCollection?.items.reduce(
      (fieldsAcc, { key, value }) => ({
        ...fieldsAcc,
        [formatKey(key)]: value,
      }),
      {}
    );

    return {
      ...acc,
      [formattedKey]: {
        ...(acc[formattedKey] ?? {}),
        ...processTranslationFiles(subFiles),
        ...fields,
      },
    };
  }, {});

