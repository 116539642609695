import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Input from "antd/es/input";

interface OnboardingCompanyNameFormFieldsProps {
  isLoading?: boolean;
}
const OnboardingCompanyNameFormFields = ({
  isLoading,
}: OnboardingCompanyNameFormFieldsProps) => {
  const { t } = useTranslation();

  return (
    <Container
      style={{
        minHeight: 350,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Div flex="column" gap={24}>
        <Div flex="column" gap={12}>
          <Typography.Title
            noMargin
            level={2}
            elementTheme="h4"
            medium
            textAlign="center"
          >
            {t("onboarding|profile.companyName")}
          </Typography.Title>
          <Typography color={theme.colors.grey_2} textAlign="center">
            {t("onboarding|profile.companyNameDescription")}
          </Typography>
        </Div>
        <FormItem
          name="company_name"
          label={t("profile|profileForm.company.labels.name")}
          rules={[
            {
              required: true,
              message: t("profile|profileForm.company.validation.name"),
            },
          ]}
          noStyle
          style={{ flex: 1 }}
        >
          <Input
            size={"large"}
            placeholder={t("profile|profileForm.company.placeholder.name")}
            disabled={isLoading}
            style={{
              minHeight: 60,
              marginTop: 20,
              textAlign: "center",
              fontSize: 32,
              width: "80%",
              margin: "auto",
            }}
          />
        </FormItem>
      </Div>
    </Container>
  );
};

export default OnboardingCompanyNameFormFields;

