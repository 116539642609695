import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Copy = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6566 23.6581V28.5812C21.6566 29.487 20.8963 30.2222 19.9596 30.2222H5.25253C4.80246 30.2222 4.37083 30.0493 4.05259 29.7416C3.73434 29.4338 3.55556 29.0164 3.55556 28.5812V9.9829C3.55556 9.07705 4.3158 8.34188 5.25253 8.34188H8.08081C8.8389 8.34138 9.59572 8.40188 10.3434 8.52275M21.6566 23.6581H26.7475C27.6842 23.6581 28.4444 22.9229 28.4444 22.0171V14.906C28.4444 8.40022 23.5526 3.00161 17.1313 1.95865C16.3836 1.83778 15.6268 1.77728 14.8687 1.77777H12.0404C11.1037 1.77777 10.3434 2.51295 10.3434 3.4188V8.52275M21.6566 23.6581H12.0404C11.5903 23.6581 11.1587 23.4852 10.8405 23.1775C10.5222 22.8697 10.3434 22.4523 10.3434 22.0171V8.52275M28.4444 18.188V15.453C28.4444 14.1473 27.9081 12.8951 26.9534 11.9718C25.9986 11.0486 24.7037 10.5299 23.3535 10.5299H21.0909C20.6408 10.5299 20.2092 10.357 19.891 10.0493C19.5727 9.74151 19.3939 9.32411 19.3939 8.88888V6.70085C19.3939 6.05434 19.2623 5.41417 19.0064 4.81687C18.7506 4.21958 18.3756 3.67686 17.9028 3.21971C17.4301 2.76256 16.8689 2.39993 16.2512 2.15252C15.6336 1.90511 14.9716 1.77777 14.303 1.77777H12.6061"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Copy;

