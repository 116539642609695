import { useTranslation } from "../../../../plugins/i18n";
import Div from "../../../common/div";
import ModalWarning from "../../../common/hd-modal/layouts/modal-with-warning";
import Typography from "../../../common/typography";

interface OnlyCanadianWarningModalProps {
  open: boolean;
  handleClose: () => void;
}
const OnlyCanadianWarningModal = ({
  open,
  handleClose,
}: OnlyCanadianWarningModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalWarning open={open} handleCancel={handleClose}>
      <Div flex="column" gap={8} align="center">
        <Typography.Title textAlign="center" level={2} elementTheme="h5">
          {t("profile|country.notCanadaWarningTitle")}
        </Typography.Title>
        <Typography textAlign="center">
          {t("profile|country.notCanadaWarningSubtitle")}
        </Typography>
      </Div>
    </ModalWarning>
  );
};
export default OnlyCanadianWarningModal;

