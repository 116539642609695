import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchReviews,
  selectAllReviews,
  selectReviewsLoading,
} from "../../features/api/slices/reviews-slice";
import { usePartnerApi } from "../../features/api/use-partner-api";
import BadgesSection from "./badges-section";
import ReviewsTable from "./reviews-table";
import YourReviewLink from "./your-review-link";

const YourReviewsPage = ({ isBadge }: { isBadge?: boolean }) => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const { t } = useTranslation();
  const reviews = useAppSelector(selectAllReviews);
  const isLoading = useAppSelector(selectReviewsLoading);

  useEffect(() => {
    dispatch(fetchReviews({ api }));
  }, [dispatch, api]);

  return (
    <>
      <PageLayout
        title={
          <>
            <Typography elementTheme="h5">
              {t("navigation.reviewsTitle")}
            </Typography>
          </>
        }
        isLoading={isLoading}
        breadcrumbs={[
          {
            breadcrumbName: t("navigation.home"),
            path: "/",
          },
          {
            breadcrumbName: t("navigation.profile"),
            path: "/profile",
          },
          {
            breadcrumbName: t("navigation.reviewsTitle"),
          },
        ]}
        actions={
          <Div justify="flex-end" flex={"column"} gap={8} fitContent>
            <Typography elementTheme="overline">
              {t("title.yourReviewsLink")}
            </Typography>
            <YourReviewLink />
          </Div>
        }
        app="provider"
      >
        <Div flex={isBadge ? "column-reverse" : "column"} gap={54}>
          <ReviewsTable reviews={reviews} />

          <BadgesSection isDisabled={isLoading || !reviews.length} />
        </Div>
      </PageLayout>
    </>
  );
};

export default YourReviewsPage;

