import { useTheme } from "styled-components";
import Loading from ".";

const LoadingScreen = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: theme.colors.background,
      }}
    >
      <Loading size="extra-large" />
    </div>
  );
};
export default LoadingScreen;

