import Tabs from "antd/es/tabs";
import styled from "../../../../../plugins/styled";

export const ProjectMenuTabs = styled(Tabs)`
  & > .ant-tabs-nav {
    margin-bottom: 25px;
    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${(props) => props.theme.colors.grey_1};
          text-shadow: none;
        }
      }
    }
  }
`;

