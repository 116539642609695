import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Image from "antd/es/image";
import { useState } from "react";
import { ProfileModalProps } from "../../../../features/entities";
import ProfileBlocks from "../../../../features/enums/profile-blocks";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import { AssetOverlay, EditButton } from "./styles";

type ProfilebannerProps = {
  isEditable?: boolean;
  banner: string;
  name: string;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
};
const ProfileBanner = ({
  isEditable = false,
  banner,
  name,
  toggleEditModal,
}: ProfilebannerProps) => {
  const theme = useTheme();

  const [bannerFailed, setbannerFailed] = useState(false);

  const handleEdit = () =>
    !!toggleEditModal &&
    toggleEditModal({ open: true, type: ProfileBlocks.Banner });

  return (
    <>
      <Div
        backgroundColor={theme.colors.grey_5}
        flex="column"
        align="center"
        justify="center"
        style={{ position: "relative", aspectRatio: `1120 / 240` }}
        xl={{ style: { aspectRatio: `326 / 119` } }}
        overflow="hidden"
      >
        {banner && !bannerFailed ? (
          <Image
            style={{ objectFit: "cover", objectPosition: "center" }}
            onError={() => setbannerFailed(true)}
            src={banner}
            alt={name}
            preview={false}
            width={"100%"}
            height={"100%"}
          />
        ) : (
          <Div
            backgroundColor={theme.colors.gradient}
            style={{
              height: 100,
              width: "100%",
              position: "absolute",
              bottom: 0,
            }}
          >
            {""}
          </Div>
        )}

        {isEditable && (
          <AssetOverlay onClick={handleEdit} $type="banner">
            <EditButton>
              <Edit width={18} height={18} />
            </EditButton>
          </AssetOverlay>
        )}
      </Div>
    </>
  );
};
export default ProfileBanner;
