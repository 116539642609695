import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import FaqContent from "@hellodarwin/core/lib/components/faq";
import { QA } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";

const FaqPage = () => {
  const { t } = useTranslation();
  const QAs: QA[] = [
    {
      question: t("faq|faq.question1.question"),
      answer: t("faq|faq.question1.answer"),
    },
    {
      question: t("faq|faq.question2.question"),
      answer: t("faq|faq.question2.answer"),
    },
    {
      question: t("faq|faq.question3.question"),
      answer: t("faq|faq.question3.answer"),
    },
    {
      question: t("faq|faq.question4.question"),
      answer: t("faq|faq.question4.answer"),
      videos: [
        {
          label: t("faq|faq.question4.video1.label"),
          url: t("faq|faq.question4.video1.url"),
          name: "General presentation of the partner app",
        },
        {
          label: t("faq|faq.question4.video2.label"),
          url: t("faq|faq.question4.video2.url"),
          name: "Profile - Expertise",
        },
        {
          label: t("faq|faq.question4.video3.label"),
          url: t("faq|faq.question4.video3.url"),
          name: "Profile - Portfolio",
        },
        {
          label: t("faq|faq.question4.video4.label"),
          url: t("faq|faq.question4.video4.url"),
          name: "Profile -  Overview and reviews",
        },
        {
          label: t("faq|faq.question4.video5.label"),
          url: t("faq|faq.question4.video5.url"),
          name: "Overview of client app",
        },
      ],
    },
    {
      question: t("faq|faq.question5.question"),
      answer: t("faq|faq.question5.answer"),
    },
    {
      question: t("faq|faq.question6.question"),
      answer: t("faq|faq.question6.answer"),
    },
    {
      question: t("faq|faq.question7.question"),
      answer: t("faq|faq.question7.answer"),
    },
    {
      question: t("faq|faq.question8.question"),
      answer: t("faq|faq.question8.answer"),
    },
  ];

  const contact_email = "clients@hellodarwin.com";
  return (
    <PageLayout
      app="provider"
      title={t("faq|faq.title")}
      subtitle={
        <>
          {t("faq|faq.subtitle")}
          <a href={`mailto:${contact_email}`}>{contact_email}</a>
        </>
      }
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.faq"),
        },
      ]}
    >
      <Container>
        <FaqContent QAs={QAs} defaultActiveKey={"4"} />
      </Container>
    </PageLayout>
  );
};

export default FaqPage;

