import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { ProjectRowInformation } from "@hellodarwin/core/lib/features/entities";

type ProjectInformationRowProps = {
  info: ProjectRowInformation;
  justify?: "flex-start" | "flex-end" | "center";
  tablet?: {
    justify?: "flex-start" | "flex-end" | "center";
  };
};

const ProjectInformationRow = ({
  info,
  justify,
  tablet,
}: ProjectInformationRowProps) => {
  const { title, value, hide } = info;

  if (!!hide || !value) return <></>;

  return (
    <Div
      flex={"row"}
      align={"center"}
      gap={8}
      wrap={"wrap"}
      justify={justify}
      tablet={tablet}
    >
      <Typography elementTheme="body2">{title}</Typography>
      <Typography elementTheme="body2" bold>
        {value?.toString()?.length < 45
          ? value
          : value.toString().slice(0, 45) + "..."}
      </Typography>
    </Div>
  );
};

export default ProjectInformationRow;

