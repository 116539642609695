import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

interface TabArrowProps extends IconProps {
  down?: boolean;
}

const TabArrow = (props: TabArrowProps) => {
  const { down } = props;
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      style={{
        ...iconProps.style,
        flexBasis: iconProps.width,
        flexShrink: 0,
        flexGrow: 0,
        transform: down ? "rotate(0deg)" : "rotate(-180deg)",
        transition: "transform 150ms ease",
      }}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 10L16.0001 22L4 10"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TabArrow;

