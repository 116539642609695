import React from "react";
import { useTheme } from "styled-components";
import { AppType } from "../../../../features/entities/core-entities";
import { NavigationLink } from "../../../../features/entities/layout-entities";
import Div from "../../../common/div";
import { handleLinkClickFunction } from "../utils/use-handle-link-click";
import NavigationMenuLabelWrapper from "./menu-item-label-wrapper";

export type SubNavigationMenuItemsListSize = "medium" | "large";

export interface SubNavigationMenuItemsListProps {
  items: NavigationLink[];
  app: AppType;
  isCollapsed?: boolean;
  handleLinkClick?: handleLinkClickFunction;
  size?: SubNavigationMenuItemsListSize;
  removeBorder?: boolean;
}

const SubNavigationMenuItemsList = ({
  items,
  app,
  isCollapsed,
  handleLinkClick,
  size,
  removeBorder,
}: SubNavigationMenuItemsListProps) => {
  const theme = useTheme();

  return (
    <Div
      flex="row"
      gap={12}
      align="stretch"
      style={{ paddingLeft: isCollapsed || removeBorder ? 0 : 24 }}
    >
      {!isCollapsed && !removeBorder && (
        <div
          style={{ width: 1, borderLeft: `1px solid ${theme.colors.grey_2}` }}
        />
      )}
      <Div flex="column" gap={isCollapsed ? 8 : 0}>
        {React.Children.toArray(
          items.map((itm) => (
            <NavigationMenuLabelWrapper
              {...itm}
              isSublink={size !== "large"}
              app={app}
              handleLinkClick={handleLinkClick}
              isCollapsed={isCollapsed}
            />
          ))
        )}
      </Div>
    </Div>
  );
};

export default SubNavigationMenuItemsList;

