import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Tooltip from "antd/es/tooltip";

type ProjectRaiseHandRHTagProps = {
  message: string;
};

const ProjectRaiseHandRHTag = ({ message }: ProjectRaiseHandRHTagProps) => {
  const { t } = useTranslation();

  const rhText = parse(message || "", {
    trim: true,
  }).toString();

  return (
    <HdTag
      color={theme.colors.yellow_2}
      text={
        <Tooltip placement="bottom" title={parse(message)}>
          {""}
          <Typography elementTheme="caption" bold inline>
            {t(`project|projectPage.rhStatusDetails`)}&nbsp;&nbsp;
          </Typography>
          <Typography
            elementTheme="caption"
            color={theme.colors.grey_2}
            bold
            inline
          >
            {rhText.slice(0, 40)}
            {rhText.length > 40 && "[...]"}
          </Typography>
        </Tooltip>
      }
    />
  );
};

export default ProjectRaiseHandRHTag;

