import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Match } from "@hellodarwin/core/lib/features/entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import ConfigProvider from "antd/es/config-provider";
import Empty from "antd/es/empty";
import List from "antd/es/list";
import Select, { SelectProps } from "antd/es/select";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/app-hooks";
import { selectMatchesLoading } from "../../features/api/slices/matches-slice";
import { filterMatches } from "../../features/helpers";
import ProjectCard from "./project-banner/project-card";

type ProjectListProps = {
  matches: Match[];
  newProject: boolean;
};

const ProjectList = ({ matches, newProject }: ProjectListProps) => {
  const { t } = useTranslation();

  const [filteredMatches, setMatches] = useState<Match[]>([]);
  const [sortType, setSortType] = useState("status");
  const [orderType, setOrderType] = useState("ascending");
  const loading = useAppSelector(selectMatchesLoading);
  const VALID_SORT_TYPES = ["status", "service", "budget", "recent"];
  const VALID_ORDER_TYPES = ["ascending", "descending"];

  useEffect(() => {
    setMatches([...filterMatches(matches, sortType, orderType)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  useEffect(() => {
    setMatches([...filterMatches(matches, sortType, orderType)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType, orderType]);

  const orderOptions: SelectProps["options"] = [
    {
      value: "ascending",
      label: t("project|filters.orderBy.options.ascending"),
    },
    {
      value: "descending",
      label: t("project|filters.orderBy.options.descending"),
    },
  ];

  const OrderBySelect = () => (
    <div>
      <Typography elementTheme="body2" style={{ marginBottom: 8 }}>
        {t("project|filters.orderBy.title")}
      </Typography>
      <Select
        options={orderOptions}
        onChange={(value: string) => {
          setOrderType(value);
          updateUrlParams({ orderType: value });
        }}
        placeholder={t("project|filters.orderBy.title")}
        value={orderType}
        style={{
          width: 221,
        }}
        suffixIcon={
          <TabArrow width={10} height={10} down style={{ marginRight: 10 }} />
        }
      />
    </div>
  );

  const SortOptions: SelectProps["options"] = [
    {
      value: "status",
      label: t("project|filters.sortBy.options.status"),
    },
    {
      value: "service",
      label: t("project|filters.sortBy.options.service"),
    },
    {
      value: "budget",
      label: t("project|filters.sortBy.options.budget"),
    },
    {
      value: "recent",
      label: t("project|filters.sortBy.options.recent"),
    },
  ];

  const SortBySelect = () => {
    return (
      <div>
        <Typography elementTheme="body2" style={{ marginBottom: 8 }}>
          {t("project|filters.sortBy.title")}
        </Typography>
        <Select
          options={SortOptions}
          onChange={(value: string) => {
            setSortType(value);
            updateUrlParams({ sortType: value });
          }}
          placeholder={t("project|filters.sortBy.title")}
          value={sortType}
          style={{ width: 200 }}
          suffixIcon={
            <TabArrow width={10} height={10} down style={{ marginRight: 10 }} />
          }
        />
      </div>
    );
  };

  const navigate = useNavigate();
  const location = useLocation();
  const getUrlParams = () => {
    return new URLSearchParams(location.search);
  };

  const updateUrlParams = (newParams: any) => {
    const searchParams = getUrlParams();
    Object.keys(newParams).forEach((key) => {
      searchParams.set(key, newParams[key]);
    });
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    const urlParams = getUrlParams();
    let urlSortType = urlParams.get("sortType");
    let urlOrderType = urlParams.get("orderType");
    let page = url.searchParams.get("page");

    let shouldUpdateUrl = false;

    if (urlSortType && VALID_SORT_TYPES.includes(urlSortType)) {
      setSortType(urlSortType);
    } else {
      setSortType("status");
      urlSortType = "status";
      shouldUpdateUrl = true;
    }

    if (urlOrderType && VALID_ORDER_TYPES.includes(urlOrderType)) {
      setOrderType(urlOrderType);
    } else {
      setOrderType("ascending");
      urlOrderType = "ascending";
      shouldUpdateUrl = true;
    }

    if (shouldUpdateUrl) {
      const searchParams = new URLSearchParams();
      searchParams.set("sortType", urlSortType);
      searchParams.set("orderType", urlOrderType);
      searchParams.set("page", page || "1");
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const url = new URL(window?.location?.href);
  const page = url.searchParams.get("page");

  const handlePageChange = (page: number, pageSize: number) => {
    const searchParams = new URLSearchParams();
    searchParams.set("sortType", sortType);
    searchParams.set("orderType", orderType);
    searchParams.set("page", `${page}`);

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };
  const renderEmpty = () => (
    <Empty description={t("project|projectPage.noProjectsAvailable")} />
  );

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <List
        pagination={{
          position: "bottom",
          align: "center",
          pageSizeOptions: [10, 25, 50, 100, 250],
          onChange: handlePageChange,
          current: parseInt(page || "1"),
          showSizeChanger: true,
        }}
        grid={{ column: 1, gutter: 20 }}
        header={
          <Div
            flex="row"
            tablet={{ flex: "column", gap: 24 }}
            justify={"space-between"}
            align="center"
          >
            <Typography elementTheme="caption" color={theme.colors.grey_2}>
              {t("project|matchesFound", { count: filteredMatches.length })}
            </Typography>
            <Div
              flex={"row"}
              fitContent
              gap={16}
              tablet={{ gap: "1", flex: "column", align: "center" }}
            >
              <Div style={{ minWidth: "230px" }} flex={"column"}>
                <SortBySelect />
              </Div>
              <Div style={{ minWidth: "150px" }} flex={"column"}>
                <OrderBySelect />
              </Div>
            </Div>
          </Div>
        }
        bordered={true}
        dataSource={filteredMatches}
        loading={loading}
        style={{ borderRadius: 0 }}
        renderItem={(match) => (
          <List.Item style={{ marginTop: 20, marginBottom: 10 }}>
            <ProjectCard
              key={match.match_id}
              match={match}
              price={match?.project?.lead_price || 0}
              discount={
                newProject
                  ? match?.project?.discounted_amount || 0
                  : match.discounted_amount || 0
              }
            />
          </List.Item>
        )}
      />
    </ConfigProvider>
  );
};

export default ProjectList;

