import Form from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import React, { useState } from "react";
import { Match } from "../../features/entities/matches-entities";
import { useTranslation } from "../../plugins/i18n";
import Button from "../common/button";
import Div from "../common/div";
import ModalLayoutSimple from "../common/hd-modal/layouts/modal-simple";
import Typography from "../common/typography";

export type MatchIgnoreFormValues = {
  ignore_reason: string;
  ignore_reason_specified: string;
};

type ProjectIgnoreModalProps = {
  match: Match;
  handleIgnore: (
    match_id: string,
    ignoreReason: string,
    ignoreReasonSpecified: string
  ) => Promise<void>;
  closeModal: () => void;
  modalVisible: boolean;
};

const ProjectIgnoreModal = ({
  match,
  closeModal,
  handleIgnore,
  modalVisible,
}: ProjectIgnoreModalProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<MatchIgnoreFormValues>();

  const [ignoreReason, setIgnoreReason] = useState("Project type");

  const initialValues = {
    ignore_reason: "Project type",
    ignore_reason_specified: "",
  };

  const handleCancel = async () => {
    form.resetFields();
    closeModal();
  };

  const handleFinish = async () => {
    handleIgnore(
      match.match_id,
      form.getFieldValue("ignore_reason"),
      form.getFieldValue("ignore_reason_specified")
    );
    handleCancel();
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!form.getFieldValue("ignore_reason")) {
      form.setFieldsValue({
        ["ignore_reason"]: "Other",
      });
    }
  };

  return (
    <ModalLayoutSimple open={modalVisible} handleCancel={handleCancel}>
      <Div flex="column" gap={16}>
        <Typography.Title level={4}>
          {t("project|ignoreModal.title")}
        </Typography.Title>
        <Form
          form={form}
          layout={"vertical"}
          size="large"
          onFinish={handleFinish}
          style={{ width: "100%" }}
          initialValues={initialValues}
          name={"ignore-project"}
        >
          <Div flex="column" gap={32}>
            <Div flex="column" gap={8}>
              <Form.Item
                name="ignore_reason"
                rules={[
                  {
                    required: true,
                    message: t("project|ignoreModal.form.validation"),
                  },
                ]}
              >
                <Radio.Group
                  size={"large"}
                  onChange={(e) => setIgnoreReason(e.target.value)}
                  defaultValue={ignoreReason}
                >
                  <Space direction="vertical">
                    <Radio value={"Project type"}>
                      <Typography elementTheme="body2">
                        {t("project|ignoreModal.reasons.projectType")}
                      </Typography>
                    </Radio>
                    <Radio value={"Client need"}>
                      <Typography elementTheme="body2">
                        {t("project|ignoreModal.reasons.clientNeed")}
                      </Typography>
                    </Radio>
                    <Radio value={"Budget"}>
                      <Typography elementTheme="body2">
                        {t("project|ignoreModal.reasons.budget")}
                      </Typography>
                    </Radio>
                    <Radio value={"Price"}>
                      <Typography elementTheme="body2">
                        {t("project|ignoreModal.reasons.price")}
                      </Typography>
                    </Radio>
                    <Radio value={"Timeline"}>
                      <Typography elementTheme="body2">
                        {t("project|ignoreModal.reasons.timeline")}
                      </Typography>
                    </Radio>
                    <Radio value={"Other"}>
                      <Typography elementTheme="body2">
                        {t("project|ignoreModal.reasons.other")}
                      </Typography>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {ignoreReason === "Other" && (
                <Form.Item name="ignore_reason_specified">
                  <TextArea
                    allowClear={true}
                    placeholder={t("project|ignoreModal.reasons.pleaseSpecify")}
                    onChange={handleChange}
                  />
                </Form.Item>
              )}
            </Div>
            <Div flex="column" align="flex-end">
              <Button onClick={handleFinish} size={"large"}>
                {t("button.ignoreProject")}
              </Button>
            </Div>
          </Div>
        </Form>
      </Div>
    </ModalLayoutSimple>
  );
};

export default ProjectIgnoreModal;

