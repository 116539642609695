import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

export interface HsConversationsSettings {
  loadImmediately: boolean;
  inlineEmbedSelector: string;
  enableWidgetCookieBanner: boolean;
  disableAttachment: boolean;
}

export interface ChatContextType {
  show: () => void;
  boot: (params?: HsConversationsSettings) => void;
  update: (params?: HsConversationsSettings) => void;
  hide: () => void;
  shutdown: () => void;
  isOpen: boolean;
  unreadMessagesCount: number;
  activeConversation: string;
}

const ChatContext = React.createContext<ChatContextType>({
  show: () => {},
  boot: () => {},
  update: () => {},
  hide: () => {},
  shutdown: () => {},
  isOpen: false,
  activeConversation: "",
  unreadMessagesCount: 0,
});

type ChatProviderProps = {} & PropsWithChildren;

declare global {
  interface Window {
    HubSpotConversations: {
      clear: (...attrs) => void;
      debug: (...attrs) => void;
      on: (...attrs) => void;
      off: (...attrs) => void;
      resetAndReloadWidget: (...attrs) => void;
      widget: {
        close: (...attrs) => void;
        load: (...attrs) => void;
        open: (...attrs) => void;
        refresh: (...attrs) => void;
        remove: (...attrs) => void;
        status: (...attrs) => void;
      };
    };
    hsConversationsOnReady: (() => void)[];
    hsCallsToActionsReady: (() => void)[];
    hsConversationsSettings: HsConversationsSettings;
    HubSpotCallsToActions: {
      on: (...attrs) => void;
      off: (...attrs) => void;
    };
  }
}

export const ChatProvider = ({ children }: ChatProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activeConversation, setActiveConversation] = useState("");
  const eventRef = useRef(null);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {
    // Add event listener.
    window.hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
      },
    ];

    return () => {
      window.hsConversationsOnReady = [];
    };
  }, []);

  useEffect(() => {
    if (!hasLoaded) return;
    eventRef.current = (payload) => {
      setActiveConversation(payload.conversation.conversationId);
    };

    window.HubSpotConversations?.on("conversationStarted", eventRef.current);

    return () => {
      window.HubSpotConversations?.off("conversationStarted", eventRef.current);
    };
  }, [hasLoaded]);

  useEffect(() => {
    if (!hasLoaded) return;

    const listener = (payload: { unreadCount: number }) => {
      setUnreadMessagesCount(payload.unreadCount);
    };

    window.HubSpotConversations.on("unreadConversationCountChanged", listener);

    return () => {
      window.HubSpotConversations.off(
        "unreadConversationCountChanged",
        listener
      );
    };
  }, [hasLoaded]);

  useEffect(() => {
    if (!hasLoaded) return;

    window.HubSpotConversations.widget.refresh();
  }, [hasLoaded]);

  const boot = (params?: HsConversationsSettings) => {
    if (!hasLoaded) return;

    window.HubSpotConversations.widget.load();
  };
  const update = (params?: HsConversationsSettings) => {
    if (!hasLoaded) return;

    window.HubSpotConversations.widget.refresh();
  };

  const shutdown = () => {
    if (!hasLoaded) return;
    window.HubSpotConversations.off();
  };

  const show = useCallback(() => {
    if (!hasLoaded) return;

    window.HubSpotConversations.widget.open();
    setIsOpen(true);
  }, [hasLoaded]);

  const hide = useCallback(() => {
    if (!hasLoaded) return;

    window.HubSpotConversations.widget.close();
    setIsOpen(false);
  }, [hasLoaded]);

  return (
    <ChatContext.Provider
      value={{
        show,
        boot,
        update,
        hide,
        shutdown,
        isOpen,
        unreadMessagesCount,
        activeConversation,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

const useChat = () => useContext(ChatContext);

export default useChat;
