import Title from "./heading";
import Highlight from "./highlight";
import Typography from "./paragraph";

export {
  HeadingProps as TitleProps,
  ParagraphProps as TypographyProps,
} from "./entities";

type TypographyType = typeof Typography & {
  Title: typeof Title;
  Paragraph: typeof Typography;
  Highlight: typeof Highlight;
};

(Typography as TypographyType).Title = Title;
(Typography as TypographyType).Paragraph = Typography;
(Typography as TypographyType).Highlight = Highlight;

export default Typography as TypographyType;

