import { OauthOptions } from "@hellodarwin/core/lib/features/providers/auth0-provider-with-navigate";
import { AxiosError } from "@hellodarwin/core/lib/plugins/axios";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchOauthOptions } from "./use-auth0";

export interface Auth0State {
  status: "idle" | "pending";
  options: OauthOptions | AxiosError;
  accessToken: string;
}

const initialState: Auth0State = {
  status: "idle",
  options: {
    domain: "",
    client_id: "",
    audience: "",
    scope: "",
  },
  accessToken: "",
};

export const fetchOauthConfiguration = createAsyncThunk(
  "auth0/fetchConfiguration",
  async (appName: string) => {
    try {
      return await fetchOauthOptions(appName);
    } catch (error) {
      return error as AxiosError;
    }
  }
);

export const setAccessToken = createAction<string>("global/setAccessToken");

const auth0Slice = createSlice({
  name: "auth0",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOauthConfiguration.rejected, (state) => {
      state.status = "idle";
    });
    builder.addCase(fetchOauthConfiguration.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchOauthConfiguration.fulfilled, (state, { payload }) => {
      state.status = "idle";
      state.options = payload;
    });

    builder.addCase(setAccessToken, (state, { payload }) => {
      state.accessToken = payload;
    });
  },
});

export const auth0Reducer = auth0Slice.reducer;
export const selectAccessToken = (state: { auth0: Auth0State }) =>
  state.auth0.accessToken;
export const selectOptions = (state: { auth0: Auth0State }) =>
  state.auth0.options;
export const selectIsLoading = (state: { auth0: Auth0State }) =>
  state.auth0.status === "pending";

