import { ReactNode } from "react";
import Language from "../enums/language";
import ProjectStartWhen from "../enums/project-start-when";
import ProjectStatus from "../enums/project-status";
import ProjectType from "../enums/project-type";
import ProviderType from "../enums/provider-type";
import SingleLanguage from "../enums/single-language";
import { Client, Company } from "./core-entities";
import { Match } from "./matches-entities";
import { ReviewRequest } from "./reviews-entities";
import { Tag } from "./tags-entities";

export interface Project {
  project_id: string;
  contact_id?: string;
  company_id?: string;
  provider_id?: string;
  title?: string;
  description: string;
  original_description: string;
  special_conditions?: string;
  additional_comments?: string;
  type: ProjectType;
  status: ProjectStatus;
  budget_min?: number;
  budget_max?: number;
  budget_type: string;
  provider_max: number;
  provider_type: ProviderType;
  start_when?: ProjectStartWhen;
  activated_at: string | null;
  premium_at: string | null;
  account_manager: string;
  languages: Language;
  created_at: string;
  category?: string;
  service?: string;
  raise_hand_max: number;
  tags?: Tag[];
  assets?: ProjectAsset[];
  industry?: string;
  estimated_value?: number;
  raise_hand_price?: number;
  client?: Client;
  specialties?: string[];
  internal_comments?: string;
  lead_price?: number;
  lead_source?: string;
  lead_source_specification?: string;
  creation_source?: string;
  campaign_id?: string;
  ad_group_id?: string;
  kw?: string;
  fbcl_id?: string;
  activated_diy_at?: string;
  diy_requested_at?: string;
  gcl_id?: string;
  source_url?: string;
  lp_url?: string;
  fbc?: string;
  canceled_reason?: string;
  comment_cancel?: string;
  canceled_at?: string;
  updated_at?: string;
  deleted_at?: string;
  caller?: string;
  discounted_amount?: number;
  aqt_at?: string | null;
  referral_provider_id?: string | null;
  company?: Company;
  rh_status_details?: string;
  referral_credit_amount?: number;
  referral_credit_applied_at?: string;
  hubspot_url?: string;
  description_goal?: string;
  description_context?: string;
  client_progression?: number;
  client_updated_progress_at?: string;
  completed_at?: string;
  completed_source?: string;
  canceled_source?: string;
  crm_id?: string;
  description_container?: { [key: string]: string };
  description_template?: string;
  request_more_providers_reason?: string;
  requested_more_providers_at?: string;
  matches?: Match[];
  shortlisted_matches?: Match[];
  rejected_matches?: Match[];
  candidates_matches?: Match[];
  status_update_token?: string;
  raise_hand_count?: number;
  shortlist_count?: number;
  completion_rate?: number;
  winner_provider_id?: string | null;
}

export const ProjectInitialState: Project = {
  project_id: "",
  title: "",
  description: "",
  original_description: "",
  type: ProjectType.Classic,
  status: ProjectStatus.PendingApproval,
  budget_min: 0,
  budget_max: 0,
  budget_type: "",
  premium_at: "",
  provider_max: 0,
  account_manager: "",
  activated_at: "",
  assets: [],
  category: "",
  created_at: "",
  provider_type: ProviderType.Any,
  languages: Language.Any,
  service: "",
  raise_hand_max: 0,
  industry: "",
};

export interface ProjectRowInformation {
  title: string;
  value: ReactNode;
  hide?: boolean;
}

export interface ProjectAsset {
  project_asset_id?: string;
  description: string;
  filename: string;
  file_extension?: string;
  project_id: string;
  uri?: string;
  url?: string;
  created_at?: string;
  updated_at?: string;
}

export interface ProjectDescription {
  project_description_id?: string;
  project_id: string;
  content: string;
  project_description_type: string;
}

export interface ProjectDescriptionTemplate {
  project_description_template_id?: string;
  content: string;
  locale: string;
  project_description_type: string;
}

export interface ProjectRequest {
  project_id?: string;
  original_description?: string;
  budget_min?: number;
  budget_max?: number;
  is_premium?: boolean;
  timeline?: string;
  premium?: boolean;
}

export interface ClientProjectRequest extends ProjectRequest {
  tags: string[];
}
export interface AdminProjectRequest extends ProjectRequest {
  tags?: Tag[];
  description: string;
  special_conditions?: string;
  additional_comments?: string;
  must_start_by?: any;
  start_when?: ProjectStartWhen;
  must_complete_by?: any;
  budget_type?: string;
  estimated_value?: number;
  lead_source?: string;
  lead_source_specification?: string;
  creation_source?: string;
  campaign_id?: string;
  ad_group_id?: string;
  kw?: string;
  fbcl_id?: string;
  gcl_id?: string;
  source_url?: string;
  lp_url?: string;
  fbc?: string;
  lead_price?: number;
  provider_max?: number;
  provider_type?: string;
  internal_comments?: string;
  caller?: string;
  account_manager?: string;
  languages?: Language;
  is_aqt?: boolean;
  is_diy_activated?: boolean;
  raise_hand_price?: number;
  company_id?: string;
  contact_id?: string;
}

export interface PartnerProjectRequest extends ProjectRequest {
  full_name: string;
  email: string;
  phone: string;
  description: string;
  preferredLanguage: string;
}

export interface AdminMappedProject
  extends Omit<Project, "matches" | "assets" | "shortlistedMatches"> {
  assets: { [key: string]: ProjectAsset };
  matches: { [key: string]: Match };
}

export interface ClientMappedProject
  extends Omit<Project, "matches" | "assets" | "shortlistedMatches"> {
  assets: { [key: string]: ProjectAsset };
}

export interface BasicProjectForm {
  name: string;
  email: string;
  budget_min: number;
  budget_max: number;
  title: string;
  original_description: string;
  estimated_value?: number;
  lead_price?: number;
  description?: string;
  company_city?: string;
  contact_phone?: string;
  contact_gender?: string;
  company_website?: string;
  company_size?: string;
  company_annual_revenue?: string;
  project_language?: SingleLanguage;
  contact_title?: string;
  is_premium?: boolean;
  is_aqt?: boolean;
  raise_hand_price?: number;
  raise_hand_max?: number;
  provider_max?: number;
  account_manager?: string;
}

export interface CancelProjectBody {
  status: string;
  canceled_reason: string;
}

export interface ProjectUpdateFunctions {
  cancel?: (
    project_id: string,
    canceled_reason: string,
    canceled_reason_specified: string
  ) => Promise<void>;
  review?: (
    projectId: string,
    providerId: string,
    reviewRequest: ReviewRequest,
    matchId?: string
  ) => Promise<void>;
  selectAsWinner?: (matchId: string) => Promise<void>;
  completProject?: (projectId: string) => Promise<void>;
  selectProgression?: (
    projectId: string,
    clientProgression: number
  ) => Promise<void>;
}

export interface ProjectTab {
  label: string | ReactNode;
  key: string;
  count?: number;
}

