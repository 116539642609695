import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Loading from "@hellodarwin/core/lib/components/loading";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/app-hooks";
import SingleProject from "../components/project/single-project";
import {
  fetchMatch,
  selectSelectedSingleMatch,
  setSelectedMatchId,
} from "../features/api/slices/matches-slice";
import { usePartnerApi } from "../features/api/use-partner-api";

type SingleProjectPageProps = {
  defaultTab: "project" | "client";
};
const SingleProjectPage = ({ defaultTab }: SingleProjectPageProps) => {
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { matchId } = useParams<{ matchId: string }>();
  const match = useAppSelector(selectSelectedSingleMatch);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (!matchId) return;
    dispatch(fetchMatch({ api, matchId }));
    dispatch(setSelectedMatchId(matchId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);

  useEffect(() => {
    if (!!match?.match_id) {
      switch (match.status) {
        case "New":
          setTitle(t("title.newOpportunities"));
          break;
        case "Waiting":
        case "Bought":
          setTitle(t("title.onGoingOpportunities"));
          break;
        case "Archive":
        case "Winner":
          setTitle(t("title.completedOpportunities"));
          break;
        default:
          setTitle(t("navigation.opportunities"));
          break;
      }
    }
  }, [match, t]);

  const location = useLocation();
  const returnToOpportunities = () => {
    const doesAnyHistoryEntryExist = location.key !== "default";
    if (doesAnyHistoryEntryExist) {
      navigate(-1);
    } else if (!!match?.match_id) {
      switch (match.status) {
        case "New":
          navigate("/projects/new");
          break;
        case "Waiting":
        case "Bought":
        case "Shortlisted":
        case "Finalist":
          navigate("/projects/ongoing");
          break;
        case "Winner":
        case "Archive":
        case "Ignored":
        case "Rejected":
          navigate("/projects/closed");
          break;
        default:
          navigate("/projects");
          break;
      }
    }
  };

  if (!match?.match_id || match?.match_id !== matchId) return <Loading />;

  return (
    <PageLayout
      handleBack={returnToOpportunities}
      app="provider"
      title={title}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        { breadcrumbName: title, onClick: returnToOpportunities },
        {
          breadcrumbName: match.project?.title || match.project?.service || "",
        },
      ]}
    >
      <SingleProject match={match} defaultTab={defaultTab} />
    </PageLayout>
  );
};

export default SingleProjectPage;

