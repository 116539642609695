import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Fingerprint = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3553 3.59133C12.1472 2.39466 14.2265 1.71077 16.3722 1.61234C18.518 1.51391 20.6501 2.00462 22.5419 3.03232C24.4337 4.06002 26.0145 5.58631 27.1165 7.449C28.2184 9.31168 28.8002 11.4412 28.8 13.6111C28.8 18.2872 27.9187 22.7534 26.3145 26.8513M6.99167 6.98785C5.70383 8.95144 5.01892 11.2556 5.02292 13.6111C5.02666 15.8739 4.39452 18.0913 3.20001 20.0054M6.35285 25.7047C9.3282 22.3948 10.9737 18.0822 10.9672 13.6111C10.9672 12.0185 11.5935 10.491 12.7082 9.36485C13.823 8.23866 15.3349 7.60598 16.9115 7.60598C18.488 7.60598 19.9999 8.23866 21.1147 9.36485C22.2295 10.491 22.8557 12.0185 22.8557 13.6111C22.8557 14.4551 22.8225 15.291 22.7543 16.1173M16.9115 13.6111C16.9203 19.3378 14.8955 24.8778 11.205 29.2246M21.7192 21.8646C20.8608 24.9021 19.5313 27.783 17.7801 30.4"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Fingerprint;

