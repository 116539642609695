import { TabsProps } from "antd/es/tabs";
import { useTheme } from "../../../../../plugins/styled";
import { ProjectMenuTabs } from "./styles";

export type ProjectMenuProps = {
  tabs: TabsProps["items"];
  activeTab: string;
  handleTabClick: (tabKey: string) => void;
};

const ProjectMenu = ({ tabs, activeTab, handleTabClick }: ProjectMenuProps) => {
  const theme = useTheme();
  return (
    <ProjectMenuTabs
      activeKey={activeTab}
      onTabClick={handleTabClick}
      items={tabs}
      style={{
        fontWeight: theme.fontWeights.regular,
        fontSize: theme.fonts.buttonC,
        lineHeight: "normal",
      }}
      className="project-menu"
    />
  );
};

export default ProjectMenu;
