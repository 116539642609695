import styled from "@hellodarwin/core/lib/plugins/styled";

export const ProjectSummaryScroll = styled.div`
  position: relative;
  overflow: hidden;
  margin-right: -10px;
  margin-left: -10px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0);
    z-index: 1;
  }

  &:after {
    left: auto;
    right: 0;
  }
  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    overflow: auto;
    width: 100%;
    flex-wrap: no-wrap;
    position: relative;
    /* width */
    &::-webkit-scrollbar {
      display: none;
    }
    &:before,
    &:after {
      display: none;
    }
  }
`;
export const ProjectSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  flex-wrap: nowrap;
  overflow: auto;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  /* width */
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    overflow: visible;
    justify-content: flex-start;
    padding: 0;
  }
`;

