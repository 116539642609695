import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Timeline = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83333 2V5.5M24.1667 2V5.5M2 26.5V9C2 8.07174 2.36875 7.1815 3.02513 6.52513C3.6815 5.86875 4.57174 5.5 5.5 5.5H26.5C27.4283 5.5 28.3185 5.86875 28.9749 6.52513C29.6313 7.1815 30 8.07174 30 9V26.5M2 26.5C2 27.4283 2.36875 28.3185 3.02513 28.9749C3.6815 29.6313 4.57174 30 5.5 30H26.5C27.4283 30 28.3185 29.6313 28.9749 28.9749C29.6313 28.3185 30 27.4283 30 26.5M2 26.5V14.8333C2 13.9051 2.36875 13.0148 3.02513 12.3585C3.6815 11.7021 4.57174 11.3333 5.5 11.3333H26.5C27.4283 11.3333 28.3185 11.7021 28.9749 12.3585C29.6313 13.0148 30 13.9051 30 14.8333V26.5M16 17.1667H16.0124V17.1791H16V17.1667ZM16 20.6667H16.0124V20.6791H16V20.6667ZM16 24.1667H16.0124V24.1791H16V24.1667ZM12.5 20.6667H12.5124V20.6791H12.5V20.6667ZM12.5 24.1667H12.5124V24.1791H12.5V24.1667ZM9 20.6667H9.01244V20.6791H9V20.6667ZM9 24.1667H9.01244V24.1791H9V24.1667ZM19.5 17.1667H19.5124V17.1791H19.5V17.1667ZM19.5 20.6667H19.5124V20.6791H19.5V20.6667ZM19.5 24.1667H19.5124V24.1791H19.5V24.1667ZM23 17.1667H23.0124V17.1791H23V17.1667ZM23 20.6667H23.0124V20.6791H23V20.6667Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Timeline;

