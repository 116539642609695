import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Upload = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77778 23.1111V26.6667C1.77778 27.6097 2.15238 28.514 2.81918 29.1808C3.48597 29.8476 4.39034 30.2222 5.33333 30.2222H26.6667C27.6097 30.2222 28.514 29.8476 29.1808 29.1808C29.8476 28.514 30.2222 27.6097 30.2222 26.6667V23.1111M8.88889 8.88888L16 1.77777M16 1.77777L23.1111 8.88888M16 1.77777V23.1111"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Upload;

