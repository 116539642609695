import { Profile, Provider } from "@hellodarwin/core/lib/features/entities";
import CompanyType from "@hellodarwin/core/lib/features/enums/company-type";
import Language from "@hellodarwin/core/lib/features/enums/language";
import SingleLanguage from "@hellodarwin/core/lib/features/enums/single-language";

const convertProviderToProfile = (provider: Provider): Profile => {
  const contact = provider.contacts?.[0];
  return {
    contact_id: contact?.contact_id || "",
    contact_name: contact?.name || "",
    contact_email: contact?.email || "",
    contact_phone: contact?.phone || "",
    contact_preferred_language:
      contact?.preferred_language || SingleLanguage.English,
    project_language: provider.project_language || Language.Any,
    company_id: provider.company_id,
    company_name: provider.name,
    company_address: provider.address || "",
    company_email: provider.email || "",
    company_website: provider.website || "",
    company_city: provider.city || "",
    company_postal_code: provider.postal_code || "",
    company_province: provider.province || "",
    company_country: provider.country || "",
    company_size: provider.size || "",
    company_founded_at: provider.founded_at,
    company_description: provider.description || "",
    company_type: provider.type || CompanyType.Agency,
    terms_and_conditions: !!provider.terms_and_conditions,
    profile_language: provider.profile_language || SingleLanguage.English,
    logo: provider.logo,
    banner: provider.banner,
    assets: provider.assets,
  };
};

export default convertProviderToProfile;
