import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useBreadcrumbs } from "../../../../features/providers/breadcrumbs-provider";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import { BreadcrumbButton, BreadcrumbText, StyledBreadcrumbs } from "./styles";

const Breadcrumbs = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { breadCrumbs } = useBreadcrumbs();

  const crumbRender = (
    route: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>,
    params: any,
    routes: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
    paths: string[]
  ) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <BreadcrumbText>{route.breadcrumbName}</BreadcrumbText>
    ) : route.onClick ? (
      <BreadcrumbButton onClick={route.onClick}>
        {route.breadcrumbName}
      </BreadcrumbButton>
    ) : (
      !!route.path && (
        <BreadcrumbButton onClick={() => navigate(`${route.path}`)}>
          {route.breadcrumbName}
        </BreadcrumbButton>
      )
    );
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <StyledBreadcrumbs
        style={{ width: "100%" }}
        separator={
          <Div flex="row" align="center" fullHeight>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
            >
              <path
                d="M1 1L4 4L1 7"
                stroke={theme.colors.grey_2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Div>
        }
        items={breadCrumbs}
        itemRender={crumbRender}
      />
    </div>
  );
};

export default Breadcrumbs;
