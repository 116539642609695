import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import Badge from "antd/es/badge";
import { useMediaQuery } from "react-responsive";
import { AppType } from "../../../../features/entities";
import { NavigationLink } from "../../../../features/entities/layout-entities";
import { useTranslation } from "../../../../plugins/i18n";
import theme from "../../../../theme";
import Div from "../../../common/div";
import HdTag from "../../../common/hd-tag";
import { handleLinkClickFunction } from "../utils/use-handle-link-click";
import { MenuItemLabelContainer, MenuItemLabelText } from "./styles";

export interface NavigationMenuLabelProps extends NavigationLink {
  isSublink?: boolean;
  isCurrent?: boolean;
  app: AppType;
  handleListItemsOpening?: () => void;
  listItemsOpen?: boolean;
  handleLinkClick?: handleLinkClickFunction;
  isCollapsed?: boolean;
  noStyle?: boolean;
}
const NavigationMenuLabel = ({
  label,
  isExternal,
  isNew,
  Icon,
  isSublink,
  isCurrent,
  app,
  subLinks,
  listItemsOpen,
  withBorderTop,
  isCollapsed,
  noStyle,
  withNotif,
}: NavigationMenuLabelProps) => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  return (
    <Badge dot={withNotif} offset={[-10, 10]}>
      <MenuItemLabelContainer
        $isCurrent={isCurrent}
        $isSublink={isSublink}
        $isCollapsed={isCollapsed}
        $withBorderTop={withBorderTop}
        $app={app}
        $noStyle={noStyle}
      >
        {isCollapsed && !isSublink ? (
          <div>{!!Icon && <Icon size={isSublink ? 16 : 20} />}</div>
        ) : (
          <>
            <Div flex="row" gap={isSublink ? 12 : 16} align="center">
              {!!Icon && (
                <Icon size={isSublink ? 16 : 18} style={{ flexShrink: 0 }} />
              )}

              <MenuItemLabelText $isSublink={isSublink}>
                {label}
              </MenuItemLabelText>
            </Div>
            {(isExternal || isNew) && (
              <Div flex="row" gap={8} fitContent>
                {isExternal && (
                  <NewWindow size={14} style={{ flexShrink: 0 }} />
                )}
                {isNew && (
                  <HdTag
                    color={
                      app === "provider"
                        ? theme.colors.purple_3
                        : theme.colors.purple_2
                    }
                    text={t("navigation|new")}
                    size="small"
                    withBorder
                  />
                )}
              </Div>
            )}
          </>
        )}
        {!!subLinks && !isCollapsed && !isTablet && !noStyle && (
          <TabArrow size={16} down={!listItemsOpen} />
        )}
      </MenuItemLabelContainer>
    </Badge>
  );
};

export default NavigationMenuLabel;

