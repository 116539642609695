import React, { CSSProperties, Suspense, lazy, useMemo } from "react";
import theme from "../../theme";

export type LogoProps = {
  primaryColor?: string;
  secondaryColor?: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
  onClick?: (...args: any) => void;
};

export type HdLogoProps = {
  type?: "primary" | "secondary" | "tertiary";
  color?: "b/w" | "colour";
  backgroundType: "light" | "dark";
  width?: number;
  height?: number;
  style?: CSSProperties;
  onClick?: (...args: any) => void;
};

const { white_1, grey_1, purple_2, purple_3 } = theme.colors;

const PrimaryLogo = lazy(() => import("./PrimaryLogo"));
const SecondaryLogo = lazy(() => import("./SecondaryLogo"));
const TertiaryLogo = lazy(() => import("./TertiaryLogo"));

const LogoComponent = ({
  type,
  ...logoProps
}: LogoProps & { type: HdLogoProps["type"] }) => {
  switch (type) {
    case "secondary":
      return <SecondaryLogo {...logoProps} />;
    case "tertiary":
      return <TertiaryLogo {...logoProps} />;
    case "primary":
    default:
      return <PrimaryLogo {...logoProps} />;
  }
};

const HdLogo = React.memo(
  ({
    type = "primary",
    color,
    backgroundType,
    width,
    height,
    style,
    onClick,
  }: HdLogoProps) => {
    const primaryColor = useMemo(
      () => (backgroundType === "dark" ? white_1 : grey_1),
      [backgroundType]
    );
    const secondaryColor = useMemo(
      () =>
        color === "b/w"
          ? backgroundType === "dark"
            ? white_1
            : grey_1
          : backgroundType === "dark"
            ? purple_3
            : purple_2,
      [color, backgroundType]
    );

    return (
      <Suspense fallback={null}>
        <LogoComponent
          type={type}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          width={width}
          height={height}
          onClick={onClick}
          style={style}
        />
      </Suspense>
    );
  }
);

export default HdLogo;
