import { Loader, LoaderOptions } from "@googlemaps/js-api-loader";

export class LoaderService {
  private static instance: LoaderService;
  private loader: Loader;

  private constructor(options: LoaderOptions) {
    this.loader = new Loader(options);
  }

  public getLoader(): Loader {
    return this.loader;
  }

  public static getInstance(options: LoaderOptions): LoaderService {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new LoaderService(options);
    return this.instance;
  }
}

