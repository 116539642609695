import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import {
  selectModal,
  toggleModal,
} from "../../features/api/slices/global-slice";
import PaymentModal from "../payment/payment-modal";
import ProjectIgnoreModalContainer from "./ignore-modal";
import RaiseHandPaymentModal from "./raise-hand-payment-modal";

const Modal = () => {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectModal);

  const onCancel = async () => {
    await dispatch(toggleModal({ isVisible: false, type: "" }));
  };

  switch (modal.type) {
    case "raiseHandPurchase":
      return (
        <RaiseHandPaymentModal open={modal.isVisible} onCancel={onCancel} />
      );
    case "leadPurchase":
      return <PaymentModal open={modal.isVisible} />;
    case "ignoreProject":
      return (
        <ProjectIgnoreModalContainer
          open={modal.isVisible}
          onCancel={onCancel}
        />
      );
    default:
      return <></>;
  }
};

export default Modal;
