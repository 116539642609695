export enum ErrorCode {
  default = 0,

  // billing
  billingNoPaymentMethod = 11_007,
  billingInsufficientFunds = 11_011,
  billingLostCard = 11_012,
  billingStolenCard = 11_013,
  billingProcessingError = 11_014,
  billingIncorrectCVC = 11_015,
  billingIncorrectZip = 11_016,
  billingGenericCardDeclined = 11_017,
  billingExpiredCard = 11_018,
  billingEmptyAddress = 11_019,
}

