import { Project } from "../entities/project-entities";
import ProjectStatus from "../enums/project-status";

const sortByStatus = (p1: Project, p2: Project) => {
  const map = new Map<ProjectStatus, number>([
    [ProjectStatus.PendingApproval, 1],
    [ProjectStatus.Active, 2],
    [ProjectStatus.EvaluateFinalists, 3],
    [ProjectStatus.FilterCandidates, 4],
    [ProjectStatus.Completed, 5],
    [ProjectStatus.Canceled, 6],
    [ProjectStatus.Rejected, 7],
  ]);
  const value1 = map.get(p1.status);
  const value2 = map.get(p2.status);

  if (!value1 || !value2) {
    return 0;
  }

  if (value1 < value2) {
    return -1;
  }
  if (value1 > value2) {
    return 1;
  }

  return 0;
};

export const sortByService = (p1: Project, p2: Project) => {
  if (!p1?.service || !p2?.service) return 0;
  return p1.service.localeCompare(p2?.service);
};

export const sortByCreatedDate = (p1: Project, p2: Project) => {
  if (!p1?.created_at || !p2?.created_at) return 0;
  return p2.created_at > p1.created_at ? 1 : -1;
};
export const sortByUpdatedDate = (p1: Project, p2: Project) => {
  if (!p1?.updated_at || !p2?.updated_at) return 0;
  return p2.updated_at > p1.updated_at ? 1 : -1;
};

const filterProjects = (
  projects: Project[],
  sortType: string,
  orderType: string
) => {
  let filtered = projects;
  switch (sortType) {
    case "status":
      filtered = projects.sort(sortByStatus);
      break;
    case "service":
      filtered = projects.sort(sortByService);
      break;
    case "recent":
      filtered = projects.sort(sortByCreatedDate);
      break;
  }

  switch (orderType) {
    case "ascending":
      break;
    case "descending":
      filtered = filtered.reverse();
      break;
  }

  return filtered;
};

export default filterProjects;
