import { CSSProperties } from "react";

export interface IconParams {
  top?: number;
  width?: number;
  height?: number;
  rotate?: number;
  margin?: number | string;
  color?: string;
  desktopOnly?: boolean;
  inversed?: boolean;
  side: "left" | "right";
  name: ContainerIcons;
}

export interface IconsParams {
  icons: IconParams[];
  backgroundColor?: string;
}

export enum ContainerIcons {
  Group1Filled = "Group1Filled",
  Group1Outlined = "Group1Outlined",
  Group2Outlined = "Group2Outlined",
  Group3Outlined = "Group3Outlined",
  Group4Filled = "Group4Filled",
  Group4Outlined = "Group4Outlined",
  Other1Outlined = "Other1Outlined",
  Other2Filled = "Other2Filled",
  Other3Filled = "Filled1J",
  Other4Filled = "Other4Filled",
  Other5Filled = "Other5Filled",
}
export type SpotIconProps = {
  style?: CSSProperties;
  className?: string;
  width?: number | string;
  height?: number | string;
};
