import { Asset } from "contentful";
import { ImageDataLike } from "gatsby-plugin-image";

export interface ContentfulComponent {
  __typename?: string;
  id?: string;
  contentful_id?: string;
  node_locale?: string;
  sys?: {
    id: string;
  };
}

type RichText = { raw: string };

export enum ResourceCategory {
  All = "all",
  Article = "article",
  Guide = "guide",
  Podcast = "podcast",
  Webinar = "webinar",
  Video = "video",
}

interface DefaultAsset extends ContentfulComponent {
  gatsbyImageData: ImageDataLike;
  id: string;
  url: string;
  width: number;
  height: number;
}
export type ContentfulAsset = DefaultAsset & Asset["fields"];

export type BackgroundColor =
  | "transparent"
  | "yellow_1"
  | "yellow_2"
  | "yellow_3"
  | "yellow_4"
  | "grey_1"
  | "grey_2"
  | "grey_3"
  | "grey_4"
  | "grey_5"
  | "beige_1"
  | "beige_2"
  | "beige_3"
  | "beige_4"
  | "white_1"
  | "purple_1"
  | "purple_2"
  | "purple_3"
  | "purple_4"
  | "purple_5"
  | "green_1"
  | "green_2"
  | "green_3"
  | "green_4";

export interface LinkEntity extends ContentfulComponent {
  name: string;
  icon?: ContentfulAsset;
  externalLink?: string;
  action?: "none" | "openChat";
  openInNewTab?: boolean;
  size?: "small" | "medium" | "large";
  withArrow?: boolean;
  theme?: "link" | BackgroundColor;
}

type IconSize = "Small" | "Medium" | "Large";
export interface IconEntity extends ContentfulComponent {
  icon?: ContentfulAsset;
  background?: ContentfulAsset;
  backgroundColor: BackgroundColor;
  rotation: number;
  inversedX: boolean;
  inversedY: boolean;
  size: IconSize;
}

export interface TitleImageBodyEntity extends ContentfulComponent {
  image?: ContentfulAsset;
  icon?: IconEntity;
  overline?: string;
  title?: string;
  richTitle?: { raw: string };
  body?: string;
  bodyLong?: RichText;
  backgroundColor?: BackgroundColor;
  flexSize?: number;
  CTAs?: LinkEntity[];
}

export interface ContentfulFAQ extends ContentfulComponent {
  items: TitleImageBodyEntity[];
  title?: string;
  body?: string;
  cta?: LinkEntity;
}
export interface ContentfulResource extends ContentfulComponent {
  title: string;
  type: string;
  excerpt: string;
  content: {
    json: any;
    links: {
      assets: { block: ContentfulAsset[] };
    };
  };
  thumbnailImage: ContentfulAsset;
  bannerImage: ContentfulAsset;
  date: string;
  scribeEmbed: string;
  linkToResource: string;
  slug: string;
  category: ResourceCategory;
  tags: string[];
}

export interface JsonResource {
  title: string;
  description: string;
  type: string;
  link: string;
  image: string;
  category: ResourceCategory;
  slug: string;
}

