import styled from "../../../../plugins/styled";

export const ProfileFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ProfileFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 56px;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    & > div {
      flex: auto !important;
      width: 100%;
    }
  }
`;

export const TabletSaveContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 24px 54px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey_5};
  border-left: 1px solid ${({ theme }) => theme.colors.grey_5};
  border-right: 1px solid ${({ theme }) => theme.colors.grey_5};
  background: ${({ theme }) => theme.colors.white_1}bb;
  backdrop-filter: blur(2px);
  z-index: 1000;
  border-box:;
`;

