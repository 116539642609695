import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Language = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 30.2222C19.1526 30.222 22.2158 29.1748 24.7088 27.245C27.2017 25.3153 28.9832 22.6122 29.7734 19.5603M16 30.2222C12.8474 30.222 9.78416 29.1748 7.29122 27.245C4.79827 25.3153 3.01682 22.6122 2.22657 19.5603M16 30.2222C19.9269 30.2222 23.1111 23.8538 23.1111 16C23.1111 8.14616 19.9269 1.77777 16 1.77777M16 30.2222C12.0731 30.2222 8.88889 23.8538 8.88889 16C8.88889 8.14616 12.0731 1.77777 16 1.77777M29.7734 19.5603C30.0658 18.4225 30.2222 17.2294 30.2222 16C30.2261 13.5539 29.5962 11.1486 28.3939 9.01846M29.7734 19.5603C25.5592 21.8965 20.8185 23.1186 16 23.1111C11.0033 23.1111 6.30835 21.8232 2.22657 19.5603M2.22657 19.5603C1.92751 18.3972 1.77671 17.2009 1.77778 16C1.77778 13.4637 2.44149 11.0807 3.60613 9.01846M16 1.77777C18.5224 1.77672 20.9998 2.44678 23.1778 3.7192C25.3558 4.99163 27.156 6.8206 28.3939 9.01846M16 1.77777C13.4775 1.77672 11.0002 2.44678 8.82222 3.7192C6.64421 4.99163 4.84394 6.8206 3.60613 9.01846M28.3939 9.01846C24.9532 11.9987 20.5519 13.6362 16 13.6296C11.2624 13.6296 6.92938 11.8913 3.60613 9.01846"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Language;

