import { useCallback, useRef } from "react";

const useDebounceFunction = <T extends (...args: any[]) => void>(
  callback: T,
  delay: number
): T => {
  const isDebouncing = useRef(false);

  return useCallback(
    (...args: Parameters<T>) => {
      // Check if debouncing is already in progress
      if (!isDebouncing.current) {
        isDebouncing.current = true; // Set debouncing to true
        setTimeout(() => {
          callback(...args); // Call the provided function with arguments
          isDebouncing.current = false; // Reset debouncing state after delay
        }, delay);
      }
    },
    [callback, delay]
  ) as T; // Ensure dependencies include callback and delay
};

export default useDebounceFunction;

