import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Typography from "../../../common/typography";
import ProfileSection from "../profile-section";

type ProviderMessageProps = {
  message: string;
};
const ProviderMessage = ({ message }: ProviderMessageProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <ProfileSection
      title={`👋🏻 ${t("profile_sections|message")}`}
      id="message"
      hide={!message.length}
    >
      <Typography elementTheme="body1" color={theme.colors.grey_4}>
        {message}
      </Typography>
    </ProfileSection>
  );
};

export default ProviderMessage;

