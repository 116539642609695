export const companySize = [
  { label: "1", value: "1" },
  { label: "2-10", value: "2-10" },
  { label: "11-25", value: "11-25" },
  { label: "26-50", value: "26-50" },
  { label: "51-100", value: "51-100" },
  { label: "101-300", value: "101-300" },
  { label: "300+", value: "300+" },
];
export const annualRevenue = [
  { label: "500K or less", value: "500k" },
  { label: "500K-1M", value: "1m" },
  { label: "1M-2M", value: "2m" },
  { label: "2M-5M", value: "5m" },
  { label: "5M-10M", value: "10m" },
  { label: "11M-25M", value: "25m" },
  { label: "25M-50M", value: "50m" },
  { label: "50M-100M", value: "100m" },
  { label: "100M+", value: "100m+" },
];
