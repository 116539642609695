import React, { PropsWithChildren, useContext } from "react";
import { AppType } from "../entities";

export interface PlatformContextType {
  app: AppType;
}

export const PlatformContext = React.createContext<PlatformContextType>({
  app: "client",
});

interface PlatformProviderProps extends PropsWithChildren {
  app: AppType;
}

const PlatformProvider = ({ children, app }: PlatformProviderProps) => {
  return (
    <PlatformContext.Provider
      value={{
        app,
      }}
    >
      {children}
    </PlatformContext.Provider>
  );
};

export const usePlatform = () => useContext(PlatformContext);

export default PlatformProvider;

