import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import AddressAutocompleteInput from "@hellodarwin/core/lib/components/forms/form-groups/adress-autocomplete-input";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import FormLabel from "@hellodarwin/core/lib/components/forms/form-layouts/form-label";
import validateWebsite from "@hellodarwin/core/lib/components/forms/utils/validate-website";
import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { FormInstance } from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import { PartnerFormValues } from "../../../../features/entities/form-entities";
type OnboardingBusinessFormFieldsProps = {
  isLoading?: boolean;
  form: FormInstance<PartnerFormValues>;
};
const OnboardingBusinessFormFields = ({
  isLoading,
  form,
}: OnboardingBusinessFormFieldsProps) => {
  const { t } = useTranslation();
  const { getFullLanguage } = useTranslations();

  return (
    <Container>
      <Typography.Title noMargin level={5} elementTheme="h6">
        {t("profile|profileForm.company.title")}
      </Typography.Title>
      <Div flex="row" gap={16} tablet={{ flex: "column" }}>
        <FormItem
          name="company_website"
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.website")}
            />
          }
          rules={[
            {
              required: true,
              validator: validateWebsite({
                message: t("profile|profileForm.company.validation.website"),
                isRequired: true,
              }),
            },
          ]}
          style={{ flex: 1 }}
        >
          <Input
            size={"large"}
            placeholder={t("profile|profileForm.company.placeholder.website")}
            disabled={isLoading}
          />
        </FormItem>
      </Div>
      <Div flex="row" gap={16} tablet={{ flex: "column" }}>
        <FormItem
          name="company_email"
          label={
            <FormLabel label={t("profile|profileForm.company.labels.email")} />
          }
          rules={[
            {
              required: true,
              type: "email",
              message: t("profile|profileForm.company.validation.email"),
            },
          ]}
          style={{ flex: 1 }}
        >
          <Input
            size={"large"}
            placeholder={t("profile|profileForm.company.placeholder.email")}
            disabled={isLoading}
          />
        </FormItem>
        <FormItem
          name="project_language"
          label={
            <FormLabel
              label={t("profile|profileForm.company.labels.projectLanguage")}
            />
          }
          rules={[
            {
              required: true,
              message: t(
                "profile|profileForm.company.validation.projectLanguage"
              ),
            },
          ]}
          style={{ flex: 1 }}
        >
          <Select
            size={"large"}
            placeholder={t(
              "profile|profileForm.company.placeholder.projectLanguage"
            )}
            disabled={isLoading}
          >
            <Select.Option value={"en"}>{getFullLanguage("en")}</Select.Option>
            <Select.Option value={"fr"}>{getFullLanguage("fr")}</Select.Option>
            <Select.Option value={"en:fr"}>
              {getFullLanguage("en:fr")}
            </Select.Option>
          </Select>
        </FormItem>
      </Div>
      <Div flex="row" gap={16} tablet={{ flex: "column" }}>
        <AddressAutocompleteInput
          form={form}
          required={true}
          showManualAddress
          provinceRequired={true}
        />
      </Div>
    </Container>
  );
};

export default OnboardingBusinessFormFields;

