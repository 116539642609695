import LinkedinLogo from "@hellodarwin/icons/dist/icons/LinkedinLogo";
import { TeamMember } from "../../../../../features/entities/core-entities";
import prefixHTTPS from "../../../../../features/helpers/prefix-https";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import { ProfilePicture } from "./styles";

type TeamMemberPreviewProps = {
  teamMember: TeamMember;
  name: string;
};

const TeamMemberPreview = ({ teamMember, name }: TeamMemberPreviewProps) => {
  const theme = useTheme();

  return (
    !!teamMember && (
      <Div className="team-member-preview">
        <Div flex={"column"} justify={"center"} align="center" gap={16}>
          {teamMember.profile_picture && (
            <ProfilePicture flex={"column"} align={"center"} justify={"center"}>
              <img
                src={teamMember.profile_picture}
                alt={`${teamMember.name} profile`}
              />
            </ProfilePicture>
          )}
          <Div flex="column" justify="center" align="center">
            <Typography elementTheme="body2" bold>
              {teamMember.name}
            </Typography>
            <Typography elementTheme="caption" color={theme.colors.grey_4}>
              {teamMember.role} at {name}
            </Typography>
          </Div>
          <Div fitContent>
            {!!teamMember.linked_in && (
              <a
                href={prefixHTTPS(teamMember.linked_in, "team_member_preview")}
                target="__blank"
              >
                <LinkedinLogo
                  width={24}
                  height={24}
                  style={{
                    color: "#007BB5",
                    cursor: "pointer",
                  }}
                />
              </a>
            )}
          </Div>
        </Div>
      </Div>
    )
  );
};

export default TeamMemberPreview;

