import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const RecommendYes = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.17121 14C9.34691 14 10.4074 13.3393 11.1338 12.4C12.2661 10.9328 13.6875 9.72175 15.3071 8.84444C16.3617 8.27555 17.2763 7.42814 17.7183 6.3037C18.0287 5.51592 18.1882 4.67518 18.188 3.82666V2.88888C18.188 2.5942 18.3033 2.31158 18.5084 2.10321C18.7136 1.89483 18.9919 1.77777 19.282 1.77777C20.1525 1.77777 20.9873 2.12896 21.6028 2.75408C22.2183 3.3792 22.5641 4.22705 22.5641 5.1111C22.5641 6.81777 22.1848 8.43407 21.5095 9.87851C21.1214 10.7052 21.6655 11.7778 22.567 11.7778H27.1269C28.6235 11.7778 29.964 12.8059 30.123 14.3185C30.1886 14.9437 30.2222 15.5778 30.2222 16.2222C30.2282 20.2761 28.8642 24.2092 26.3581 27.3644C25.7922 28.0785 24.9184 28.4444 24.0169 28.4444H18.1588C17.4543 28.4444 16.7527 28.3289 16.0831 28.1037L11.5408 26.563C10.8714 26.3369 10.1705 26.2218 9.46506 26.2222H7.10782M7.10782 26.2222C7.22889 26.5259 7.36018 26.8222 7.50167 27.1141C7.78903 27.7067 7.38789 28.4444 6.73878 28.4444H5.41429C4.11751 28.4444 2.91555 27.677 2.53775 26.4178C2.03253 24.7337 1.77641 22.9827 1.77778 21.2222C1.77778 18.9215 2.20809 16.7244 2.98995 14.7067C3.43631 13.56 4.57408 12.8889 5.78917 12.8889H7.32517C8.01367 12.8889 8.41189 13.7126 8.05451 14.3111C6.80863 16.394 6.15154 18.7851 6.1553 21.2222C6.1553 22.9911 6.49372 24.6785 7.10928 26.2222H7.10782ZM19.282 11.7778H22.5641"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RecommendYes;

