import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { auth0Reducer } from "../auth0";
import { creditsReducer } from "../features/api/slices/credits-slice";
import { dashboardReducer } from "../features/api/slices/dashboard-slice";
import { globalReducer } from "../features/api/slices/global-slice";
import { matchesReducer } from "../features/api/slices/matches-slice";
import { profileReducer } from "../features/api/slices/profile-slice";
import { projectsReducer } from "../features/api/slices/projects-slice";
import { reviewsReducer } from "../features/api/slices/reviews-slice";
import { tagsReducer } from "../features/api/slices/tags-slice";

export const store = configureStore({
  reducer: {
    auth0: auth0Reducer,
    global: globalReducer,
    profile: profileReducer,
    matches: matchesReducer,
    score: dashboardReducer,
    projects: projectsReducer,
    reviews: reviewsReducer,
    credits: creditsReducer,
    tags: tagsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
