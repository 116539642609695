import {
  OnBoardingSteps,
  useOnBoarding,
} from "@hellodarwin/core/lib/context/onboarding-provider";
import { ServiceTag } from "@hellodarwin/core/lib/features/entities/tags-entities";
import theme from "@hellodarwin/core/lib/theme";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  createTags,
  fetchProfile,
  fetchTags,
  onboard,
  onboardingMatch,
  selectTags,
} from "../../features/api/slices/profile-slice";
import { usePartnerApi } from "../../features/api/use-partner-api";
import PartnerCategoryPage from "./components/partner-category-page";
import PartnerOnBoardingProfilePage from "./components/partner-onboarding-profile-page";
import PartnerServicePage from "./components/partner-service-page";
import PartnerSpecialtyPage from "./components/partner-specialty-page";

const PartnerOnBoardingPage = () => {
  const { currentStep } = useOnBoarding();

  const api = usePartnerApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectedCategories } = useAppSelector(selectTags);
  const tags = useAppSelector(selectTags);

  useEffect(() => {
    dispatch(fetchTags({ api }));
  }, [dispatch, api]);

  const handleSave = async (
    categories: string[],
    services: ServiceTag[],
    specialties: string[]
  ) => {
    await dispatch(
      createTags({
        api: api,
        serviceTags: services,
        specialtyTags: specialties,
      })
    );
    await dispatch(onboard({ api }));
    await dispatch(onboardingMatch({ api }));
    await dispatch(fetchProfile({ api }));
    navigate("/");
  };

  const Content = useMemo(() => {
    switch (currentStep) {
      case OnBoardingSteps.Services:
        return <PartnerServicePage selectedTags={tags} />;
      case OnBoardingSteps.Specialties:
        return (
          <PartnerSpecialtyPage
            categories={selectedCategories.map((category) => category.label)}
            onSave={handleSave}
          />
        );
      case OnBoardingSteps.Category:
        return <PartnerCategoryPage />;
      case OnBoardingSteps.CompanyName:
      case OnBoardingSteps.Company:
      case OnBoardingSteps.Contact:
        return <PartnerOnBoardingProfilePage />;
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, selectedCategories, tags]);

  return (
    <div
      style={{
        maxWidth: theme.breakpoints.xl,
        width: "100%",
        margin: "0 auto",
      }}
    >
      {Content}
    </div>
  );
};

export default PartnerOnBoardingPage;

