import Tabs from "antd/es/tabs";
import styled from "../../../plugins/styled";
import Typography from "../typography";

export const TabsContainer = styled(Tabs)``;

export const TabsBarContainer = styled.div<{
  $underlineWidth: number;

  $underlineOffsetX: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 38px;
  position: relative;
  overflow-y: visible;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grey_4};
  }
  &::after {
    content: "";
    width: ${({ $underlineWidth }) => $underlineWidth}px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.green_1};
    position: absolute;
    bottom: 0;
    left: ${({ $underlineOffsetX }) => $underlineOffsetX}px;
    transition:
      left 0.5s,
      width 0.5s;
  }
`;

export const TabBarItem = styled(Typography)`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.purple_1};
  }
`;

