import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Match } from "@hellodarwin/core/lib/features/entities/matches-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Checkbox from "antd/es/checkbox/Checkbox";
import { Dispatch, SetStateAction } from "react";
import { useAppSelector } from "../../../../app/app-hooks";
import { selectMatchesLoading } from "../../../../features/api/slices/matches-slice";

interface PaymentFormFooterProps {
  match: Match;
  waiveFee: boolean;
  availableRaisehands: number;
  onCancel: () => void;
  handleFormConfirm: () => void;
  setWaiveFee: Dispatch<SetStateAction<boolean>>;
}

const PaymentFormFooter = ({
  match,
  waiveFee,
  availableRaisehands,
  setWaiveFee,
  onCancel,
  handleFormConfirm,
}: PaymentFormFooterProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isLoading = useAppSelector(selectMatchesLoading);

  const handleWaiveFee = () => {
    setWaiveFee((prevWaiveFee) => !prevWaiveFee);
  };

  return (
    <Div
      flex={"row"}
      gap={16}
      justify={"space-between"}
      style={{ marginTop: "2rem" }}
      align="center"
    >
      {availableRaisehands > 0 && (
        <Div fitContent>
          <label>
            <Div
              flex="row"
              align="center"
              gap={8}
              className="waive-button"
              style={{ cursor: "pointer" }}
            >
              <Checkbox
                onClick={handleWaiveFee}
                checked={waiveFee}
                className="hd-checkbox"
              />

              <Typography elementTheme="body2">
                {availableRaisehands <= 1
                  ? t("modal|raiseHand.paymentForm.freeRaiseHand", {
                      count: availableRaisehands,
                    })
                  : t("modal|raiseHand.paymentForm.freeRaiseHandPlural", {
                      count: availableRaisehands,
                    })}
              </Typography>
            </Div>
          </label>
        </Div>
      )}
      <Div
        flex={"row"}
        tablet={{ flex: "column-reverse" }}
        gap={16}
        align="center"
        justify="flex-end"
      >
        <Button
          defaultStyle={theme.colors.white_1}
          onClick={onCancel}
          size="small"
        >
          {t("common|button.cancel")}
        </Button>
        <Button
          defaultStyle={theme.colors.green_1}
          onClick={handleFormConfirm}
          loading={isLoading}
          size="small"
          headingIcon={"👋"}
        >
          {t("modal|raiseHand.paymentForm.raiseHand", {
            raiseHandPrice:
              waiveFee || match.source === "Directory"
                ? 0
                : match.project.raise_hand_price || 0,
          })}
        </Button>
      </Div>
    </Div>
  );
};

export default PaymentFormFooter;

