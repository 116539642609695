import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import ModalLayoutWithHdLogo from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-logo";
import ModalWarning from "@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-warning";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  Match,
  PurchasedProject,
} from "@hellodarwin/core/lib/features/entities";
import { ErrorCode } from "@hellodarwin/core/lib/features/entities/errors";
import ProjectType from "@hellodarwin/core/lib/features/enums/project-type";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { TFunction } from "@hellodarwin/core/lib/plugins/i18n/utils";
import theme from "@hellodarwin/core/lib/theme";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import { unwrapResult } from "@reduxjs/toolkit";
import { Elements } from "@stripe/react-stripe-js";
import { Token } from "@stripe/stripe-js";
import Divider from "antd/es/divider";
import { useEffect, useState } from "react";
import { stripePromise } from "../../PartnerApp";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import PartnerApiClient from "../../features/api/partner-api-client";
import { fetchCreditBalance } from "../../features/api/slices/credits-slice";
import { toggleModal } from "../../features/api/slices/global-slice";
import {
  fetchAvailableMatches,
  purchaseProject,
  selectSelectedMatch,
} from "../../features/api/slices/matches-slice";
import { usePartnerApi } from "../../features/api/use-partner-api";
import showErrorNotification from "../../features/helpers/show-error-notifications";
import PaymentForm from "./payment-form";
const PaymentConfirmModal = ({
  availableProject,
  handleCancel,
  handlePurchase,
  isLoading,
  t,
}: {
  availableProject: Match;
  handleCancel: () => void;
  handlePurchase: (token: Token | undefined) => void;
  isLoading: boolean;
  t: TFunction;
}) => (
  <Div
    className={"payment-modal-container"}
    flex={"column"}
    justify={"center"}
    align={"center"}
  >
    <Div
      flex={"column"}
      justify={"center"}
      align={"center"}
      gap={24}
      style={{ marginTop: 20 }}
    >
      <Typography.Title level={4} noMargin textAlign="center">
        {t("payment|paymentModal.confirm.title")}
      </Typography.Title>
      <Typography textAlign="center">
        {t("payment|paymentModal.confirm.description")}
      </Typography>
      <Divider />
    </Div>
    <Div
      className="payment-modal-content-container"
      flex={"column"}
      justify={"center"}
      align={"center"}
      gap={24}
      style={{ marginTop: 32 }}
    >
      <Typography elementTheme="subtitle2">
        {t("payment|paymentModal.confirm.invoice", {
          price:
            (availableProject?.lead_price || 0) -
            (availableProject?.discounted_amount || 0),
          id: getShortId(availableProject?.project_id),
        })}
      </Typography>
      <Div flex="column" align="center">
        <Button
          defaultStyle={theme.colors.purple_2}
          onClick={() => handlePurchase(undefined)}
          loading={isLoading}
        >
          {t("button.confirm")}
        </Button>
        <Button isLink onClick={handleCancel}>
          {t("button.cancel")}
        </Button>
      </Div>
      <Typography elementTheme="caption" color={theme.colors.grey_2}>
        {t("payment|paymentModal.text.creditBalanceApplicable")}
      </Typography>
    </Div>
  </Div>
);

const PaymentFormModal = ({
  price,
  handlePurchase,
  t,
}: {
  price: number;
  handlePurchase: (token: Token | undefined) => void;
  t: TFunction;
}) => (
  <Div
    className={"payment-modal-container"}
    flex={"column"}
    justify={"center"}
    align={"center"}
    style={{ marginTop: 20 }}
    gap={32}
  >
    <Div flex={"column"} justify={"center"} align={"center"}>
      <Typography.Title level={4}>
        {t("payment|paymentModal.form.title")}
      </Typography.Title>
      <Typography>{t("payment|paymentModal.form.description")}</Typography>
      <Divider />
    </Div>
    <Div
      className="payment-modal-content-container"
      flex={"column"}
      justify={"center"}
      align={"center"}
      gap={16}
    >
      <Elements stripe={stripePromise}>
        <PaymentForm price={price} handlePurchase={handlePurchase} />
      </Elements>
    </Div>
  </Div>
);

const PaymentSuccessModal = ({
  project,
  handleCancel,
  t,
  api,
}: {
  project: PurchasedProject;
  handleCancel: () => void;
  t: TFunction;
  api: PartnerApiClient;
}) => {
  const fetchInvoice = () => {
    (async () => {
      try {
        const invoice =
          project.type === ProjectType.Classic
            ? await api.fetchInvoice(project.match_id)
            : await api.fetchRaisehandInvoice(project.match_id);

        window.open(invoice.HostedURL, "_blank");
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
      }
    })();
  };

  return (
    <Div
      className={"payment-modal-container fade-in"}
      flex={"column"}
      justify={"center"}
      align={"center"}
      gap={32}
    >
      <Div flex={"column"} justify={"center"} align={"center"} gap={24}>
        <Typography.Title level={4} noMargin>
          {t("payment|paymentModal.success.title")}
        </Typography.Title>
        <Typography textAlign="center">
          {t("payment|paymentModal.success.description")}
        </Typography>
        <Divider />
      </Div>
      <Div
        flex={"column"}
        justify={"space-between"}
        align={"center"}
        gap={24}
        className="payment-modal-content-container"
      >
        <Typography elementTheme="subtitle2">
          {t("payment|paymentModal.success.invoice.description", {
            id: " " + getShortId(project.project_id),
          })}
        </Typography>

        <Div flex={"column"} align="center">
          <Button
            onClick={fetchInvoice}
            defaultStyle={theme.colors.green_1}
            trailingIcon={<NewWindow width={16} height={16} />}
          >
            {t("project|projectPage.viewInvoice")}
          </Button>
          <Button style={{ marginLeft: "1rem" }} isLink onClick={handleCancel}>
            {t("button.close")}
          </Button>
        </Div>
      </Div>
    </Div>
  );
};

const PaymentErrorModal = ({
  description,
  api,
  t,
}: {
  description: string;
  api: PartnerApiClient;
  t: TFunction;
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchAvailableMatches(api));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSession = () => {
    (async () => {
      try {
        const session = await api.fetchBillingSession();
        window.location.href = session.url;
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
      }
    })();
  };

  return (
    <Div flex={"column"} align={"center"} gap={16}>
      <Typography center color={theme.colors.grey_2}>
        {description}
      </Typography>
      <Button
        defaultStyle={theme.colors.purple_1}
        size={"medium"}
        onClick={openSession}
      >
        {t("profile|profileForm.manageBilling")}
      </Button>
    </Div>
  );
};

type modalSteps = "confirm" | "form" | "success" | "error";

const PaymentModal = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const match = useAppSelector(selectSelectedMatch);
  const [isLoading, setIsLoading] = useState(false);
  const [modalStep, setModalStep] = useState<modalSteps>("confirm");
  const [errorCode, setErrorCode] = useState<number>(-1);
  const [purchasedProject, setPurchasedProject] = useState<PurchasedProject>(
    {} as PurchasedProject
  );
  const status = useAppSelector((state) => state.global.status);

  const handlePurchase = (token: Token | undefined) => {
    setIsLoading(true);

    dispatch(
      purchaseProject({
        api,
        projectId: match.project_id,
        matchId: match.match_id,
        token,
      })
    )
      .then(unwrapResult)
      .then((payload: PurchasedProject) => {
        handleSuccess(payload);
      })
      .catch((err: any) => {
        handleError(err.response.data.error_code);
      });
  };

  useEffect(() => {
    if (status !== "pending") setIsLoading(false);
  }, [status]);

  const handleSuccess = (purchasedProject: PurchasedProject) => {
    setPurchasedProject(purchasedProject);
    setModalStep("success");
    dispatch(fetchCreditBalance({ api }));
  };

  const handleError = (code: number) => {
    switch (code) {
      case ErrorCode.billingNoPaymentMethod:
        setModalStep("form");
        return;
      case ErrorCode.billingIncorrectCVC:
      case ErrorCode.billingIncorrectZip:
      case ErrorCode.billingGenericCardDeclined:
      case ErrorCode.billingExpiredCard:
      case ErrorCode.billingEmptyAddress:
      case ErrorCode.billingInsufficientFunds:
      case ErrorCode.billingStolenCard:
      case ErrorCode.billingProcessingError:
        setErrorCode(code);
        setModalStep("error");
        return;
    }

    setErrorCode(code);
    setModalStep("error");
  };

  const finalPrice =
    (match.project?.lead_price || 0) - (match.project?.discounted_amount || 0);

  const onTogglePaymentModal = async () => {
    await dispatch(toggleModal({ isVisible: false, type: "" }));
  };

  const getModalStep = () => {
    switch (modalStep) {
      case "form":
        return (
          <PaymentFormModal
            price={finalPrice}
            handlePurchase={handlePurchase}
            t={t}
          />
        );
      case "success":
        return (
          <PaymentSuccessModal
            project={purchasedProject}
            handleCancel={onTogglePaymentModal}
            t={t}
            api={api}
          />
        );
      default:
        return (
          <PaymentConfirmModal
            availableProject={match}
            handleCancel={onTogglePaymentModal}
            handlePurchase={handlePurchase}
            isLoading={isLoading}
            t={t}
          />
        );
    }
  };

  return (
    <>
      {modalStep === "error" && (
        <ModalWarning open={open} handleCancel={onTogglePaymentModal}>
          <>
            <Typography.Title level={2} elementTheme="h4">
              {t(`errors|${errorCode}.message`)}
            </Typography.Title>
            <PaymentErrorModal
              description={t(`errors|${errorCode}.description`)}
              api={api}
              t={t}
            />
          </>
        </ModalWarning>
      )}
      {modalStep !== "error" && (
        <ModalLayoutWithHdLogo open={open} handleCancel={onTogglePaymentModal}>
          {getModalStep()}
        </ModalLayoutWithHdLogo>
      )}
    </>
  );
};

export default PaymentModal;

