import {
  CategoryTag,
  CategoryTagEntity,
  ServiceTag,
  SpecialtyTag,
  Tags,
} from "../entities/tags-entities";

export enum TagSubType {
  Service = "services",
  Specialty = "specialties",
}

export enum TagType {
  Category = "categories",
}

const getTagCategory = (service: string) => {
  return service?.split(":")?.[1];
};

export default class TagAdapter {
  setAll = (tags: Tags) => {
    const mappedTags = {} as { [key: string]: CategoryTagEntity };
    for (let tag of tags.Tags) {
      const [type, category, subType, value] = tag.tag.split(":");

      if (type !== TagType.Category) {
        continue;
      }

      if (mappedTags[category] === undefined) {
        mappedTags[category] = {} as CategoryTagEntity;
        mappedTags[category].tag = category;
        mappedTags[category].label = category;
        mappedTags[category].isSelected = false;
        mappedTags[category].services = {} as { [key: string]: ServiceTag };
        mappedTags[category].specialties = {} as {
          [key: string]: SpecialtyTag;
        };
      }

      if (subType === TagSubType.Service) {
        const service: ServiceTag = {
          category: category,
          tag: tag.tag,
          label: value,
          isSelected: false,
          min_budget: 0,
          max_budget: 0,
          description: "",
        };
        mappedTags[category].services[tag.tag] = service;
      }

      if (subType === TagSubType.Specialty) {
        const speciality: SpecialtyTag = {
          tag: tag.tag,
          label: value,
          isSelected: false,
        };
        mappedTags[category].specialties[tag.tag] = speciality;
      }
    }

    for (let tag of Object.values(tags.PartnerTags)) {
      mappedTags[tag.category].isSelected = true;
      if (tag.services) {
        for (let service of tag.services) {
          mappedTags[tag.category].services[service.tag].min_budget =
            service.min_budget;
          mappedTags[tag.category].services[service.tag].max_budget =
            service.max_budget;
          mappedTags[tag.category].services[service.tag].description =
            service.description;
          mappedTags[tag.category].services[service.tag].isSelected = true;
        }
      }

      if (tag.specialties) {
        for (let speciality of tag.specialties) {
          mappedTags[tag.category].specialties[speciality].isSelected = true;
        }
      }
    }

    return mappedTags;
  };

  toggleCategoryTag = (
    categoryTagEntities: { [key: string]: CategoryTagEntity },
    category: string
  ) => {
    Object.values(categoryTagEntities[category].services).forEach((service) => {
      service.isSelected = false;
    });

    Object.values(categoryTagEntities[category].specialties).forEach(
      (specialty) => {
        specialty.isSelected = false;
      }
    );

    categoryTagEntities[category].isSelected =
      !categoryTagEntities[category].isSelected;

    return categoryTagEntities;
  };

  toggleServiceTag = (
    categoryTagEntities: { [key: string]: CategoryTagEntity },
    service: string
  ) => {
    const category = getTagCategory(service);

    categoryTagEntities[category].services[service].isSelected =
      !categoryTagEntities[category].services[service].isSelected;
    categoryTagEntities[category].services[service].min_budget = 0;
    categoryTagEntities[category].services[service].max_budget = 0;

    return categoryTagEntities;
  };

  handleServiceChange = (
    categoryTagEntities: { [key: string]: CategoryTagEntity },
    service: string,
    minBudget: number,
    maxBudget: number,
    description?: string
  ) => {
    const category = getTagCategory(service);
    categoryTagEntities[category].services[service].min_budget = minBudget;
    categoryTagEntities[category].services[service].max_budget = maxBudget;
    categoryTagEntities[category].services[service].description = description;

    return categoryTagEntities;
  };

  toggleSpecialtyTag = (
    categoryTagEntities: { [key: string]: CategoryTagEntity },
    specialty: string
  ) => {
    const category = getTagCategory(specialty);

    categoryTagEntities[category].specialties[specialty].isSelected =
      !categoryTagEntities[category].specialties[specialty].isSelected;

    return categoryTagEntities;
  };

  selectTags = (categoryTagEntities: { [key: string]: CategoryTagEntity }) => {
    const categories: { label: string; isSelected: boolean }[] = [];
    const selectedCategories: CategoryTag[] = [];

    Object.values(categoryTagEntities).forEach((category) => {
      if (category.isSelected) {
        selectedCategories.push({
          label: category.label,
          tag: category.tag,
          isSelected: category.isSelected,
          services: Object.values(category.services),
          specialties: Object.values(category.specialties),
        });
      }

      categories.push({
        label: category.label,
        isSelected: category.isSelected,
      });
    });

    if (categories.length === 0) {
      return {
        categories: [] as { label: string; isSelected: boolean }[],
        selectedServices: [] as ServiceTag[],
        selectedSpecialties: [] as SpecialtyTag[],
        selectedCategories: [] as CategoryTag[],
      };
    }

    const selectedServices: ServiceTag[] = [];
    const selectedSpecialties: SpecialtyTag[] = [];

    for (const category of categories) {
      Object.values(categoryTagEntities[category.label].services).forEach(
        (tag) => {
          const categorizedTag = {
            category: category.label,
            tag: tag.tag,
            label: tag.label,
            isSelected: tag.isSelected,
            min_budget: tag.min_budget,
            max_budget: tag.max_budget,
            description: tag.description,
          };

          if (categorizedTag.isSelected) {
            selectedServices.push(categorizedTag);
          }
        }
      );

      Object.values(categoryTagEntities[category.label].specialties).forEach(
        (tag) => {
          const categorizedTag = {
            category: category.label,
            tag: tag.tag,
            label: tag.label,
            isSelected: tag.isSelected,
          };

          if (categorizedTag.isSelected) {
            selectedSpecialties.push(categorizedTag);
          }
        }
      );
    }

    return {
      categories,
      selectedServices,
      selectedSpecialties,
      selectedCategories,
    };
  };

  selectPortfolioTags = (portfolioTags: string[]) => {
    const categories: string[] = [];
    const services: string[] = [];
    const specialties: string[] = [];
    if (portfolioTags) {
      const splited_tags = portfolioTags.map((tag) => tag?.split(":"));

      splited_tags.forEach((tag) => {
        if (tag[0] === "categories") {
          categories.push(tag.join(":"));
        }
        if (tag[2] === "specialties") {
          specialties.push(tag.join(":"));
        }
        if (tag[2] === "services") {
          services.push(tag.join(":"));
        }
      });
    }

    return {
      categories,
      services,
      specialties,
    };
  };

  getInitialState = () => {
    return {} as { [key: string]: CategoryTagEntity };
  };
}

export const createTagAdapter = () => {
  return new TagAdapter();
};
