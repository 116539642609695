import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";

import {
  ApiError,
  ApiErrorInitialState,
} from "@hellodarwin/core/lib/features/entities";
import showErrorNotification from "../../helpers/show-error-notifications";
import PartnerApiClient from "../partner-api-client";

export const fetchCreditBalance = createAsyncThunk<
  number,
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  "partner/fetchCreditBalance",
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchCreditBalance();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  }
);

export interface CoreState {
  status: "idle" | "pending";
  error: ApiError;
  creditBalance: number;
}

const initialState: CoreState = {
  status: "idle",
  error: ApiErrorInitialState,
  creditBalance: 0,
};

const creditsSlice = createSlice({
  name: "credits",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCreditBalance.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchCreditBalance.fulfilled, (state, { payload }) => {
      state.creditBalance = payload;
      state.status = "idle";
    });
    builder.addCase(fetchCreditBalance.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const selectCreditBalance = (state: RootState) =>
  state.credits.creditBalance;

export const selectCreditsLoading = (state: RootState) =>
  state.credits.status === "pending";

export const selectError = (state: RootState) => state.credits.error;
export const creditsReducer = creditsSlice.reducer;

