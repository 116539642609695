import Div from "@hellodarwin/core/lib/components/common/div";
import Grid from "@hellodarwin/core/lib/components/common/hd-grid";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  Match,
  ProjectRowInformation,
} from "@hellodarwin/core/lib/features/entities";
import { getShortFormattedDate } from "@hellodarwin/core/lib/features/helpers";
import parse from "@hellodarwin/core/lib/features/helpers/parse";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Tooltip from "antd/es/tooltip";
import ProjectActions from "./project-actions";
import ProjectInformationRow from "./project-information-row";
import ProjectRaiseHandRHTag from "./project-raisehand-rh";
import ProjectSpecialties from "./project-specialties";
import ProjectStatus from "./project-status";

type ProjectBannerProps = {
  match: Match;
  showTags?: boolean;
  isCard?: boolean;
  available?: boolean;
  informationsLeft?: ProjectRowInformation[];
  informationsRight?: ProjectRowInformation[];
};

const ProjectBanner = ({
  match,
  showTags,
  isCard,
  available,
  informationsLeft,
  informationsRight,
}: ProjectBannerProps) => {
  const { t } = useTranslation();

  const ProjectTitle = () => {
    return (
      <Div
        flex={"column"}
        bordersSide="bottom"
        borderColor={isCard ? theme.colors.purple_5 : undefined}
        style={{ padding: isCard ? "1.5rem" : undefined }}
      >
        <Div
          flex="row"
          align="center"
          justify="space-between"
          tablet={{ flex: "column-reverse", align: "flex-start" }}
          gap={16}
        >
          <Div flex="column" gap={16}>
            {match.status && (
              <Div flex={"row"} tablet={{ flex: "column" }} gap={8} fitContent>
                <ProjectStatus
                  status={match.status?.toLowerCase() || ""}
                  statusMessage={
                    match.status === "Rejected"
                      ? ` | ${t(`project$|${match.raise_hand_reject_reason}`)}`
                      : ""
                  }
                  tooltip={
                    match.status === "Rejected"
                      ? ` | ${t(
                          `project$|${match.raise_hand_reject_reason_specified}`
                        )}`
                      : ""
                  }
                />
                {match.source === "Directory" && (
                  <Tooltip
                    title={
                      <>
                        {parse(
                          t(`project|projectCard.directoryMatchMoreInfo`, {
                            PageURL: match.project.source_url,
                            PageName: match.project.ad_group_id,
                          })
                        )}
                      </>
                    }
                  >
                    {""}
                    <HdTag
                      text={t(`project|projectCard.directoryMatch`)}
                      color={theme.colors.green_2}
                    />
                  </Tooltip>
                )}
                {match.project.updated_at && (
                  <HdTag
                    color={theme.colors.yellow_2}
                    text={getShortFormattedDate(match.project.updated_at)}
                  />
                )}

                {match.project.rh_status_details && (
                  <ProjectRaiseHandRHTag
                    message={match.project.rh_status_details}
                  />
                )}
              </Div>
            )}
            <Div>
              <Div
                className={"project-alias-container"}
                flex={"row"}
                align={"center"}
                gap={8}
              >
                <Typography elementTheme="body1" className="project-title">
                  {match.project.title
                    ? match.project.title
                    : match.project.service}
                </Typography>
              </Div>
              <Typography elementTheme="caption" color={theme.colors.grey_3}>
                {t(`project|projectType|${match.project.type}`)} -{" "}
                {match.project.category}
              </Typography>
            </Div>
          </Div>
          {!!available && (
            <Div
              flex="row"
              style={{ width: "fit-content" }}
              tablet={{ style: { width: "100%" } }}
            >
              <ProjectActions match={match} />
            </Div>
          )}
        </Div>
      </Div>
    );
  };

  const ProjectInformations = () => {
    return (
      <Grid xl={2} sm={1}>
        {!!informationsLeft?.length && (
          <Div flex="column">
            {informationsLeft?.map((info) => (
              <ProjectInformationRow info={info} key={info.title} />
            ))}
          </Div>
        )}
        {!!informationsRight?.length && (
          <Div
            flex="column"
            align={"flex-end"}
            tablet={{ align: "flex-start" }}
          >
            {informationsRight?.map((info) => (
              <ProjectInformationRow
                key={info.title}
                info={info}
                justify="flex-end"
                tablet={{ justify: "flex-start" }}
              />
            ))}
          </Div>
        )}
      </Grid>
    );
  };

  const ProjectTags = () => {
    return (
      <>
        {showTags && !!match.project?.specialties && (
          <Grid
            className={"project-banner-footer"}
            xl={2}
            sm={1}
            style={{ margin: 0 }}
          >
            <ProjectSpecialties specialties={match.project.specialties || []} />
          </Grid>
        )}
      </>
    );
  };

  return (
    <Div flex="column" gap={isCard ? 0 : 16}>
      <ProjectTitle />
      <Div
        style={{ padding: isCard ? "1.5rem" : undefined }}
        flex="column"
        gap={16}
      >
        <ProjectInformations />
        <ProjectTags />
      </Div>
    </Div>
  );
};
export default ProjectBanner;

