import React, { PropsWithChildren, useContext, useMemo, useState } from "react";
import { getI18n } from "../../plugins/i18n/utils";
import { Language } from "../entities/translations_ns";
import formatLang from "../helpers/format-lang";
import isBrowser from "../helpers/is-browser";
import useDebounceFunction from "../hooks/use-debounce-function";

export const LOCALE_CACHE_KEY = "language";
export const DEFAULT_LANGUAGE = "en";
export const SUPPORTED_LANGUAGES = ["en", "fr"];

export interface LocaleContextType {
  selectedLocale: Language;
  changeLanguage: (locale: Language) => void;
  localeProviderLoading: boolean;
}

export const LocaleContext = React.createContext<LocaleContextType>({
  selectedLocale: "en",
  changeLanguage(locale) {},
  localeProviderLoading: false,
});

interface LocaleProviderProps extends PropsWithChildren {}

export const LocaleProvider = ({ children }: LocaleProviderProps) => {
  //Find stored language
  const i18n = getI18n();
  const storedLanguage: Language = useMemo(() => {
    if (!isBrowser) return DEFAULT_LANGUAGE;

    const storedLanguage = localStorage.getItem(LOCALE_CACHE_KEY) as Language;
    const browserLanguage = navigator.language as Language;

    return SUPPORTED_LANGUAGES.includes(storedLanguage)
      ? storedLanguage
      : formatLang(browserLanguage);
  }, [isBrowser]);

  const [selectedLocale, setSelectedLocale] =
    useState<Language>(storedLanguage);

  const [localeProviderLoading, setLocaleProviderLoading] = useState(true);

  //Change language function
  const changeLanguage = (locale: Language) => {
    if (!isBrowser) return;
    if (!locale?.length) return;

    setLocaleProviderLoading(true);

    const validLocale = formatLang(locale);

    if (validLocale !== localStorage.getItem(LOCALE_CACHE_KEY)) {
      localStorage.setItem(LOCALE_CACHE_KEY, validLocale);
    }

    if (!!i18n) {
      i18n.changeLanguage(validLocale);
    }

    setSelectedLocale(validLocale);

    setTimeout(() => {
      setLocaleProviderLoading(false);
    }, 500);
  };

  const debouncedChangeLanguage = useDebounceFunction(changeLanguage, 200);

  const contextValue = useMemo(
    () => ({
      selectedLocale,
      changeLanguage: debouncedChangeLanguage,
      localeProviderLoading,
    }),
    [selectedLocale, localeProviderLoading]
  );

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};

const useLocale = () => useContext(LocaleContext);

export default useLocale;

