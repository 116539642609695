import { HdArrowProps } from ".";
import styled from "../../../../plugins/styled";

interface ArrowContainerProps {
  $dir: HdArrowProps["dir"];
}
export const ArrowContainer = styled.span<ArrowContainerProps>`
  display: flex;
  width: 20px;
  flex-direction: column;
  align-items: ${({ $dir }) => ($dir === "left" ? "flex-start" : "flex-end")};
  justify-content: center;
  position: relative;
  transition: width 100ms linear;

  &:after {
    border-radius: 99px;
    overflow: hidden;
    content: "";
    ${({ $dir }) => ($dir === "left" ? "left: 1px;" : "right: 1px;")};

    border-bottom: 2px solid ${({ theme }) => theme.colors.grey_1};
    width: 100%;
    top: calc(50% - 1px);
    position: absolute;
  }
`;

