import Layout from "antd/es/layout";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Typography from "../../typography";

const { Footer } = Layout;

const AppFooter = () => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Footer
      style={{
        padding: "25px 96px",
        textAlign: "center",
        display: "block",
        position: "relative",
        background: "transparent",
        marginTop: 54,
        height: theme.appearance.pageFooterHeight,
      }}
    >
      <Typography
        elementTheme="overline"
        bold
        letterSpacing={0.27}
        textTransform="uppercase"
        color={theme.colors.grey_4}
      >
        {t("footer|allRightsReserved")}
      </Typography>
    </Footer>
  );
};

export default AppFooter;

