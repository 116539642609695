import Edit from "@hellodarwin/icons/dist/icons/Edit";
import { CSSProperties } from "react";
import { ProfileModalProps } from "../../../../../features/entities";
import ProfileBlocks from "../../../../../features/enums/profile-blocks";
import Avatar from "../../../../common/avatar";
import Div from "../../../../common/div";
import { AssetOverlay, EditButton } from "../styles";

const DEFAULT_SIZE = 116;

type ProfileLogoProps = {
  name: string;
  isEditable?: boolean;
  logo: string;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  style?: CSSProperties;
  size?: number;
};

const ProfileLogo = ({
  name,
  isEditable = false,
  logo,
  toggleEditModal,
  style,
  size = DEFAULT_SIZE,
}: ProfileLogoProps) => {
  const handleEdit = () =>
    !!toggleEditModal &&
    toggleEditModal({ open: true, type: ProfileBlocks.Logo });

  return (
    <Div style={{ ...style, position: "relative" }} fitContent>
      <Avatar alt={name} src={logo} size={size} shape="square">
        {name?.[0]}
      </Avatar>
      {isEditable && (
        <AssetOverlay onClick={handleEdit} $type="logo">
          <EditButton>
            <Edit width={18} height={18} />
          </EditButton>
        </AssetOverlay>
      )}
    </Div>
  );
};
export default ProfileLogo;

