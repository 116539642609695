import styled from "../../../../../plugins/styled";
import Div from "../../../../common/div";

export const ProfilePicture = styled(Div)`
  width: 200px;
  height: 200px;
  border-radius: 12px;
  overflow: hidden;
  margin: auto;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;
