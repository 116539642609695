import { LogoProps } from ".";
import theme from "../../theme";

const TertiaryLogo = ({
  primaryColor = theme.colors.grey_1,
  secondaryColor = theme.colors.grey_1,
  width = 135,
  height = 145,
  style,
  onClick,
}: LogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      onClick={onClick}
      viewBox="0 0 135 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 102.384H10.389C13.7496 113.335 16.4999 123.033 19.3719 133.438H19.4954C22.8561 123.087 26.3385 112.735 29.6991 102.384H40.0881C43.4487 112.735 46.9311 123.087 50.3535 133.438H50.4753C53.2855 123.359 56.2193 112.68 59.3965 102.384H69.7854L56.2193 144.878H44.9147C41.1254 134.199 38.1916 125.373 34.9545 115.24H34.831C31.532 125.701 28.4148 135.126 24.9924 144.878H13.5644L0 102.384Z"
        fill={primaryColor}
      />
      <path
        d="M77.738 102.384H86.8427V144.878H77.738V102.384Z"
        fill={primaryColor}
      />
      <path
        d="M95.219 102.384H104.691C111.717 111.699 118.745 120.797 125.834 130.658H125.955V102.384H134.998V144.878H125.588C118.56 135.671 111.534 126.846 104.507 117.202H104.324V144.878H95.219V102.384Z"
        fill={primaryColor}
      />
      <path
        d="M0 52.3029H18.4546C34.3423 52.3029 41.0636 61.7833 41.0636 73.278C41.0636 85.9168 32.8745 94.7971 19.0032 94.7971H0V52.3029ZM17.9041 87.3328C24.809 87.3328 31.5303 84.227 31.5303 73.7132C31.5303 61.5096 22.4874 59.7672 16.1312 59.7672H9.10467V87.3345H17.9041V87.3328Z"
        fill={primaryColor}
      />
      <path
        d="M62.5808 52.3026H73.2133L91.3009 94.7968H81.5858L76.8191 83.6828H58.6098C57.0203 87.3869 55.4926 91.0927 53.9049 94.7968H44.495L62.5826 52.3026H62.5808ZM73.5802 75.8377C72.1142 72.4057 70.2795 67.9927 67.8362 62.055H67.7145C66.125 65.9222 63.9869 70.9353 61.8487 75.8377H73.582H73.5802Z"
        fill={primaryColor}
      />
      <path
        d="M98.8848 52.3026H117.278C120.517 52.3026 133.837 53.501 133.837 65.9766C133.837 73.9848 128.52 77.6889 123.083 78.9417V78.9961C127.054 84.2267 131.027 89.51 135 94.795H124.733L113.49 79.5962H107.991V94.795H98.8865V52.3008L98.8848 52.3026ZM115.628 72.4056C119.051 72.4056 124.306 71.2616 124.306 66.1398C124.306 61.0179 119.112 59.6563 115.752 59.6563H107.991V72.4056H115.63H115.628Z"
        fill={primaryColor}
      />
      <path
        d="M81.3246 42.045C80.2644 42.4573 79.1371 42.6679 77.9763 42.6679C76.1892 42.6679 74.8026 42.0836 73.8518 40.929C72.9344 39.8166 72.4881 38.0514 72.4881 35.5317V4.09363H64.9764V5.97461H69.9213V35.53C69.9213 37.3337 70.0977 38.855 70.4488 40.0482C70.7822 41.187 71.2691 42.1046 71.8971 42.7749C72.5269 43.4469 73.2908 43.9225 74.2364 44.2295C75.2296 44.5506 76.3833 44.7138 77.6676 44.7138C78.2392 44.7138 78.8795 44.6454 79.5711 44.5085C80.2714 44.3699 80.9824 44.1664 81.6792 43.9067C82.3831 43.6452 83.0764 43.3206 83.7397 42.9381C84.1578 42.6995 84.5565 42.4363 84.934 42.1538L83.9161 40.6079C82.9935 41.2501 82.1238 41.7309 81.3246 42.0432V42.045Z"
        fill={secondaryColor}
      />
      <path
        d="M99.6116 42.045C98.5514 42.4573 97.4241 42.6679 96.2633 42.6679C94.4762 42.6679 93.0896 42.0836 92.1388 40.929C91.2214 39.8166 90.7751 38.0514 90.7751 35.5317V4.09363H83.2634V5.97461H88.2083V35.53C88.2083 37.3337 88.3847 38.855 88.7358 40.0482C89.0692 41.187 89.5561 42.1046 90.1841 42.7749C90.8139 43.4469 91.5778 43.9225 92.5233 44.2295C93.5165 44.5506 94.6703 44.7138 95.9546 44.7138C96.5261 44.7138 97.1665 44.6454 97.8581 44.5085C98.5584 44.3699 99.2694 44.1664 99.9662 43.9067C100.67 43.6452 101.363 43.3206 102.027 42.9381C102.445 42.6995 102.843 42.4363 103.221 42.1538L102.203 40.6079C101.28 41.2501 100.411 41.7309 99.6116 42.0432V42.045Z"
        fill={secondaryColor}
      />
      <path
        d="M0 0H2.58445V20.2908H2.69735C5.17066 15.1479 9.21581 13.0792 13.8237 13.0792C19.2184 13.0792 25.287 15.8743 25.287 25.4881V44.7173H22.7026V26.6075C22.7026 17.8325 17.8706 15.3725 13.4303 15.3725C6.2362 15.3725 2.58445 21.9121 2.58445 27.1673V44.719H0V0Z"
        fill={secondaryColor}
      />
      <path
        d="M32.5835 28.4516C32.5835 18.8379 38.8779 12.1299 47.6439 12.1299C52.9257 12.1299 61.524 14.5899 61.524 27.2234V28.7324H35.392C35.392 36.9494 40.5062 42.4274 47.755 42.4274C53.8801 42.4274 57.8705 38.404 59.7246 33.7086L62.0286 34.435C59.6117 41.1413 54.0494 44.719 47.5856 44.719C38.9308 44.719 32.58 38.1233 32.58 28.4534L32.5835 28.4516ZM58.6591 26.1601C58.6591 17.3833 52.6452 14.4215 47.531 14.4215C41.0125 14.4215 36.4046 19.2292 35.5049 26.1601H58.6591Z"
        fill={secondaryColor}
      />
      <path
        d="M104.371 28.4516C104.371 18.8379 110.666 12.1299 119.712 12.1299C128.759 12.1299 134.998 18.8379 134.998 28.4516C134.998 38.0654 128.704 44.7173 119.712 44.7173C110.72 44.7173 104.371 38.011 104.371 28.4516ZM119.712 42.4257C127.13 42.4257 132.188 36.6687 132.188 28.4516C132.188 20.2346 127.13 14.4215 119.712 14.4215C112.294 14.4215 107.18 20.2346 107.18 28.4516C107.18 36.6687 112.294 42.4257 119.712 42.4257Z"
        fill={secondaryColor}
      />
    </svg>
  );
};

export default TertiaryLogo;
