import Divider from "antd/es/divider";
import React from "react";
import { Project } from "../../../../features/entities/project-entities";
import ProjectDescriptionType from "../../../../features/enums/project-description-type";
import parse, {
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "../../../../features/helpers/parse";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import ProjectSingleTile from "./project-single-tile";

type ProjectDescriptionProps = {
  project: Project;
  isPartner?: boolean;
  projectManager?: string;
};

const ProjectDescription = ({
  project,
  isPartner,
  projectManager,
}: ProjectDescriptionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (domNode.attribs) {
        const props = attributesToProps(domNode.attribs);
        if (domNode.name === "ul") {
          return (
            <Typography elementTheme="body2">
              <ul {...props}>{domToReact(domNode.children)}</ul>
            </Typography>
          );
        }
        if (domNode.name === "h4") {
          return (
            <Div flex="column" gap={6}>
              <Typography
                elementTheme="caption"
                color={theme.colors.grey_3}
                textTransform="uppercase"
                style={{ letterSpacing: 0.3 }}
                bold
              >
                {domToReact(domNode.children)}
              </Typography>
              <Divider style={{ margin: 0 }} />
            </Div>
          );
        }
      }
    },
  };

  return (
    <ProjectSingleTile>
      <Typography elementTheme="body2" color={theme.colors.primary}>
        {isPartner && (
          <>
            {project.special_conditions &&
              project.special_conditions !== "<p><br></p>" && (
                <Div flex="column" gap={12}>
                  <Div flex="column" gap={6}>
                    <Typography
                      elementTheme="caption"
                      color={theme.colors.grey_3}
                      textTransform="uppercase"
                      style={{ letterSpacing: 0.3 }}
                      bold
                    >
                      {t(`project|projectPage.specialConditions`)}
                    </Typography>
                    <Divider style={{ margin: 0 }} />
                  </Div>
                  <Typography elementTheme="body2">
                    {parse(project.special_conditions as string)}
                  </Typography>
                </Div>
              )}
            {project.additional_comments &&
              parse(project.additional_comments) !== "" && (
                <Div flex="column" gap={12}>
                  <Div flex="column" gap={6}>
                    <Typography
                      elementTheme="caption"
                      color={theme.colors.grey_3}
                      textTransform="uppercase"
                      style={{ letterSpacing: 0.3 }}
                      bold
                    >
                      {t(`project|projectPage.additionalComments`)}
                    </Typography>
                    <Divider style={{ margin: 0 }} />
                  </Div>
                  <Typography elementTheme="body2">
                    {parse(project.additional_comments as string)}
                  </Typography>
                </Div>
              )}
          </>
        )}

        {!isPartner && project.status === "PendingApproval" ? (
          <Div flex="column" gap={12}>
            {project.original_description
              ? parse(project.original_description, options)
              : ""}
          </Div>
        ) : project.description ? (
          <Div> {parse(project.description, options)} </Div>
        ) : (
          project.description_container && (
            <Div flex="column" gap={12}>
              {React.Children.toArray(
                Object.values(ProjectDescriptionType).map((descriptionType) => {
                  if (project.description_container[descriptionType]) {
                    return (
                      <React.Fragment key={descriptionType}>
                        <Div
                          bordersSide="bottom"
                          borderColor={theme.colors.grey_5}
                        >
                          <Typography
                            className="hd-mb-05"
                            style={{ letterSpacing: 0.3 }}
                            elementTheme="caption"
                            bold
                            color={theme.colors.grey_3}
                          >
                            {t(
                              `project|projectPage.projectDescriptionType.${descriptionType}`
                            )}
                          </Typography>
                        </Div>
                        <Div>
                          {parse(
                            project.description_container[descriptionType],
                            options
                          )}
                        </Div>
                      </React.Fragment>
                    );
                  }
                  return null;
                })
              )}
            </Div>
          )
        )}
      </Typography>
    </ProjectSingleTile>
  );
};

export default ProjectDescription;

