import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import Divider from "antd/es/divider";
import React, { ReactNode } from "react";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";
import Typography from "../../../../common/typography";
import ProjectSingleTile from "../project-single-tile";
import { ProjectSidebarSection } from "./styles";

type ProjectSidebarProps = {
  sections: ProjectSidebarField[][];
  isOverview?: boolean;
};

export interface ProjectSidebarField {
  label: string;
  value: string | ReactNode;
  Icon?: (props: IconProps) => ReactNode;
}

const ProjectSidebar = ({ sections }: ProjectSidebarProps) => {
  const theme = useTheme();

  const SidebarField = ({ Icon, label, value }: ProjectSidebarField) => {
    return (
      <Div
        flex="column"
        gap={4}
        style={{ paddingLeft: !Icon ? 26 : undefined }}
      >
        <Div flex="row" align="center" gap={15}>
          {!!Icon && <Icon width={12} height={12} />}
          <Typography
            elementTheme="overline"
            medium
            color={theme.colors.grey_2}
            textTransform="uppercase"
          >
            {label}
          </Typography>
        </Div>
        <Typography
          elementTheme="body2"
          style={{ paddingLeft: !!Icon ? 32 : undefined }}
        >
          {value}
        </Typography>
      </Div>
    );
  };
  return (
    <ProjectSingleTile>
      <Div flex="column" gap={20}>
        {React.Children.toArray(
          sections.map((fields, i) => (
            <React.Fragment key={`sidebar-section-${i}`}>
              {i > 0 && (
                <Divider
                  style={{ margin: 0, borderColor: theme.colors.grey_4 }}
                />
              )}
              <ProjectSidebarSection flex="column" gap={20}>
                {React.Children.toArray(
                  fields.map((field, j) => <SidebarField {...field} />)
                )}
              </ProjectSidebarSection>
            </React.Fragment>
          ))
        )}
      </Div>
    </ProjectSingleTile>
  );
};

export default ProjectSidebar;

