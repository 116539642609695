import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Help = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6483 12.0794C12.6483 7.48694 17.5012 7.29915 19.3517 8.91891C21.2038 10.5387 21.2038 13.165 19.3517 14.7848C19.0309 15.0676 18.6722 15.2999 18.2929 15.4833C17.1157 16.0537 16.0016 17.0619 16.0016 18.3704V19.5556M30.2222 16C30.2222 17.8677 29.8544 19.7171 29.1396 21.4426C28.4249 23.1681 27.3773 24.736 26.0566 26.0566C24.736 27.3773 23.1681 28.4249 21.4426 29.1396C19.7171 29.8544 17.8677 30.2222 16 30.2222C14.1323 30.2222 12.2829 29.8544 10.5574 29.1396C8.83187 28.4249 7.26403 27.3773 5.94337 26.0566C4.62272 24.736 3.57511 23.1681 2.86038 21.4426C2.14565 19.7171 1.77778 17.8677 1.77778 16C1.77778 12.228 3.27619 8.61055 5.94337 5.94336C8.61056 3.27618 12.228 1.77777 16 1.77777C19.772 1.77777 23.3894 3.27618 26.0566 5.94336C28.7238 8.61055 30.2222 12.228 30.2222 16ZM16 24.2963H16.0126V24.3089H16V24.2963Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Help;

