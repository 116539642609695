import { ProfileModalProps, Provider } from "../../../../features/entities";
import ProfileBlocks from "../../../../features/enums/profile-blocks";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import { DefaultTheme } from "../../../../theme";
import SeeMoreText from "../../../common/see-more/see-more-text";
import ProfileSection from "../profile-section";
import ProfileEmptySection from "../profile-section/empty-state";

const maxDescLengthMobile = 200;
const maxDescLength = 500;

type ProfileAboutProps = {
  provider: Provider;
  isEditable?: boolean;
  id: string;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
};

const ProfileAbout = ({
  provider,
  isEditable = false,
  id,
  toggleEditModal,
}: ProfileAboutProps) => {
  const { t } = useTranslations();
  const theme = useTheme() as DefaultTheme;

  const handleEditionModal = () =>
    !!toggleEditModal &&
    toggleEditModal({ open: true, type: ProfileBlocks.About });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const descLength = isTablet ? maxDescLengthMobile : maxDescLength;

  return (
    <ProfileSection
      title={t("profile_about|aboutTitle", {
        companyName: provider?.name,
      })}
      isEditable={isEditable}
      handleEdit={handleEditionModal}
      id={id}
      level={2}
      hide={!provider?.description?.length}
      emptyState={
        <ProfileEmptySection
          title={t("profile_about|aboutEmptyTitle")}
          subtitle={t("profile_about|aboutEmptySubtitle")}
          sectionName="About"
          cta={t("profile_about|aboutEmptyCta")}
          handleClick={handleEditionModal}
          isEditable={isEditable}
        />
      }
    >
      <SeeMoreText
        text={provider?.description || ""}
        limit={descLength}
        elementTheme="body1"
        color={theme.colors.grey_2}
      />
    </ProfileSection>
  );
};

export default ProfileAbout;

