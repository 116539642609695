import styled from "../../../../plugins/styled";

export const EditButton = styled.div`
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  opacity: 0.9;
`;

export const AssetOverlay = styled.div<{ $type: "banner" | "logo" }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  cursor: pointer;
  background: ${({ theme }) => theme.colors.white_1}40;

  ${EditButton} {
    ${({ $type }) =>
      $type === "logo"
        ? `
      margin-right:-6px;
      margin-bottom:-6px;
      `
        : `
      margin-right:6px;
      margin-bottom:6px;
      `}
  }
  &:hover {
    background: ${({ theme }) => theme.colors.white_1}70;
    ${EditButton} {
      opacity: 1;
    }
  }
`;
