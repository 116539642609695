import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import ProjectReferralForm from "../../../components/referral/project-referral-form";
import PartnerReferralSharePage from "./partner-referral-share-section";

export type ProjectReferralFormValues = {
  client_name: string;
  client_email: string;
  client_phone: string;
  project_category: string;
  project_budget: string;
};

const PartnerReferralReferPage = ({ isShare }: { isShare?: boolean }) => {
  const { t } = useTranslation();

  return (
    <PageLayout
      app="provider"
      title={t("referral|form.title")}
      className={"project-referral-page-content"}
      subtitle={t("referral|form.subtitle")}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.referralRefer"),
        },
      ]}
    >
      <Container>
        <Div flex={"column"} gap={54}>
          <PartnerReferralSharePage />
          <ProjectReferralForm />
        </Div>
      </Container>
    </PageLayout>
  );
};

export default PartnerReferralReferPage;

