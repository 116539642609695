import { PropsWithChildren } from "react";
import useMediaQuery from "../../../features/helpers/use-media-query";
import { useTheme } from "../../../plugins/styled";

type MediaProps = {
  direction?: "up" | "down";
  inactive?: boolean;
} & PropsWithChildren;

export const Desktop = ({
  children,
  direction = "down",
  inactive,
}: MediaProps) => {
  const theme = useTheme();

  if (inactive) return children;

  const breakpoint =
    direction === "down"
      ? { maxWidth: theme.breakpoints.xl }
      : { minWidth: theme.breakpoints.xl };

  const isDesktop = useMediaQuery(breakpoint);
  return isDesktop ? children : null;
};

export const Tablet = ({
  children,
  direction = "up",
  inactive,
}: MediaProps) => {
  const theme = useTheme();

  if (inactive) return children;

  const breakpoint =
    direction === "down"
      ? { maxWidth: theme.breakpoints.tablet }
      : { minWidth: theme.breakpoints.tablet };

  const isTablet = useMediaQuery(breakpoint);
  return isTablet ? children : null;
};
