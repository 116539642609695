import styled from "../../../../../plugins/styled";
import Div from "../../../../common/div";

export const PortfolioPreviewInfoContainer = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.grey_4};
  &:last-child {
    border: none;
  }
`;

export const PortfolioThumbnail = styled(Div)`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .ant-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
`;

export const PortfolioImageContainer = styled(Div)`
  width: calc((100% / 3) - 1rem);
  height: fit-content;
  .ant-image {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) { {
    width: calc((100% / 2) - 1rem);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) { {
    width: 100%;
  }
`;

export const PortfolioVideoContainer = styled(Div)`
  width: calc(((100% / 3) * 2) - 1rem);
  height: fit-content;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) { {
    width: calc((100% / 2) - 1rem);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) { {
    width: 100%;
  }
  .portfolio-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    .video {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
