import classNames from "classnames";
import {
  CSSProperties,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from "react";
import { useTheme } from "styled-components";
import Spin from "../spin";
import HdArrow from "./arrow";
import { ButtonContainer } from "./styles";
import useButtonProps from "./use-button-props";

export type ButtonSize =
  | "small"
  | "extra-small"
  | "medium"
  | "large"
  | "auto"
  | "circle"
  | "square";
export type HTMLType = "submit" | "reset" | "button";

export interface GeneralButtonProps {
  ref?: any;
  id?: string;
  className?: string;
  loading?: boolean;
  headingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  htmlType?: HTMLType;
  withArrowLeft?: boolean;
  withArrowRight?: boolean;
  size?: ButtonSize;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

export interface ViewButtonProps {
  style?: CSSProperties;
  defaultStyle?: string;
  fitContent?: boolean;
  fullWidth?: boolean;
  transparent?: boolean;
  isLink?: boolean;
  hidden?: boolean;
  textWrap?: boolean;

  onClick?: MouseEventHandler<HTMLElement>;
}

export type ButtonProps = GeneralButtonProps &
  PropsWithChildren &
  ViewButtonProps & {
    tablet?: ViewButtonProps;
    xl?: ViewButtonProps;
  };

const Button = (props: ButtonProps) => {
  const theme = useTheme();
  const { currentProps, currentStyle, defaultProps } = useButtonProps(props);

  const {
    children,
    id,
    className,
    headingIcon,
    trailingIcon,
    withArrowLeft,
    withArrowRight,
    disabled,
    size,
    loading,
    handleClick,
  } = defaultProps;

  const classes = classNames("hd-button", className);
  return (
    <ButtonContainer
      {...currentProps}
      className={classes}
      onClick={!!props.onClick ? handleClick : undefined}
      id={id}
      style={currentStyle}
      disabled={loading || disabled}
      $withArrow={withArrowLeft || withArrowRight}
      $size={size}
    >
      {!!headingIcon && <span>{headingIcon}</span>}
      {withArrowLeft && <HdArrow dir="left" />}
      {!!children && <span>{children}</span>}
      {!!loading ? (
        <Spin color={theme.colors.white_1} size="small" />
      ) : (
        <>
          {withArrowRight && <HdArrow dir="right" />}
          {!!trailingIcon && <span>{trailingIcon}</span>}
        </>
      )}
    </ButtonContainer>
  );
};

export default Button;

