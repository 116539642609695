enum ProfileBlocks {
  FastInfos = "fast-infos",
  About = "about",
  Portfolios = "portfolios",
  Presentation = "video",
  Logo = "logo",
  Banner = "banner",
  Team = "team",
  Reviews = "reviews",
  Project = "project",
  Expertise = "expertise",
  Contact = "contact",
  AdBanner = "ad-banner",
}

export default ProfileBlocks;

