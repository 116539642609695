import styled from "../../../plugins/styled";

type ProgressionContainerProps = {
  $progressValue?: number;
  $progressColor?: string;
  $progressBarColor?: string;
  $outerColor?: string;
  $inversed?: boolean;
  $barSize?: number;
  $outerBarSize?: number;
  $size?: number;
};

export const ProgressionInner = styled.div<ProgressionContainerProps>`
  border: ${(props) => props.$outerBarSize}px solid
    ${(props) => props.$outerColor};
  width: calc(100% - ${(props) => props.$barSize}px);
  height: calc(100% - ${(props) => props.$barSize}px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProgressionContent = styled.div<ProgressionContainerProps>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => props.$outerBarSize}px solid
    ${(props) => props.$outerColor};
  background-repeat: no-repeat;
  background: ${(props) =>
      props.$inversed
        ? `
      radial-gradient(
        closest-side,
        white calc(100% - ${props.$barSize}px),
        transparent 80% 100%,
        white 0
      ),
      conic-gradient(
          ${props.$progressBarColor},
          calc(100% - ${props.$progressValue}%),
          ${props.$progressColor} 0
      );`
        : `
        radial-gradient(
          closest-side,
          white calc(100% - ${props.$barSize}px),
          transparent 80% 100%,
          white 0
        ),
      conic-gradient(
        ${props.$progressColor},
        ${props.$progressValue}%,
        ${props.$progressBarColor} 0
      );`}
    progress {
    visibility: hidden;
    width: 0;
    height: 0;
  }
`;
