import ProjectIgnoreModal from "@hellodarwin/core/lib/components/project/project-ignore-modal";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  ignoreProject,
  selectSelectedMatch,
} from "../../../features/api/slices/matches-slice";
import { usePartnerApi } from "../../../features/api/use-partner-api";

type ProjectIgnoreModalContainerProps = {
  open: boolean;
  onCancel: () => void;
};
const ProjectIgnoreModalContainer = ({
  open,
  onCancel,
}: ProjectIgnoreModalContainerProps) => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const match = useAppSelector(selectSelectedMatch);

  const handleIgnore = async (
    match_id: string,
    ignoreReason: string,
    ignoreReasonSpecified: string
  ) => {
    await dispatch(
      ignoreProject({
        api,
        matchId: match_id,
        refuseReason: ignoreReason,
        refuseReasonSpecified: ignoreReasonSpecified,
      })
    ).then(() => onCancel());
  };

  return (
    <ProjectIgnoreModal
      closeModal={onCancel}
      modalVisible={open}
      handleIgnore={handleIgnore}
      match={match}
    />
  );
};

export default ProjectIgnoreModalContainer;

