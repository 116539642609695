import Close from "@hellodarwin/icons/dist/icons/Close";
import classNames from "classnames";
import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  ModalBody,
  ModalCloseButton,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from "./styles";

export interface ModalProps extends PropsWithChildren {
  open: boolean;
  handleCancel: () => void;
  afterClose?: () => void;
  size?: "small" | "medium" | "large" | "extra-large" | "full";
  noPadding?: boolean;
  className?: string;
  styles?: {
    wrapper?: CSSProperties;
    content?: CSSProperties;
    container?: CSSProperties;
    body?: CSSProperties;
  };
  style?: CSSProperties;
  header?: ReactNode;
  footer?: ReactNode;
}

const Modal = ({
  children,
  open,
  handleCancel,
  size = "medium",
  noPadding,
  className,
  styles,
  style,
  header,
  footer,
  afterClose,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const classes = classNames("hd-modal", className, { [`fade-out`]: fadeOut });

  const closeWithFade = () => {
    setIsOpen((prevOpen) => {
      if (prevOpen === false) {
        return false;
      } else {
        setFadeOut(true);
        setTimeout(() => {
          setFadeOut(false);
        }, 300);

        return false;
      }
    });
  };
  useEffect(() => {
    if (open) {
      setIsOpen(true);
      setFadeOut(false);
    } else {
      closeWithFade();
    }
  }, [open]);

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    } else {
      closeWithFade();
    }
  };

  useEffect(() => {
    if (!open && !fadeOut && !!afterClose) {
      afterClose();
    }
  }, [open, fadeOut]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen && !fadeOut) return null;

  return (
    <>
      {createPortal(
        <ModalWrapper
          onClick={onCancel}
          className={classes}
          style={{ ...styles?.wrapper }}
        >
          <ModalContainer $size={size} style={{ ...styles?.container }}>
            {!!header && (
              <ModalHeader onClick={(e) => e.stopPropagation()}>
                {header}
              </ModalHeader>
            )}
            <ModalContent
              $noPadding={noPadding}
              onClick={(e) => e.stopPropagation()}
              style={{ ...styles?.content, ...style }}
              $withHeader={!!header}
              $withFooter={!!footer}
            >
              <ModalBody style={{ ...styles?.body }}>
                <ModalCloseButton
                  onClick={handleCancel}
                  transparent
                  size="small"
                  headingIcon={<Close width={16} height={16} />}
                ></ModalCloseButton>
                {children}
              </ModalBody>
            </ModalContent>
            {!!footer && (
              <ModalFooter onClick={(e) => e.stopPropagation()}>
                {footer}
              </ModalFooter>
            )}
          </ModalContainer>
        </ModalWrapper>,
        document.getElementById("root")
      )}
    </>
  );
};

export default Modal;

