import Accueil from "@hellodarwin/icons/dist/icons/Accueil";
import NotFound from "@hellodarwin/icons/dist/icons/NotFound";
import { useNavigate } from "react-router-dom";
import { usePlatform } from "../../features/providers/platform-provider";
import { useTranslations } from "../../features/providers/translations-provider";
import Div from "../common/div";
import PageLayout from "../common/layout/page-layout";
import ErrorBox from "./error-box";

const ErrorNotFound = () => {
  const { t } = useTranslations();
  const { app } = usePlatform();

  const navigate = useNavigate();
  const goToHome = () => {
    navigate("/");
  };

  return (
    <PageLayout tabTitle="Page not found" app={app} breadcrumbs={[]}>
      <Div
        flex="column"
        justify="center"
        align="center"
        style={{
          minHeight: `calc(100vh - 40px - 40px - 32px - 54px - 64px - 40px)`,
        }}
      >
        <ErrorBox
          errorMessage={t(`errorsPage|NotFound`)}
          ErrorIcon={NotFound}
          ctaAction={goToHome}
          CtaIcon={Accueil}
        />
      </Div>
    </PageLayout>
  );
};

export default ErrorNotFound;

