import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import BadgesForm from "@hellodarwin/core/lib/components/provider/profile/profile-expertise/badges-form";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import Locked from "@hellodarwin/icons/dist/icons/Locked";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/index";
import { usePartnerAnalytics } from "../../features/analytics/use-partner-analytics";
import { fetchTags } from "../../features/api/slices/profile-slice";
import { usePartnerApi } from "../../features/api/use-partner-api";
import YourReviewLink from "./your-review-link";
interface BadgesSectionProps {
  isDisabled?: boolean;
}
const BadgesSection = ({ isDisabled }: BadgesSectionProps) => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const { t } = useTranslation("common");
  const theme = useTheme();

  const [providerId, setProviderId] = useState<string>("");
  const [numberReviews, setNumberReviews] = useState<string>("");
  const [average, setAverage] = useState<string>("");

  useEffect(() => {
    dispatch(fetchTags({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchTags({ api }));

    const fetchReviewsData = async () => {
      try {
        const provider = await api.fetchProfile();

        const widgetInfo = (await api.fetchInfoForWidget(
          provider.provider_id
        )) as any;
        setAverage(widgetInfo.average_reviews);
        setNumberReviews(widgetInfo.review_count);

        setProviderId(provider.provider_id);
      } catch (error) {
        console.error("Error fetching reviews data:", error);
      }
    };

    fetchReviewsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analytics = usePartnerAnalytics();

  const Content = () => {
    return (
      <Container style={{ width: 600, maxWidth: "100%" }}>
        <div>
          <Typography.Title level={2} elementTheme="h5">
            {t("navigation.badges")}
          </Typography.Title>
          <BadgesForm
            numberReviews={numberReviews}
            providerId={providerId}
            average={average}
            handleClipboardAnalytics={analytics.widgetSnippetCopied}
          />
        </div>
      </Container>
    );
  };
  return isDisabled ? (
    <Div style={{ position: "relative" }}>
      <Div style={{ filter: "blur(5px)", width: "100%" }}>
        <Content />
      </Div>
      <Div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
        }}
        fitContent
      >
        <Div
          flex="column"
          align="center"
          gap={32}
          style={{
            height: 360,
            width: 560,

            padding: "24px 32px",
            justifyContent: "center",
            borderRadius: 4,
            background: theme.colors.white_1,
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
            border: `2px solid ${theme.colors.primary}`,
          }}
          tablet={{ style: { width: "100vw" } }}
        >
          <Locked width={48} height={48} />
          <Div flex="column" align="center" gap={8}>
            <Typography.Title
              level={3}
              elementTheme="subtitle1"
              textAlign="center"
            >
              {t(`expertise|PopUp.title`)}
            </Typography.Title>
            <Typography elementTheme="body2" textAlign="center">
              {t(`expertise|PopUp.description`)}
            </Typography>
          </Div>

          <YourReviewLink hideLangSelector dir="column" />
        </Div>
      </Div>
    </Div>
  ) : (
    <Content />
  );
};

export default BadgesSection;

