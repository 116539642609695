import { useEffect, useState } from "react";
import Typography from "../typography";
import { ProgressionContent, ProgressionInner } from "./styles";

type HdProgressionProps = {
  value: number;
  size: number;
  barSize?: number;
  outerBarSize?: number;
  progressColor?: string;
  progressBarColor?: string;
  outerColor?: string;
  inversed?: boolean;
};

const HdProgression = ({
  value,
  size,
  barSize = 6,
  outerBarSize = 20,
  progressColor,
  progressBarColor,
  outerColor,
  inversed,
}: HdProgressionProps) => {
  const [currentValue, setCurrentValue] = useState(value);

  const changeValue = (newValue: number) => {
    if (newValue > value) {
      setTimeout(() => changeValue(newValue - 1), 5);
    } else if (newValue < value) {
      setTimeout(() => changeValue(newValue + 1), 5);
    }
    setCurrentValue(newValue);
  };

  useEffect(() => {
    changeValue(currentValue);
  }, [value]);

  return (
    <ProgressionContent
      style={{ width: size, height: size }}
      $outerColor={outerColor}
      $progressBarColor={progressBarColor}
      $progressColor={progressColor}
      $inversed={inversed}
      $barSize={barSize}
      $outerBarSize={outerBarSize}
      $progressValue={currentValue}
    >
      <progress value={currentValue} max={100} />
      <ProgressionInner
        $outerColor={outerColor}
        $barSize={barSize}
        $outerBarSize={outerBarSize}
      >
        <Typography elementTheme="h5">{currentValue}%</Typography>
      </ProgressionInner>
    </ProgressionContent>
  );
};
export default HdProgression;
