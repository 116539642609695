import {
  ApiError,
  ApiErrorInitialState,
  Project,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app";
import PartnerApiClient from "../partner-api-client";

const projectAdapter = createEntityAdapter<Project, string>({
  selectId: (project: Project) => project.project_id,
});

export interface clientState {
  status: "idle" | "pending";
  error: ApiError;
  projects: EntityState<Project, string>;
}

const initialState: clientState = {
  status: "idle",
  error: ApiErrorInitialState,
  projects: projectAdapter.getInitialState(),
};

export const fetchWonProjects = createAsyncThunk<
  Project[],
  { api: PartnerApiClient },
  { rejectValue: ApiError; state: RootState }
>(
  "partner/fetchWonProjects",
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchWonProjects();
    } catch (err: any) {
      // showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  }
);

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWonProjects.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        projectAdapter.addMany(state.projects, payload);
      }
      state.status = "idle";
    });
    builder.addCase(fetchWonProjects.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchWonProjects.pending, (state) => {
      state.status = "pending";
    });
  },
});

export const {
  selectAll: selectAllProject,
  selectById: selectProject,
} = projectAdapter.getSelectors((state: RootState) => state.projects.projects);

export const selectProjectsLoading = (state: RootState) =>
  state.projects.status === "pending";

export const projectsReducer = projectsSlice.reducer;
