enum TrackingEvent {
  ReferralProjectSubmitted = "Referral Project Submitted",
  ReferralLinkClicked = "Referral Link Clicked",
  ProjectClicked = "Project Clicked",
  ProjectSubmitClicked = "Project Submit Clicked",
  MeetingClicked = "Meeting Clicked",
  ProviderClicked = "Provider Clicked",
  ProviderContactClicked = "Provider Clicked",
  UpdatedPortfolio = "Updated Portfolio",
  UpdatedTeamMember = "Updated Team Member",
  UpdatedInformation = "Updated Information",
  FundicaClicked = "Fundica Link Clicked",
  WidgetSnippetCopied = "Widget Snippet Copied",
  GrantApplyNowClicked = "Grant Apply Now Click",
}
export default TrackingEvent;

