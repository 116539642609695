export const prefixHTTPS = (
  url: string,
  campaign?: string,
  medium?: string
): string => {
  const prefixedUrl =
    !!url && (url.startsWith("http://") || url.startsWith("https://"))
      ? url
      : `https://${url}`;

  if (campaign) {
    const separator = prefixedUrl.includes("?") ? "&" : "?";
    const queryParams = `utm_source=helloDarwin&utm_medium=${medium || "profile"}&utm_campaign=${campaign}`;
    const modifiedUrl = `${prefixedUrl}${separator}${queryParams}`;
    return modifiedUrl;
  } else return prefixedUrl;
};

export default prefixHTTPS;

