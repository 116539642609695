import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";
import { ProfileEditModalFunctions } from ".";
import { CompanyRequest, Provider } from "../../../../features/entities";
import addLineBreak from "../../../../features/helpers/add-line-break";
import handleDispatchResponse from "../../../../features/helpers/handle-dispatch-response";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import ModalWithHeaderFooter from "../../../common/hd-modal/layouts/modal-with-header-footer";
import Loading from "../../../loading";
import FormItem from "../../form-layouts/form-item";

export interface DescriptionFormValues {
  description: string;
}

type AboutFormProps = {
  provider: Provider;
  setFooter: (footer: JSX.Element) => void;
  handleCancel: () => void;
  functions: ProfileEditModalFunctions;
};

const AboutForm = ({
  provider,
  setFooter,
  handleCancel,
  functions,
}: AboutFormProps) => {
  const { t } = useTranslation();
  const [form] = useForm<DescriptionFormValues>();
  const theme = useTheme();

  const { isLoading } = functions;
  const initialValues: DescriptionFormValues = {
    description: provider.description || "",
  };

  const handleSave = async () => {
    const values = form.getFieldsValue();
    const { company_id } = provider;
    const profileRequest: CompanyRequest = {
      company_description: addLineBreak(values.description),
      company_id,
    };

    const data = new FormData();
    data.append("request", JSON.stringify(profileRequest));
    functions
      .updateProfile({ data })
      .then((res) =>
        handleDispatchResponse(
          res,
          t("notification.saved"),
          t("notification.saving_error")
        )
      );

    handleCancel();
  };

  useEffect(() => {
    setFooter(
      <ModalWithHeaderFooter.Footer
        actions={
          <>
            <Button
              defaultStyle={theme.colors.white_1}
              onClick={handleCancel}
              size="small"
              loading={functions.isLoading}
            >
              {t("button.cancel")}
            </Button>
            <Button
              onClick={handleSave}
              size="small"
              loading={functions.isLoading}
            >
              {t("button.save")}
            </Button>
          </>
        }
      />
    );
  }, []);

  return (
    <Div flex="column">
      {isLoading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={handleSave}
          layout={"vertical"}
          className={"description-form"}
          style={{ width: "100%" }}
        >
          <FormItem name={"description"} className="form-item-description">
            <TextArea
              style={{ minHeight: 150 }}
              className={"hd-text-editor"}
              placeholder={t("provider-profile|about.placeholder.description")}
            />
          </FormItem>
        </Form>
      )}
    </Div>
  );
};

export default AboutForm;

