import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import { PropsWithChildren, ReactNode, useCallback, useMemo } from "react";
import { useTranslations } from "../../../features/providers/translations-provider";
import { useTheme } from "../../../plugins/styled";
import Collapse from "../Collapse";
import {
  CollapsePanelExtendedProps,
  CollapsePanelProps,
} from "../Collapse/CollapsePanel";
import List, { ListGridType, ListProps } from "../List";
import Typography from "../typography";

type SeeMoreListProps = {
  limit: number;
  grid?: ListGridType;
  content: ReactNode[];
};

const SeeMoreList = (props: SeeMoreListProps & PropsWithChildren) => {
  const { content, limit, grid } = props;
  const theme = useTheme();
  const { t } = useTranslations();

  const contentLength = content.length;
  const remainingCount = contentLength - limit;

  const showMore = useMemo(
    () =>
      remainingCount <= 1
        ? t("actions|seeCountMoreSingular", {
            count: remainingCount,
          })
        : t("actions|seeCountMore", { count: remainingCount }),
    [remainingCount, t]
  );

  const showLess = t("actions|seeLess");

  const listProps: Omit<ListProps<ReactNode>, "dataSource"> = {
    styles: { container: { width: "100%" } },
    grid,
    renderItem: useCallback(
      (child) => (
        <List.Item
          style={{
            paddingBottom: `${grid.gutter}px`,
            marginBottom: 0,
          }}
        >
          {child}
        </List.Item>
      ),
      [grid.gutter]
    ),
  };

  const expandIcon = ({ isActive }: CollapsePanelExtendedProps) => (
    <Typography nowrap elementTheme="body2" color={theme.colors.grey_3}>
      <span style={{ marginRight: 8 }}>{isActive ? showLess : showMore}</span>
      <TabArrow width={8} height={8} down={!isActive} />
    </Typography>
  );

  const collapseItems: CollapsePanelProps[] = useMemo(
    () => [
      {
        handleClickButtonOnly: true,
        id: "see-more-header",
        title: <List dataSource={content.slice(0, limit)} {...listProps} />,
        children:
          contentLength > limit ? (
            <List dataSource={content.slice(limit)} {...listProps} />
          ) : undefined,
      },
    ],
    [content, limit, contentLength, listProps]
  );

  return (
    <Collapse
      expandIconPosition="bottom"
      ExpandIcon={expandIcon}
      ghost
      noPadding
      items={collapseItems}
    />
  );
};

export default SeeMoreList;

